import React, { useState, useRef } from 'react';
import { IconButton, Box, CircularProgress, Fade } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { keyframes } from '@mui/system';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const AudioRecorder = ({ onTranscription }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onstop = async () => {
        setIsProcessing(true);
        const audioBlob = new Blob(audioChunks.current, { type: 'audio/mp3' });
        await sendAudioToServer(audioBlob);
        audioChunks.current = [];
        setIsProcessing(false);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
    }
  };

  const sendAudioToServer = async (audioBlob) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      
      reader.onloadend = async () => {
        const base64Audio = reader.result;
        
        const response = await fetch('http://localhost:5000/transcribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ audio: base64Audio }),
        });
        
        const data = await response.json();
        if (data.success) {
          onTranscription(data.transcription);
        } else {
          console.error('Transcription error:', data.error);
        }
      };
    } catch (error) {
      console.error('Error sending audio to server:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <IconButton
        color="primary"
        onClick={isRecording ? stopRecording : startRecording}
        disabled={isProcessing}
        sx={{
          width: 56,
          height: 56,
          bgcolor: isRecording ? 'error.main' : '#2EC4B6',
          color: 'white',
          '&:hover': {
            bgcolor: isRecording ? 'error.dark' : '#1aad9f',
          },
          animation: isRecording ? `${pulse} 1.5s infinite` : 'none',
        }}
      >
        {isRecording ? <StopIcon /> : <MicIcon />}
      </IconButton>
      <Fade in={isProcessing}>
        <CircularProgress
          size={68}
          sx={{
            position: 'absolute',
            color: 'primary.main',
          }}
        />
      </Fade>
    </Box>
  );
};

export default AudioRecorder; 