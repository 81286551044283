import React from "react";
import CustomModal from "../../theme/style-components/CustomModal";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import CustomInput from "../../theme/style-components/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomButton from "../../theme/style-components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useImportPropertyMutation } from "../../store/property/propertyApiSlice";
import { toast } from "react-toastify";
import { showError } from "../shared/helperFunction";

const CreatePropertyOptionsModal = ({
  isCreateModalOpen,
  setIsCreateModalOpen,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [importProperty, { isLoading: isImportLoading }] =
    useImportPropertyMutation();

  const validationSchema = yup.object({
    url: yup
      .string("Please enter name")
      .min(3, "URL must contain 3 characters minimum")
      .required("URL is required")
      .max(100, "URL can contain a maximum of 100 characters"),
    scraper_name: yup
      .string("Please enter scraper name")
      .required("Scraper name is required"),
  });

  const propertyFormik = useFormik({
    initialValues: {
      url: "",
      scraper_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await importProperty(values).unwrap();
        if (response) {
          toast.success("Property created succesfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsCreateModalOpen(false);
          // navigate(`/my-listings/edit-properties/${response?.data?.id}`);
        }
      } catch (error) {
        showError(error?.data);
      }
    },
  });
  return (
    <CustomModal
      handleClose={() => setIsCreateModalOpen(false)}
      title="Create Property"
      isModalOpen={isCreateModalOpen}
      isShowActionButton={false}
    >
      <Box sx={{ paddingTop: "20px", width: "100%" }}>
        <Box>
          <Box
            sx={{
              display: "grid",
              gap: "16px",
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
              },
            }}
          >
            <Box>
              <CustomInput
                id="url"
                name="url"
                label="Import from URL"
                value={propertyFormik.values.url}
                onChange={propertyFormik.handleChange}
                error={
                  propertyFormik.touched.url &&
                  Boolean(propertyFormik.errors.url)
                }
                helperText={
                  propertyFormik.touched.url && propertyFormik.errors.url
                }
              />
            </Box>
            <Box>
              <CustomInput
                id="scraper_name"
                name="scraper_name"
                label="Scraper Name"
                value={propertyFormik.values.scraper_name}
                onChange={propertyFormik.handleChange}
                error={
                  propertyFormik.touched.scraper_name &&
                  Boolean(propertyFormik.errors.scraper_name)
                }
                helperText={
                  propertyFormik.touched.scraper_name &&
                  propertyFormik.errors.scraper_name
                }
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: "15px", textAlign: "center", width: "100%" }}>
            <CustomButton
              variant="contained"
              color="primary"
              buttonName="Import"
              isLoading={isImportLoading}
              fullWidth={true}
              onClick={propertyFormik.handleSubmit}
            />
          </Box>
        </Box>
        <Grid2 container spacing={2}>
          <Grid2 item xs={12} sm={12}></Grid2>
          <Grid2 item xs={12} sm={12} sx={{ padding: "0" }}>
            <Box
              sx={{
                marginTop: "20px",
                textAlign: "center",
                position: "relative",
                zIndex: "1",
                "&:after": {
                  content: '""',
                  position: "absolute",
                  top: "50%",
                  left: "0",
                  width: "100%",
                  height: "1px",
                  backgroundColor: `gray !important`,
                  transform: "translateY(-50%)",
                  zIndex: "-1",
                },
              }}
            >
              <Typography
                sx={{
                  color: "gray",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "400",
                  textAlign: "center",
                  display: "inline-block",
                  backgroundColor: theme.palette.background.paper,
                  padding: "0 20px",
                }}
              >
                or
              </Typography>
            </Box>
          </Grid2>
          <Grid2
            item
            xs={12}
            sm={12}
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CustomButton
              variant="contained"
              color="primary"
              buttonName="Create"
              fullWidth={true}
              onClick={() => navigate("/my-listings/create-properties")}
            />
          </Grid2>
        </Grid2>
      </Box>
    </CustomModal>
  );
};

export default CreatePropertyOptionsModal;
