import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  useTheme,
  Button,
  Drawer,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Icon } from "../../asset/logo.svg";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import home from "../../asset/home.svg";
import userIcon from "../../asset/login-register.svg";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const HomeHeader = ({ onScroll, ref }) => {
  const [scroll, setScroll] = useState(false);
  const navigate = useNavigate();
  const divRef = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:780px)");

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        padding: "20px 30px",
        borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
        position: "fixed",
        left: "0",
        width: "100%",
        top: 0,
        zIndex: 1000,
      }}
      className={scroll ? "sticky" : ""}
    >
      <Box
        ref={divRef}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Logo */}
        <Box
          sx={{
            width: "120px",
            cursor: "pointer",
            "& svg": {
              width: "100%",
              height: "100%",
              "& path": {
                fill: "#fff",
              },
            },
          }}
          onClick={() => navigate("/")}
        >
          <Icon />
        </Box>

        {/* Desktop Navigation */}
        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button onClick={() => navigate("/")}>
              <img src={home} alt="Logo" />
            </Button>
            <Box
              sx={{
                width: "1.5px",
                color: "rgba(255,255,255,.5)",
                height: "16px",
              }}
            >
              |
            </Box>
            <Button
              onClick={() => navigate("/listings")}
              sx={{
                color: "#fff",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "500",
                padding: "0 35px",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#2EC4B6",
                },
              }}
            >
              Listing
            </Button>
            <Box
              sx={{
                width: "1.5px",
                color: "rgba(255,255,255,.5)",
                height: "16px",
              }}
            >
              |
            </Box>
            <Button
              onClick={() => navigate("/brokers")}
              sx={{
                color: "#fff",
                textTransform: "none",
                fontSize: "16px",
                fontWeight: "500",
                padding: "0 35px",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#2EC4B6",
                },
              }}
            >
              Brokers
            </Button>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Box sx={{}}>
                <Button
                  onClick={() => navigate("/login")}
                  sx={{
                    background: "#fff",
                    padding: "12px 15px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    color: "#495251",
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "600",
                    textTransform: "uppercase",
                    lineHeight: "20px",
                    borderRadius: "6px",

                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#2EC4B6",
                    },
                  }}
                >
                  <img src={userIcon} alt="" />
                  Login/Register
                </Button>
              </Box>
              {/* <Box sx={{ width:'1.5px', color:'rgba(255,255,255,.5)', height:'16px' }}>|</Box>                             */}
            </Box>
          </Box>
        )}

        {/* Mobile Menu Icon */}
        {isMobile && (
          <IconButton
            onClick={() => setMobileMenuOpen(true)}
            sx={{ color: "#fff" }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Box>

      {/* Mobile Menu Drawer */}
      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "250px",
            backgroundColor: "#1A1A1A",
            padding: "20px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            position: "relative",
          }}
        >
          {/* Close Button - Positioned absolutely */}
          <IconButton
            onClick={() => setMobileMenuOpen(false)}
            sx={{
              color: "#fff",
              position: "absolute",
              right: "-10px",
              top: "-10px",
              padding: "4px",
              "& svg": {
                fontSize: "20px",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Centered Logo */}
          <Box
            sx={{
              width: "140px",
              margin: "4px 8px",
              "& svg": {
                width: "100%",
                height: "100%",
                "& path": {
                  fill: "#fff",
                },
              },
            }}
          >
            <Icon />
          </Box>

          {/* Navigation Buttons */}
          <Button
            onClick={() => {
              navigate("/");
              setMobileMenuOpen(false);
            }}
            sx={{
              color: "#fff",
              justifyContent: "flex-start",
              "&:hover": { color: "#2EC4B6" },
            }}
          >
            Home
          </Button>
          <Button
            onClick={() => {
              navigate("/listings");
              setMobileMenuOpen(false);
            }}
            sx={{
              color: "#fff",
              justifyContent: "flex-start",
              "&:hover": { color: "#2EC4B6" },
            }}
          >
            Listing
          </Button>
          <Button
            onClick={() => {
              navigate("/brokers");
              setMobileMenuOpen(false);
            }}
            sx={{
              color: "#fff",
              justifyContent: "flex-start",
              "&:hover": { color: "#2EC4B6" },
            }}
          >
            Brokers
          </Button>
          <Button
            onClick={() => {
              navigate("/login");
              setMobileMenuOpen(false);
            }}
            sx={{
              background: "#fff",
              color: "#495251",
              justifyContent: "flex-start",
              "&:hover": { color: "#2EC4B6" },
            }}
          >
            Login/Register
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
};

export default HomeHeader;
