import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import { Typography, Box, Grid } from "@mui/material";

const Dashboard = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          background: theme.palette.background.paper,
          borderRadius: "20px",
          marginTop: "30px",
          padding: "20px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#00c6b6",
            fontWeight: 600,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          Coming soon...
        </Typography>
      </Box>
    </>
  );
};

export default Dashboard;
