import React from "react";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import CustomInput from "../../theme/style-components/CustomInput";
import {
  Box,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CustomButton from "../../theme/style-components/CustomButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { showError } from "../shared/helperFunction";
import CustomSelect from "../../theme/style-components/CustomSelect";
import {
  useCreatePropertyMutation,
  useGetAmenitiesQuery,
  useUpdatePropertyMutation,
} from "../../store/property/propertyApiSlice";
import { Link, useNavigate } from "react-router-dom";
import CustomCheckBox from "../../theme/style-components/CustomCheckBox";
import { useState } from "react";
import { useEffect } from "react";
import ComponentTitle from "../shared/ComponentTitle";
import { Autocomplete } from "@mui/material";
import CustomAutoComplete from "../../theme/style-components/CustomAutoComplete";

const CreateProperty = ({ id, property, submitRef }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [updateProperty, { isLoading: isUpdateLoading }] =
    useUpdatePropertyMutation();
  const [createProperty, { isLoading: isCreateLoading }] =
    useCreatePropertyMutation();
  const { data: getAmenities, isLoading: isGetAmenitiesLoading } =
    useGetAmenitiesQuery();

  const validationSchema = yup.object({
    name: yup
      .string("Please enter name")
      .min(3, "Name must contain 3 characters minimum")
      .required("Name is required")
      .max(100, "Name can contain a maximum of 100 characters"),
    title: yup
      .string("Please enter title")
      .min(3, "Title must contain 3 characters minimum")
      .required("Title is required")
      .max(100, "Title can contain a maximum of 100 characters"),
    description: yup
      .string("Please enter description")
      .min(3, "Description must contain 3 characters minimum")
      .required("Description is required")
      .max(600, "Description can contain a maximum of 600 characters"),
    tenant_status: yup
      .string("Please select tenant status")
      .required("Tenant status is required"),
    property_type: yup
      .string("Please select property type")
      .required("Property type is required"),
    // amenities:
    //     yup.array()
    //         .of(yup.number()) // Each selected option should be a string
    //         .min(1, 'Select at least one option') // Ensure at least one option is selected
  });

  const propertyFormik = useFormik({
    initialValues: {
      name: property?.name || "",
      title: property?.title || "",
      description: property?.description || "",
      tenant_status: property?.tenant_status || "OCUPIED",
      property_type: property?.property_type || "RESEDENTIAL",
      amenities: property?.amenities?.map((item) => item.id) || [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      const createData = { ...values };
      const updateData = { ...values, id: id };

      if (id) {
        try {
          const response = await updateProperty(updateData).unwrap();
          if (response) {
            toast.success("Property updated succesfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } catch (error) {
          showError(error?.data);
        }
      } else {
        try {
          const response = await createProperty(createData).unwrap();
          if (response) {
            toast.success("Property created succesfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate(`/my-listings/edit-properties/${response?.data?.id}`);
          }
        } catch (error) {
          showError(error?.data);
        }
      }
    },
  });

  useEffect(() => {
    if (submitRef) {
      submitRef.current = propertyFormik.handleSubmit;
    }
  }, [submitRef, propertyFormik.handleSubmit]);

  const [amenitiesOptions] = useState([
    { id: 1, label: "pool", value: 1 },
    { id: 2, label: "gym", value: 2 },
    { id: 3, label: "club house", value: 3 },
  ]);

  useEffect(() => {
    if (property?.amenities?.length > 0) {
      const amenityIds = property.amenities.map((item) => item.id);
      propertyFormik.setFieldValue("amenities", amenityIds);
    }
  }, [property?.amenities]);

  useEffect(() => {
    if (property) {
      propertyFormik.setValues({
        name: property.name || "",
        title: property.title || "",
        description: property.description || "",
        tenant_status: property.tenant_status || "OCUPIED",
        property_type: property.property_type || "RESEDENTIAL",
        amenities: property.amenities?.map((item) => item.id) || [],
      });
    }
  }, [property]);

  const breadcrumbs = [
    <Link to="/my-listings">Properties</Link>,
    <Typography key="2" variant="body1">
      Create Property
    </Typography>,
  ];

  return (
    <>
      {!id && <ComponentTitle breadcrumbs={breadcrumbs} />}
      <Box
        sx={{
          background: "#fff",
          border: "1px solid #E5E5E5",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            "& .MuiTypography-body1": {
              color: theme.palette.primary.main,
              fontSize: "18px",
              lineHeight: 1,
              marginBottom: "15px",
              p: "15px 20px",
              borderBottom: "1px solid #E5E5E5",
              background: "#f2f4f7",
              borderRadius: "10px 10px 0 0",
              m: 0,
            },
          }}
        >
          <Typography>Basic Information</Typography>
        </Box>

        <Box sx={{ p: "20px", display: "grid", gap: "20px" }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(2, minmax(0, 1fr))",
              },
              gap: "20px",
            }}
          >
            <Box>
              <CustomInput
                id="name"
                name="name"
                label="Name"
                value={propertyFormik.values.name}
                onChange={propertyFormik.handleChange}
                error={
                  propertyFormik.touched.name &&
                  Boolean(propertyFormik.errors.name)
                }
                helperText={
                  propertyFormik.touched.name && propertyFormik.errors.name
                }
              />
            </Box>
            <Box>
              <CustomInput
                label="Title"
                id="title"
                name="title"
                value={propertyFormik.values.title}
                onChange={propertyFormik.handleChange}
                error={
                  propertyFormik.touched.title &&
                  Boolean(propertyFormik.errors.title)
                }
                helperText={
                  propertyFormik.touched.title && propertyFormik.errors.title
                }
              />
            </Box>
          </Box>
          <Box>
            <CustomInput
              minRows={3}
              multiline={true}
              label="Description"
              id="description"
              name="description"
              value={propertyFormik.values.description}
              onChange={propertyFormik.handleChange}
              error={
                propertyFormik.touched.description &&
                Boolean(propertyFormik.errors.description)
              }
              helperText={
                propertyFormik.touched.description &&
                propertyFormik.errors.description
              }
            />
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(2, minmax(0, 1fr))",
              },
              gap: "20px",
            }}
          >
            <Box>
              <CustomSelect
                label="Tenant Status"
                id="tenant_status"
                name="tenant_status"
                value={propertyFormik.values.tenant_status}
                onChange={propertyFormik.handleChange}
                error={
                  propertyFormik.touched.tenant_status &&
                  Boolean(propertyFormik.errors.tenant_status)
                }
                helperText={
                  propertyFormik.touched.tenant_status &&
                  propertyFormik.errors.tenant_status
                }
              >
                <MenuItem value="OCUPIED">Ocupied</MenuItem>
                <MenuItem value="VACANT">Vacant</MenuItem>
              </CustomSelect>
            </Box>

            <Box>
              <CustomSelect
                label="Property Type"
                id="property_type"
                name="property_type"
                value={propertyFormik.values.property_type}
                onChange={propertyFormik.handleChange}
                error={
                  propertyFormik.touched.property_type &&
                  Boolean(propertyFormik.errors.property_type)
                }
                helperText={
                  propertyFormik.touched.property_type &&
                  propertyFormik.errors.property_type
                }
              >
                <MenuItem value="RESEDENTIAL">Resedential</MenuItem>
                <MenuItem value="COMMERCIAL">Commercial</MenuItem>
              </CustomSelect>
            </Box>
          </Box>
          <Box>
            <FormLabel
              sx={{
                color: theme.palette.text.secondary,
                whiteSpace: "nowrap",
                display: "block",
                marginBottom: "8px",
              }}
            >
              Amenities:
            </FormLabel>
            <Box
              sx={{
                display: "flex",
                background: "#fff",
                borderRadius: "4px",
                width: "100%",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                "&:hover": {
                  border: "1px solid #2ec4b6",
                },
              }}
            >
              <CustomAutoComplete
                multiple
                options={getAmenities || []}
                value={(getAmenities || []).filter((option) =>
                  propertyFormik.values.amenities.includes(option.id)
                )}
                onChange={(_, newValue) => {
                  propertyFormik.setFieldValue(
                    "amenities",
                    newValue.map((item) => item.id)
                  );
                }}
                getOptionLabel={(option) =>
                  `${option.name || ""}`.trim() || "All Agents"
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    {`${option.name || ""}`.trim() || "All Agents"}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      propertyFormik.values.amenities.length === 0
                        ? "Select Agents"
                        : ""
                    }
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      "& .MuiInputBase-input": {
                        padding: "10px 15px 9px !important",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#7D8383",
                      },
                      "& .MuiSvgIcon-root ": {
                        fill: "#2ec4b6",
                      },
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Box>
          </Box>
        </Box>
        {!id && (
          <Box
            sx={{
              borderTop: "1px solid #E5E5E5",
              textAlign: "end",
              p: "15px 20px",
              // marginTop: "20px",
            }}
          >
            <CustomButton
              color="primary"
              type="submit"
              isLoading={isCreateLoading || isUpdateLoading}
              onClick={propertyFormik.handleSubmit}
              disabled={isCreateLoading || isUpdateLoading}
              buttonName={"Next"}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default CreateProperty;
