import React from "react";
import PublicLayout from "../../../component/layout/PublicLayout";
import HomeContent from "../../../component/home/HomeContent";
import ChatbotButton from "../../../component/chatbot/ChatbotButton";

const Home = () => {
  return (
    <PublicLayout>
      {/* <Box className="head-bg"></Box> */}
      <HomeContent />
      <ChatbotButton />
    </PublicLayout>
  );
};

export default Home;
