import React from "react";
import CustomModal from "../../theme/style-components/CustomModal";
import { Box, Typography } from "@mui/material";

export const TrueBrokerBadgeModal = ({
  setIsTrueBrokerModalOpen,
  isTrueBrokerModalOpen,
}) => {
  return (
    <CustomModal
      handleClose={() => setIsTrueBrokerModalOpen(false)}
      isModalOpen={isTrueBrokerModalOpen}
      isShowActionButton={false}
      maxWidth="sm"
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "10px 0px 10px 10px",
          maxWidth: "500px",
          width: "100%",
        }}
      >
        {/* TruBroker Logo */}
        <Box
          sx={{
            width: "150px",
            margin: "0 auto 20px",
            background:
              "linear-gradient(271.59deg, #2EC4B6 0%, #0C877B 45%, #2EC4B6 100%)",
            padding: "15px 20px",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontSize: "20px",
              fontWeight: "700",
              fontFamily: "Quicksand",
              "& sub": {
                fontSize: "12px",
                verticalAlign: "super",
              },
            }}
          >
            TruBroker<sub>™</sub>
          </Typography>
        </Box>

        {/* Modal Content */}
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#495251",
              lineHeight: "1.5",
              fontFamily: "Quicksand",
            }}
          >
            Agents with the exclusive TruBroker™ badge have showcased that they
            offer available and quality listings. In addition to this, they also
            are quick to respond ensuring a smooth and hassle-free property
            search experience.
          </Typography>
        </Box>
      </Box>
    </CustomModal>
  );
};
