import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import diamond from "../../asset/diamond.svg";
import CustomModal from "../../theme/style-components/CustomModal";

export const QualityListerBadge = ({
  setIsQualityListerModalOpen,
  isQualityListerModalOpen,
}) => {
  return (
    <CustomModal
      handleClose={() => setIsQualityListerModalOpen(false)}
      isModalOpen={isQualityListerModalOpen}
      isShowActionButton={false}
      maxWidth="sm"
    >
      {/* Premium Border Effect */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "6px",
          background: "linear-gradient(90deg, #2196F3 0%, #00BCD4 100%)",
          borderRadius: "20px 20px 0 0",
        }}
      />

      {/* Quality Lister Badge */}
      <Box
        sx={{
          //   width: "300px",
          margin: { sm: "0 auto 24px", xs: "0 auto 16px" },
          background: "linear-gradient(135deg, #E3F2FD 0%, #BBDEFB 100%)",
          padding: { sm: "16px", xs: "10px" },
          borderRadius: "16px",
          textAlign: "center",
          boxShadow: "0 4px 20px rgba(33, 150, 243, 0.15)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "15px",
          border: "1px solid rgba(255, 255, 255, 0.8)",
        }}
      >
        <Box
          component="img"
          src={diamond}
          alt="Quality Lister Badge"
          sx={{
            width: { sm: "35px", xs: "25px" },
            height: { sm: "35px", xs: "25px" },
            filter: "drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1))",
          }}
        />
        <Typography
          sx={{
            color: "#1565C0",
            fontSize: { sm: "24px", xs: "20px" },
            fontWeight: "800",
            fontFamily: "Quicksand",
            letterSpacing: "0.5px",
            textShadow: "0 2px 4px rgba(0, 0, 0, 0.08)",
          }}
        >
          Quality Lister
        </Typography>
      </Box>

      {/* Modal Content */}
      <Box sx={{ textAlign: "center" }}>
        <Typography
          sx={{
            fontSize: { sm: "18px", xs: "16px" },
            color: "#37474F",
            lineHeight: "1.6",
            fontFamily: "Quicksand",
            // maxWidth: "450px",
            margin: "0 auto",
            fontWeight: "500",
          }}
        >
          This badge represents excellence in real estate listings. Agents
          earning this distinction have consistently demonstrated:
        </Typography>

        <Box sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          {[
            "High-quality property presentations",
            "Accurate and detailed listing information",
            "Responsive communication",
            "Verified property availability",
          ].map((text, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                background: "rgba(255, 255, 255, 0.7)",
                padding: "12px 20px",
                borderRadius: "10px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.03)",
              }}
            >
              <Box
                sx={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  background:
                    "linear-gradient(135deg, #2196F3 0%, #00BCD4 100%)",
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#455A64",
                  fontFamily: "Quicksand",
                  fontWeight: "600",
                }}
              >
                {text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </CustomModal>
  );
};
