import React from "react";
import { useTheme } from "@mui/material";
import PublicLayout from "../../../component/layout/PublicLayout";
import PropertyDetail from "../../../component/listings/PropertyDetail";
import ChatbotButton from "../../../component/chatbot/ChatbotButton";

const Properties = () => {
  return (
    <PublicLayout>
      <PropertyDetail />
      <ChatbotButton />
    </PublicLayout>
  );
};

export default Properties;
