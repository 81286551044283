import React from "react";
import { useTheme } from "@mui/material";
import PublicLayout from "../../../component/layout/PublicLayout";
import ListingsContent from "../../../component/listings/Listings";
import ChatbotButton from "../../../component/chatbot/ChatbotButton";

const Listings = () => {
  const theme = useTheme();

  return (
    <PublicLayout>
      <ListingsContent />
      <ChatbotButton />
    </PublicLayout>
  );
};

export default Listings;
