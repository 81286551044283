import { apiSlice } from "../apiSlice";

export const brokerListingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrokerList: builder.query({
      query: ({ id, page, search }) =>
        `/user_extended/brokers/?broker_id=${
          id ? id : ""
        }&page=${page}&search=${search}`,
      keepUnusedDataFor: 0,
      meta: { noAuth: true },
    }),

    postContactBroker: builder.mutation({
      query: (data) => ({
        url: `/user_extended/contact-agent/`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGetBrokerListQuery, usePostContactBrokerMutation } =
  brokerListingsApiSlice;
