import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Container, TextField } from "@mui/material";
import Slider from "react-slick";
import naturenew from "../../asset/company.jpg";
import CheckIcon from "@mui/icons-material/Check";
import { useState } from "react";
import { useRef } from "react";
import {
  useBrokerRegisterMutation,
  useDevRegisterMutation,
  useInvesterRegisterMutation,
} from "../../store/register/registerApiSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLazyGetUserProfileQuery } from "../../store/user-profile/userProfileApiSlice";
import { useEffect } from "react";
import { setUser } from "../../store/auth/authSlice";
import { useDispatch } from "react-redux";

const Register = () => {
  const [brokerRegister] = useBrokerRegisterMutation();
  const [investerRegister] = useInvesterRegisterMutation();
  const [devRegister] = useDevRegisterMutation();
  const [getUserProfile] = useLazyGetUserProfileQuery();

  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    callUserProfile();
  }, []);

  const [selectedRole, setSelectedRole] = useState("investor");

  const [slideData, setSlideData] = useState({
    selected_role: "",
  });

  const [devSlideData, setDevSlideData] = useState({
    name: "",
    phone: "",
    company_name: "",
    best_time_to_call: "",
  });

  const [brokerSlideData, setBrokerSlideData] = useState({
    is_company: null,
    company_name: "",
    name: "",
    phone: "",
    best_time_to_call: "",
  });

  const [devError, setDevError] = useState({
    name: "",
    company_name: "",
    phone: "",
    best_time_to_call: "",
  });

  const [brokerError, setBrokerError] = useState({
    is_company: "",
    name: "",
    company_name: "",
    phone: "",
    best_time_to_call: "",
  });

  const [error, setError] = useState({
    selected_role: "",
  });

  const handleDevValidation = () => {
    const errorObj = {
      name: "",
      phone: "",
      company_name: "",
      best_time_to_call: "",
    };
    let isValidate = true;

    if (activeSlide === 1 && !devSlideData?.name) {
      isValidate = false;
      errorObj.name = "Please enter your name!";
    }
    if (activeSlide === 2 && !devSlideData?.phone) {
      isValidate = false;
      errorObj.phone = "Please enter your phone number!";
    }
    if (activeSlide === 3 && !devSlideData?.company_name) {
      isValidate = false;
      errorObj.company_name = "Please enter your company name!";
    }
    if (activeSlide === 4 && !devSlideData?.best_time_to_call) {
      isValidate = false;
      errorObj.best_time_to_call = "This field is required!";
    }

    setDevError(errorObj); // Set all errors together in the 'error' state.
    return isValidate;
  };

  const handleBrokerValidation = () => {
    const errorObj = {
      is_company: "",
      name: "",
      company_name: "",
      phone: "",
      best_time_to_call: "",
    };
    let isValidate = true;

    if (activeSlide === 1 && !brokerSlideData?.name) {
      isValidate = false;
      errorObj.name = "Please enter your name!";
    }
    if (activeSlide === 2 && !brokerSlideData?.phone) {
      isValidate = false;
      errorObj.phone = "Please enter your phone number!";
    }
    if (activeSlide === 3 && !brokerSlideData?.best_time_to_call) {
      isValidate = false;
      errorObj.best_time_to_call = "This field is required!";
    }
    if (activeSlide === 4 && brokerSlideData?.is_company === null) {
      isValidate = false;
      errorObj.is_company = "Please select the option!";
    }
    if (brokerSlideData?.is_company) {
      if (activeSlide === 5 && !brokerSlideData?.name) {
        isValidate = false;
        errorObj.name = "Please enter your company name!";
      }
    }

    setBrokerError(errorObj); // Set all errors together in the 'error' state.
    return isValidate;
  };

  const handleValidation = () => {
    const errorObj = {
      selected_role: "",
    };
    let isValidate = true;

    if (activeSlide === 0 && !slideData?.selected_role) {
      isValidate = false;
      errorObj.selected_role = "Please select the user role";
    }
    setError(errorObj); // Set all errors together in the 'error' state.
    return isValidate;
  };

  function SampleNextArrow(props) {
    const { className, style } = props;
    return (
      <Box
        className={className}
        style={{ ...style }}
        onClick={() => {
          const valid =
            selectedRole === "DEVELOPER"
              ? handleDevValidation()
              : handleBrokerValidation();
          if (valid) {
            sliderRef.current.slickNext();
          }
        }}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return <Box className={className} style={{ ...style }} onClick={onClick} />;
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    draggable: false,
    swipeToSlide: false,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
      setActiveSlide(nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleDevOnChange = (event) => {
    setDevError({});
    setDevSlideData({
      ...devSlideData,
      [event.target.name]: event.target.value,
    });
  };

  const handleBrokerOnChange = (event) => {
    setBrokerError({});
    setBrokerSlideData({
      ...brokerSlideData,
      [event.target.name]: event.target.value,
    });
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
    setError({});
    setSlideData({ ...slideData, selected_role: event.target.value });
  };

  const handleWorkChange = (event) => {
    setBrokerSlideData({ ...brokerSlideData, is_company: event.target.value });
  };

  const handleSubmit = async () => {
    if (selectedRole === "BROKER") {
      const reformatData = {
        name:
          brokerSlideData?.name +
          (brokerSlideData?.company_name
            ? ` ${brokerSlideData?.company_name}`
            : ""),
        is_company: brokerSlideData?.is_company,
        phone: brokerSlideData?.phone,
        company_name: brokerSlideData?.company_name,
        best_time_to_call: brokerSlideData?.best_time_to_call,
      };
      try {
        const response = await brokerRegister(reformatData).unwrap();
        if (response) {
          toast.success("Registration completed succesfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          await callUserProfile();
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    if (selectedRole === "DEVELOPER") {
      const reformatData = {
        name:
          devSlideData?.name +
          (devSlideData?.company_name ? ` ${devSlideData?.company_name}` : ""),
        is_company: devSlideData?.is_company,
        phone: devSlideData?.phone,
        company_name: devSlideData?.company_name,
        best_time_to_call: devSlideData?.best_time_to_call,
      };
      try {
        const response = await devRegister(reformatData).unwrap();
        if (response) {
          toast.success("Registration completed succesfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          await callUserProfile();
          navigate("/dashboard");
        }
      } catch (error) {
        toast.error("error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const callUserProfile = async () => {
    try {
      const response = await getUserProfile().unwrap();
      if (response) {
        dispatch(setUser(response?.data));
      }
    } catch (error) {
      toast.error(error?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        "& .slick-next": {
          right: "30px",
          bottom: "30px",
          top: "auto",
          transform: "rotate(90deg)",
          backgroundColor: "#2ec4b6",
          width: "30px",
          height: "30px",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
        },
        "& .slick-prev": {
          left: "auto",
          right: "60px",
          zIndex: "999",
          bottom: "30px",
          top: "auto",
          transform: "rotate(90deg)",
          backgroundColor: "#2ec4b6",
          width: "30px",
          height: "30px",
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
        },
        "& .slick-prev:before": {
          position: "relative",
          bottom: "-4px",
          left: "4px",
        },
        ".slick-next:before": {
          position: "relative",
          bottom: "-4px",
          left: "4px",
        },
        "& .slick-slider": {
          height: "100%",
        },
        "& .slick-list": {
          height: "100% !important",
        },
        "& .slick-track": {
          height: "100% !important",
        },
        "& .slick-slide": {
          border: "0",
        },
      }}
    >
      <Container
        maxWidth="xxl"
        sx={{
          padding: "0px !important",
          height: "100%",
        }}
      >
        <Box sx={{ height: "100%" }}>
          <Slider ref={sliderRef} {...settings}>
            <Box index={0} sx={{ height: "100vh" }}>
              <Grid
                container
                spacing={0}
                sx={{
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Grid
                  sx={{ height: "100%", backgroundColor: "#011627" }}
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <Box
                    sx={{
                      maxWidth: "540px",
                      width: "100%",
                      marginLeft: "auto",
                      textAlign: "end",
                      margin: "0 auto",
                      height: "100vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingLeft: {
                        xl: "0px",
                        lg: "0px",
                        md: "0px",
                        sm: "0px",
                        xs: "15px",
                      },
                      "& label": {
                        display: "block",
                        marginBottom: "10px",
                        textAlign: "start",
                        color: "#fff",
                        fontWeight: "500",
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        marginBottom: "15px",
                        color: "#fff",
                        textAlign: "left",
                        fontWeight: "500",
                      }}
                    >
                      I'm a
                    </Typography>
                    <FormControl
                      sx={{
                        width: "100%",
                      }}
                    >
                      <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        sx={{
                          "& span": {
                            "&:last-child": {
                              color: "#fff",
                            },
                          },
                          "& .MuiRadio-root": {
                            color: "#fff",
                          },
                          "& .Mui-checked": {
                            color: "#2ec4b6 !important",
                          },
                        }}
                        name="selected_role"
                        value={selectedRole}
                        onChange={handleRoleChange}
                      >
                        <FormControlLabel
                          value="INVESTOR"
                          control={<Radio />}
                          label="Invester"
                          sx={{
                            border: "1px solid #2ec4b6",
                            borderRadius: "100px",
                            margin: "0 auto",
                            marginBottom: "10px",
                            width: "100%",
                          }}
                        />
                        <FormControlLabel
                          value="BROKER"
                          control={<Radio />}
                          label="Broker"
                          sx={{
                            border: "1px solid #2ec4b6",
                            borderRadius: "100px",
                            margin: "0 auto",
                            marginBottom: "10px",
                            width: "100%",
                          }}
                        />
                        <FormControlLabel
                          value="DEVELOPER"
                          control={<Radio />}
                          label="Developer"
                          sx={{
                            border: "1px solid #2ec4b6",
                            margin: "0 auto",
                            borderRadius: "100px",
                            width: "100%",
                          }}
                        />
                      </RadioGroup>
                      <FormHelperText
                        error={error["selected_role"] ? true : false}
                      >
                        {error?.selected_role ? error?.selected_role : ""}
                      </FormHelperText>
                    </FormControl>
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#2ec4b6 !important",
                        maxWidth: "max-content",
                        color: "#fff",
                        marginTop: "15px",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        "& svg": {
                          fontSize: "18px",
                        },
                      }}
                      onClick={async () => {
                        const valid = handleValidation();
                        if (valid && slideData?.selected_role === "INVESTOR") {
                          try {
                            const response = await investerRegister().unwrap();
                            if (response) {
                              toast.success(
                                "Registration completed succesfully",
                                {
                                  position: toast.POSITION.TOP_RIGHT,
                                }
                              );
                              await callUserProfile();
                              navigate("/dashboard");
                            }
                          } catch (error) {
                            toast.error("error", {
                              position: toast.POSITION.TOP_RIGHT,
                            });
                          }
                        } else {
                          sliderRef.current.slickNext();
                        }
                      }}
                    >
                      Next <CheckIcon />
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    height: "100%",
                    display: {
                      xl: "block",
                      lg: "block",
                      md: "block",
                      sm: "none",
                      xs: "none",
                    },
                  }}
                  item
                  lg={6}
                  md={6}
                  sm={6}
                >
                  <Box
                    sx={{
                      height: "100%",
                      "& img": {
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  >
                    <img src={naturenew} alt="..." />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* DEV SLIDE  */}
            {selectedRole === "DEVELOPER" && (
              <Box sx={{ height: "100vh" }}>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Grid
                    sx={{ height: "100%", backgroundColor: "#011627" }}
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Box
                      sx={{
                        maxWidth: "540px",
                        width: "100%",
                        marginLeft: "auto",
                        textAlign: "end",
                        margin: "0 auto",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: {
                          xl: "0px",
                          lg: "0px",
                          md: "0px",
                          sm: "0px",
                          xs: "15px",
                        },
                        "& label": {
                          display: "block",
                          marginBottom: "10px",
                          textAlign: "start",
                          color: "#fff",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <label>What's Your Name?</label>
                      <TextField
                        id="filled-basic"
                        variant="standard"
                        placeholder="Your name"
                        sx={{
                          width: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                            xs: "90%",
                          },
                          "& input": {
                            paddingTop: "8px",
                            color: "#2ec4b6",
                            fontSize: {
                              xl: "25px",
                              lg: "25px",
                              md: "18px",
                              sm: "16px",
                              xs: "16px",
                            },
                          },
                          "& .Mui-error": {
                            color: "red !important",
                          },
                          "& .MuiInputBase-root": {
                            "&::before": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&::after": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&:hover": {
                              borderColor: "#2ec4b6 !important",
                            },
                          },
                        }}
                        onChange={handleDevOnChange}
                        name="name"
                        value={devSlideData?.name}
                        error={devError["name"] ? true : false}
                        helperText={devError?.name ? devError?.name : ""}
                      />
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#2ec4b6 !important",
                          maxWidth: "max-content",
                          color: "#fff",
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          "& svg": {
                            fontSize: "18px",
                          },
                        }}
                        onClick={() => {
                          const valid = handleDevValidation();
                          if (valid) {
                            sliderRef.current.slickNext();
                          }
                        }}
                      >
                        Next <CheckIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      height: "100%",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                    item
                    lg={6}
                    md={6}
                    sm={6}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        "& img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={naturenew} alt="..." />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedRole === "DEVELOPER" && (
              <Box sx={{ height: "100vh" }}>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Grid
                    sx={{ height: "100%", backgroundColor: "#011627" }}
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Box
                      sx={{
                        maxWidth: "540px",
                        width: "100%",
                        marginLeft: "auto",
                        textAlign: "end",
                        margin: "0 auto",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: {
                          xl: "0px",
                          lg: "0px",
                          md: "0px",
                          sm: "0px",
                          xs: "15px",
                        },
                        "& label": {
                          display: "block",
                          marginBottom: "10px",
                          textAlign: "start",
                          color: "#fff",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <label>What's Your Phone Number ??</label>
                      <TextField
                        id="filled-basic"
                        variant="standard"
                        placeholder="Your Phone Number"
                        sx={{
                          width: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                            xs: "90%",
                          },
                          "& input": {
                            paddingTop: "8px",
                            color: "#2ec4b6",
                            fontSize: {
                              xl: "25px",
                              lg: "25px",
                              md: "18px",
                              sm: "16px",
                              xs: "16px",
                            },
                          },
                          "& .MuiInputBase-root": {
                            "&::before": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&::after": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&:hover": {
                              borderColor: "#2ec4b6 !important",
                            },
                          },
                        }}
                        onChange={handleDevOnChange}
                        value={devSlideData?.phone}
                        name="phone"
                        error={devError?.phone ? true : false}
                        helperText={devError?.phone ? devError?.phone : null}
                      />
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#2ec4b6 !important",
                          maxWidth: "max-content",
                          color: "#fff",
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          "& svg": {
                            fontSize: "18px",
                          },
                        }}
                        onClick={() => {
                          const valid = handleDevValidation();
                          if (valid) {
                            sliderRef.current.slickNext();
                          }
                        }}
                      >
                        Next <CheckIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      height: "100%",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                    item
                    lg={6}
                    md={6}
                    sm={6}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        "& img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={naturenew} alt="..." />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedRole === "DEVELOPER" && (
              <Box sx={{ height: "100vh" }}>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Grid
                    sx={{ height: "100%", backgroundColor: "#011627" }}
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Box
                      sx={{
                        maxWidth: "540px",
                        width: "100%",
                        marginLeft: "auto",
                        textAlign: "end",
                        margin: "0 auto",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: {
                          xl: "0px",
                          lg: "0px",
                          md: "0px",
                          sm: "0px",
                          xs: "15px",
                        },
                        "& label": {
                          display: "block",
                          marginBottom: "10px",
                          textAlign: "start",
                          color: "#fff",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <label>What's Your Company Name?</label>
                      <TextField
                        id="filled-basic"
                        variant="standard"
                        placeholder="Your company name"
                        sx={{
                          width: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                            xs: "90%",
                          },
                          "& input": {
                            paddingTop: "8px",
                            color: "#2ec4b6",
                            fontSize: {
                              xl: "25px",
                              lg: "25px",
                              md: "18px",
                              sm: "16px",
                              xs: "16px",
                            },
                          },
                          "& .Mui-error": {
                            color: "red !important",
                          },
                          "& .MuiInputBase-root": {
                            "&::before": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&::after": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&:hover": {
                              borderColor: "#2ec4b6 !important",
                            },
                          },
                        }}
                        onChange={handleDevOnChange}
                        name="company_name"
                        value={devSlideData?.company_name}
                        error={devError["company_name"] ? true : false}
                        helperText={
                          devError?.company_name ? devError?.company_name : ""
                        }
                      />
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#2ec4b6 !important",
                          maxWidth: "max-content",
                          color: "#fff",
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          "& svg": {
                            fontSize: "18px",
                          },
                        }}
                        onClick={() => {
                          const valid = handleDevValidation();
                          if (valid) {
                            sliderRef.current.slickNext();
                          }
                        }}
                      >
                        Next <CheckIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      height: "100%",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                    item
                    lg={6}
                    md={6}
                    sm={6}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        "& img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={naturenew} alt="..." />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedRole === "DEVELOPER" && (
              <Box sx={{ height: "100vh" }}>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Grid
                    sx={{ height: "100%", backgroundColor: "#011627" }}
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Box
                      sx={{
                        maxWidth: "540px",
                        width: "100%",
                        marginLeft: "auto",
                        textAlign: "end",
                        margin: "0 auto",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: {
                          xl: "0px",
                          lg: "0px",
                          md: "0px",
                          sm: "0px",
                          xs: "15px",
                        },
                        "& label": {
                          display: "block",
                          marginBottom: "10px",
                          textAlign: "start",
                          color: "#fff",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <label>Best Time To Call?</label>
                      <TextField
                        id="filled-basic"
                        variant="standard"
                        placeholder="5 PM"
                        sx={{
                          width: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                            xs: "90%",
                          },
                          "& input": {
                            paddingTop: "8px",
                            color: "#2ec4b6",
                            fontSize: {
                              xl: "25px",
                              lg: "25px",
                              md: "18px",
                              sm: "16px",
                              xs: "16px",
                            },
                          },
                          "& .MuiInputBase-root": {
                            "&::before": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&::after": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&:hover": {
                              borderColor: "#2ec4b6 !important",
                            },
                          },
                        }}
                        onChange={handleDevOnChange}
                        value={devSlideData?.best_time_to_call}
                        name="best_time_to_call"
                        error={devError?.best_time_to_call ? true : false}
                        helperText={
                          devError?.best_time_to_call
                            ? devError?.best_time_to_call
                            : null
                        }
                      />
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#2ec4b6 !important",
                          maxWidth: "max-content",
                          color: "#fff",
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          "& svg": {
                            fontSize: "18px",
                          },
                        }}
                        onClick={handleSubmit}
                      >
                        Submit <CheckIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      height: "100%",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                    item
                    lg={6}
                    md={6}
                    sm={6}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        "& img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={naturenew} alt="..." />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* BROKER SLIDE  */}
            {selectedRole === "BROKER" && (
              <Box sx={{ height: "100vh" }}>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Grid
                    sx={{ height: "100%", backgroundColor: "#011627" }}
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Box
                      sx={{
                        maxWidth: "540px",
                        width: "100%",
                        marginLeft: "auto",
                        textAlign: "end",
                        margin: "0 auto",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: {
                          xl: "0px",
                          lg: "0px",
                          md: "0px",
                          sm: "0px",
                          xs: "15px",
                        },
                        "& label": {
                          display: "block",
                          marginBottom: "10px",
                          textAlign: "start",
                          color: "#fff",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <label>What should we call you?</label>
                      <TextField
                        id="filled-basic"
                        variant="standard"
                        placeholder="Your name"
                        sx={{
                          width: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                            xs: "90%",
                          },
                          "& input": {
                            paddingTop: "8px",
                            color: "#2ec4b6",
                            fontSize: {
                              xl: "25px",
                              lg: "25px",
                              md: "18px",
                              sm: "16px",
                              xs: "16px",
                            },
                          },
                          "& .Mui-error": {
                            color: "red !important",
                          },
                          "& .MuiInputBase-root": {
                            "&::before": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&::after": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&:hover": {
                              borderColor: "#2ec4b6 !important",
                            },
                          },
                        }}
                        onChange={handleBrokerOnChange}
                        name="name"
                        value={brokerSlideData?.name}
                        error={brokerError["name"] ? true : false}
                        helperText={brokerError?.name ? brokerError?.name : ""}
                      />
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#2ec4b6 !important",
                          maxWidth: "max-content",
                          color: "#fff",
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          "& svg": {
                            fontSize: "18px",
                          },
                        }}
                        onClick={() => {
                          const valid = handleBrokerValidation();
                          if (valid) {
                            sliderRef.current.slickNext();
                          }
                        }}
                      >
                        Next <CheckIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      height: "100%",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                    item
                    lg={6}
                    md={6}
                    sm={6}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        "& img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={naturenew} alt="..." />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedRole === "BROKER" && (
              <Box sx={{ height: "100vh" }}>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Grid
                    sx={{ height: "100%", backgroundColor: "#011627" }}
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Box
                      sx={{
                        maxWidth: "540px",
                        width: "100%",
                        marginLeft: "auto",
                        textAlign: "end",
                        margin: "0 auto",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: {
                          xl: "0px",
                          lg: "0px",
                          md: "0px",
                          sm: "0px",
                          xs: "15px",
                        },
                        "& label": {
                          display: "block",
                          marginBottom: "10px",
                          textAlign: "start",
                          color: "#fff",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <label>What's Your Phone Number ??</label>
                      <TextField
                        id="filled-basic"
                        variant="standard"
                        placeholder="Your Phone Number"
                        sx={{
                          width: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                            xs: "90%",
                          },
                          "& input": {
                            paddingTop: "8px",
                            color: "#2ec4b6",
                            fontSize: {
                              xl: "25px",
                              lg: "25px",
                              md: "18px",
                              sm: "16px",
                              xs: "16px",
                            },
                          },
                          "& .MuiInputBase-root": {
                            "&::before": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&::after": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&:hover": {
                              borderColor: "#2ec4b6 !important",
                            },
                          },
                        }}
                        onChange={handleBrokerOnChange}
                        value={brokerSlideData?.phone}
                        name="phone"
                        error={brokerError?.phone ? true : false}
                        helperText={
                          brokerError?.phone ? brokerError?.phone : null
                        }
                      />
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#2ec4b6 !important",
                          maxWidth: "max-content",
                          color: "#fff",
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          "& svg": {
                            fontSize: "18px",
                          },
                        }}
                        onClick={() => {
                          const valid = handleBrokerValidation();
                          if (valid) {
                            sliderRef.current.slickNext();
                          }
                        }}
                      >
                        Next <CheckIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      height: "100%",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                    item
                    lg={6}
                    md={6}
                    sm={6}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        "& img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={naturenew} alt="..." />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedRole === "BROKER" && (
              <Box sx={{ height: "100vh" }}>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Grid
                    sx={{ height: "100%", backgroundColor: "#011627" }}
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Box
                      sx={{
                        maxWidth: "540px",
                        width: "100%",
                        marginLeft: "auto",
                        textAlign: "end",
                        margin: "0 auto",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: {
                          xl: "0px",
                          lg: "0px",
                          md: "0px",
                          sm: "0px",
                          xs: "15px",
                        },
                        "& label": {
                          display: "block",
                          marginBottom: "10px",
                          textAlign: "start",
                          color: "#fff",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <label>Best Time To Call?</label>
                      <TextField
                        id="filled-basic"
                        variant="standard"
                        placeholder="4 PM"
                        sx={{
                          width: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                            xs: "90%",
                          },
                          "& input": {
                            paddingTop: "8px",
                            color: "#2ec4b6",
                            fontSize: {
                              xl: "25px",
                              lg: "25px",
                              md: "18px",
                              sm: "16px",
                              xs: "16px",
                            },
                          },
                          "& .MuiInputBase-root": {
                            "&::before": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&::after": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&:hover": {
                              borderColor: "#2ec4b6 !important",
                            },
                          },
                        }}
                        onChange={handleBrokerOnChange}
                        value={brokerSlideData?.best_time_to_call}
                        name="best_time_to_call"
                        error={brokerError?.best_time_to_call ? true : false}
                        helperText={
                          brokerError?.best_time_to_call
                            ? brokerError?.best_time_to_call
                            : null
                        }
                      />
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#2ec4b6 !important",
                          maxWidth: "max-content",
                          color: "#fff",
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          "& svg": {
                            fontSize: "18px",
                          },
                        }}
                        onClick={() => {
                          const valid = handleBrokerValidation();
                          if (valid) {
                            sliderRef.current.slickNext();
                          }
                        }}
                      >
                        Next <CheckIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      height: "100%",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                    item
                    lg={6}
                    md={6}
                    sm={6}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        "& img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={naturenew} alt="..." />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {selectedRole === "BROKER" && (
              <Box sx={{ height: "100vh" }}>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Grid
                    sx={{ height: "100%", backgroundColor: "#011627" }}
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Box
                      sx={{
                        maxWidth: "540px",
                        width: "100%",
                        marginLeft: "auto",
                        textAlign: "end",
                        margin: "0 auto",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: {
                          xl: "0px",
                          lg: "0px",
                          md: "0px",
                          sm: "0px",
                          xs: "15px",
                        },
                        "& label": {
                          display: "block",
                          marginBottom: "10px",
                          textAlign: "start",
                          color: "#fff",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <label>Working For Company?</label>
                      <Select
                        value={brokerSlideData?.is_company}
                        onChange={handleWorkChange}
                        displayEmpty
                        inputProps={{
                          MenuProps: {
                            MenuListProps: {
                              sx: {
                                backgroundColor: "#072035",
                                "& li": {
                                  color: "#fff",
                                },
                              },
                            },
                          },
                        }}
                        renderValue={
                          brokerSlideData?.is_company !== null
                            ? undefined
                            : () => (
                                <Box sx={{ color: "grey" }}>
                                  Select the option
                                </Box>
                              )
                        }
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fill: "#2ec4b6",
                          },
                          "& .MuiSelect-select": {
                            padding: "10px 14px",
                            color: "#fff",
                          },
                          "& fieldset": {
                            borderRadius: "100px",
                            borderColor: "#2ec4b6 !important",
                            color: "red",
                          },
                          "& .MuiInputBase-root": {
                            "&:hover": {
                              "& fieldset": {
                                borderColor: "#2ec4b6 !important",
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#2ec4b6",
                            },
                          }}
                          value={false}
                        >
                          No
                        </MenuItem>
                        <MenuItem
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#2ec4b6",
                            },
                          }}
                          value={true}
                        >
                          Yes
                        </MenuItem>
                      </Select>
                      <FormHelperText
                        error={brokerError["is_company"] ? true : false}
                      >
                        {brokerError?.is_company ? brokerError?.is_company : ""}
                      </FormHelperText>
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#2ec4b6 !important",
                          maxWidth: "max-content",
                          color: "#fff",
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          "& svg": {
                            fontSize: "18px",
                          },
                        }}
                        onClick={() => {
                          const valid = handleBrokerValidation();
                          if (valid) {
                            if (brokerSlideData?.is_company) {
                              sliderRef.current.slickNext();
                            } else {
                              handleSubmit();
                            }
                          }
                        }}
                      >
                        {brokerSlideData?.is_company ? "Next" : "Submit"}
                        <CheckIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      height: "100%",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                    item
                    lg={6}
                    md={6}
                    sm={6}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        "& img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={naturenew} alt="..." />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            {brokerSlideData?.is_company && selectedRole === "BROKER" && (
              <Box sx={{ height: "100vh" }}>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Grid
                    sx={{ height: "100%", backgroundColor: "#011627" }}
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Box
                      sx={{
                        maxWidth: "540px",
                        width: "100%",
                        marginLeft: "auto",
                        textAlign: "end",
                        margin: "0 auto",
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: {
                          xl: "0px",
                          lg: "0px",
                          md: "0px",
                          sm: "0px",
                          xs: "15px",
                        },
                        "& label": {
                          display: "block",
                          marginBottom: "10px",
                          textAlign: "start",
                          color: "#fff",
                          fontWeight: "500",
                        },
                      }}
                    >
                      <label>What's Your Company Name ?</label>
                      <TextField
                        id="filled-basic"
                        variant="standard"
                        placeholder="Your company name"
                        sx={{
                          width: {
                            xl: "100%",
                            lg: "100%",
                            md: "100%",
                            sm: "100%",
                            xs: "90%",
                          },
                          "& input": {
                            paddingTop: "8px",
                            color: "#2ec4b6",
                            fontSize: {
                              xl: "25px",
                              lg: "25px",
                              md: "18px",
                              sm: "16px",
                              xs: "16px",
                            },
                          },
                          "& .Mui-error": {
                            color: "red !important",
                          },
                          "& .MuiInputBase-root": {
                            "&::before": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&::after": {
                              borderColor: "#2ec4b6 !important",
                            },
                            "&:hover": {
                              borderColor: "#2ec4b6 !important",
                            },
                          },
                        }}
                        onChange={handleBrokerOnChange}
                        name="company_name"
                        value={brokerSlideData?.company_name}
                        error={brokerError["company_name"] ? true : false}
                        helperText={
                          brokerError?.company_name
                            ? brokerError?.company_name
                            : ""
                        }
                      />
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor: "#2ec4b6 !important",
                          maxWidth: "max-content",
                          color: "#fff",
                          marginTop: "15px",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          "& svg": {
                            fontSize: "18px",
                          },
                        }}
                        onClick={handleSubmit}
                      >
                        Submit <CheckIcon />
                      </Button>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      height: "100%",
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                    item
                    lg={6}
                    md={6}
                    sm={6}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        "& img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={naturenew} alt="..." />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
};

export default Register;
