import React from "react";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CustomInput from "../../theme/style-components/CustomInput";
import CustomSelect from "../../theme/style-components/CustomSelect";
import { useUpdatePropertyFinancialsMutation } from "../../store/property/propertyApiSlice";
import * as yup from "yup";
import { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { showError } from "../shared/helperFunction";
import CustomButton from "../../theme/style-components/CustomButton";

const PropertyFinancials = ({ id, financials, submitRef }) => {
  const theme = useTheme();
  const [updatePropertyFinancials, { data, isLoading, error }] =
    useUpdatePropertyFinancialsMutation();

  const validationSchema = yup.object({
    asset_price: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Asset price is required")
      .positive("Asset price must be a positive number")
      .integer("Asset price must be an integer")
      .max(9999999999, "Maximum 10 digits allowed"),
    other_fees: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Othre fees is required")
      .positive("Othre fees must be a positive number")
      .integer("Othre fees must be an integer")
      .max(9999999999, "Maximum 10 digits allowed"),
    other_taxes: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Other taxes is required")
      .positive("Other taxes must be a positive number")
      .integer("Other taxes must be an integer")
      .max(9999999999, "Maximum 10 digits allowed"),
    total_amount: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Total amount is required")
      .positive("Total amount must be a positive number")
      .integer("Total amount must be an integer")
      .max(9999999999, "Maximum 10 digits allowed"),
    anual_gross_rent: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Anual gross rent is required")
      .positive("Anual gross rent must be a positive number")
      .integer("Anual gross rent must be an integer")
      .max(9999999999, "Maximum 10 digits allowed"),
    anual_service_charges: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Anual service charges is required")
      .positive("Anual service charges must be a positive number")
      .integer("Anual service charges must be an integer")
      .max(9999999999, "Maximum 10 digits allowed"),
    maintenance_fee: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Maintenance fee is required")
      .positive("Maintenance fee must be a positive number")
      .integer("Maintenance fee must be an integer")
      .max(9999999999, "Maximum 10 digits allowed"),
    projected_anualized_return: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Projected anualized return is required")
      .positive("Projected anualized return must be a positive number")
      .integer("Projected anualized return must be an integer")
      .max(9999999999, "Maximum 10 digits allowed"),
    current_rent: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Current rent is required")
      .positive("Current rent must be a positive number")
      .integer("Current rent must be an integer")
      .max(9999999999, "Maximum 10 digits allowed"),
    current_market_value: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Current market value is required")
      .positive("Current market value must be a positive number")
      .integer("Current market value must be an integer")
      .max(9999999999, "Maximum 10 digits allowed"),
  });

  const financialsFormik = useFormik({
    initialValues: {
      asset_price: "",
      other_fees: "",
      other_taxes: "",
      total_amount: "",
      anual_gross_rent: "",
      anual_service_charges: "",
      maintenance_fee: "",
      projected_anualized_return: "",
      current_rent: "",
      current_market_value: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const updateData = { ...values, id: id };

      try {
        const response = await updatePropertyFinancials(updateData).unwrap();
        if (response) {
          toast.success("Property financials updated succesfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        showError(error?.data);
      }
    },
  });

  useEffect(() => {
    if (financials?.id) {
      financialsFormik.setFieldValue("asset_price", financials?.asset_price);
      financialsFormik.setFieldValue("other_fees", financials?.other_fees);
      financialsFormik.setFieldValue("other_taxes", financials?.other_taxes);
      financialsFormik.setFieldValue("total_amount", financials?.total_amount);
      financialsFormik.setFieldValue(
        "anual_gross_rent",
        financials?.anual_gross_rent
      );
      financialsFormik.setFieldValue(
        "anual_service_charges",
        financials?.anual_service_charges
      );
      financialsFormik.setFieldValue(
        "maintenance_fee",
        financials?.maintenance_fee
      );
      financialsFormik.setFieldValue(
        "projected_anualized_return",
        financials?.projected_anualized_return
      );
      financialsFormik.setFieldValue("current_rent", financials?.current_rent);
      financialsFormik.setFieldValue(
        "current_market_value",
        financials?.current_market_value
      );
    }
  }, [financials]);

  useEffect(() => {
    if (submitRef) {
      submitRef.current = financialsFormik.handleSubmit;
    }
  }, [submitRef, financialsFormik.handleSubmit]);

  return (
    <>
      <Box
        sx={{
          background: "#fff",
          border: "1px solid #E5E5E5",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            "& .MuiTypography-body1": {
              color: theme.palette.primary.main,
              fontSize: "18px",
              lineHeight: 1,
              marginBottom: "15px",
              p: "15px 20px",
              borderBottom: "1px solid #E5E5E5",
              background: "#f2f4f7",
              borderRadius: "10px 10px 0 0",
              m: 0,
            },
          }}
        >
          <Typography sx={{ color: "#011627" }}>Financials</Typography>
        </Box>
        <Box sx={{ p: "20px" }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, minmax(0, 1fr))",
                sm: "repeat(2, minmax(0, 1fr))",
                md: "repeat(3, minmax(0, 1fr))",
              },
              gap: "20px",
            }}
          >
            <Box>
              <CustomInput
                label="Asset Price"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: "pointer",
                        "& svg": {
                          fill: "#696cff",
                        },
                      }}
                    >
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                id="asset_price"
                name="asset_price"
                value={financialsFormik.values.asset_price}
                onChange={financialsFormik.handleChange}
                type="number"
                min={0}
                onBlur={financialsFormik.handleBlur}
                error={
                  financialsFormik.touched.asset_price &&
                  Boolean(financialsFormik.errors.asset_price)
                }
                helperText={
                  financialsFormik.touched.asset_price &&
                  financialsFormik.errors.asset_price
                }
              />
            </Box>
            <Box>
              <CustomInput
                label="Other Fees"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: "pointer",
                        "& svg": {
                          fill: "#696cff",
                        },
                      }}
                    >
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                id="other_fees"
                name="other_fees"
                value={financialsFormik.values.other_fees}
                onChange={financialsFormik.handleChange}
                type="number"
                onBlur={financialsFormik.handleBlur}
                error={
                  financialsFormik.touched.other_fees &&
                  Boolean(financialsFormik.errors.other_fees)
                }
                helperText={
                  financialsFormik.touched.other_fees &&
                  financialsFormik.errors.other_fees
                }
              />
            </Box>
            <Box>
              <CustomInput
                label="Other Taxes"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: "pointer",
                        "& svg": {
                          fill: "#696cff",
                        },
                      }}
                    >
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                id="other_taxes"
                name="other_taxes"
                value={financialsFormik.values.other_taxes}
                onChange={financialsFormik.handleChange}
                type="number"
                onBlur={financialsFormik.handleBlur}
                error={
                  financialsFormik.touched.other_taxes &&
                  Boolean(financialsFormik.errors.other_taxes)
                }
                helperText={
                  financialsFormik.touched.other_taxes &&
                  financialsFormik.errors.other_taxes
                }
              />
            </Box>
            <Box>
              <CustomInput
                label="Total Amount"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: "pointer",
                        "& svg": {
                          fill: "#696cff",
                        },
                      }}
                    >
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                id="total_amount"
                name="total_amount"
                value={financialsFormik.values.total_amount}
                onChange={financialsFormik.handleChange}
                type="number"
                onBlur={financialsFormik.handleBlur}
                error={
                  financialsFormik.touched.total_amount &&
                  Boolean(financialsFormik.errors.total_amount)
                }
                helperText={
                  financialsFormik.touched.total_amount &&
                  financialsFormik.errors.total_amount
                }
              />
            </Box>
            <Box>
              <CustomInput
                label="Anual Gross Rent"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: "pointer",
                        "& svg": {
                          fill: "#696cff",
                        },
                      }}
                    >
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                id="anual_gross_rent"
                name="anual_gross_rent"
                value={financialsFormik.values.anual_gross_rent}
                onChange={financialsFormik.handleChange}
                type="number"
                onBlur={financialsFormik.handleBlur}
                error={
                  financialsFormik.touched.anual_gross_rent &&
                  Boolean(financialsFormik.errors.anual_gross_rent)
                }
                helperText={
                  financialsFormik.touched.anual_gross_rent &&
                  financialsFormik.errors.anual_gross_rent
                }
              />
            </Box>
            <Box>
              <CustomInput
                label="Anual Service Charges"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: "pointer",
                        "& svg": {
                          fill: "#696cff",
                        },
                      }}
                    >
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                id="anual_service_charges"
                name="anual_service_charges"
                value={financialsFormik.values.anual_service_charges}
                onChange={financialsFormik.handleChange}
                type="number"
                onBlur={financialsFormik.handleBlur}
                error={
                  financialsFormik.touched.anual_service_charges &&
                  Boolean(financialsFormik.errors.anual_service_charges)
                }
                helperText={
                  financialsFormik.touched.anual_service_charges &&
                  financialsFormik.errors.anual_service_charges
                }
              />
            </Box>
            <Box>
              <CustomInput
                label="Maintenance Fee"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: "pointer",
                        "& svg": {
                          fill: "#696cff",
                        },
                      }}
                    >
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                id="maintenance_fee"
                name="maintenance_fee"
                value={financialsFormik.values.maintenance_fee}
                onChange={financialsFormik.handleChange}
                type="number"
                onBlur={financialsFormik.handleBlur}
                error={
                  financialsFormik.touched.maintenance_fee &&
                  Boolean(financialsFormik.errors.maintenance_fee)
                }
                helperText={
                  financialsFormik.touched.maintenance_fee &&
                  financialsFormik.errors.maintenance_fee
                }
              />
            </Box>
            <Box>
              <CustomInput
                label="Projected Anualized Return"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: "pointer",
                        "& svg": {
                          fill: "#696cff",
                        },
                      }}
                    >
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                id="projected_anualized_return"
                name="projected_anualized_return"
                value={financialsFormik.values.projected_anualized_return}
                onChange={financialsFormik.handleChange}
                type="number"
                onBlur={financialsFormik.handleBlur}
                error={
                  financialsFormik.touched.projected_anualized_return &&
                  Boolean(financialsFormik.errors.projected_anualized_return)
                }
                helperText={
                  financialsFormik.touched.projected_anualized_return &&
                  financialsFormik.errors.projected_anualized_return
                }
              />
            </Box>
            <Box>
              <CustomInput
                label="Current Rent"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: "pointer",
                        "& svg": {
                          fill: "#696cff",
                        },
                      }}
                    >
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                id="current_rent"
                name="current_rent"
                value={financialsFormik.values.current_rent}
                onChange={financialsFormik.handleChange}
                type="number"
                onBlur={financialsFormik.handleBlur}
                error={
                  financialsFormik.touched.current_rent &&
                  Boolean(financialsFormik.errors.current_rent)
                }
                helperText={
                  financialsFormik.touched.current_rent &&
                  financialsFormik.errors.current_rent
                }
              />
            </Box>
            <Box>
              <CustomInput
                label="Current Market Value"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: "pointer",
                        "& svg": {
                          fill: "#696cff",
                        },
                      }}
                    >
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                id="current_market_value"
                name="current_market_value"
                value={financialsFormik.values.current_market_value}
                onChange={financialsFormik.handleChange}
                type="number"
                onBlur={financialsFormik.handleBlur}
                error={
                  financialsFormik.touched.current_market_value &&
                  Boolean(financialsFormik.errors.current_market_value)
                }
                helperText={
                  financialsFormik.touched.current_market_value &&
                  financialsFormik.errors.current_market_value
                }
              />
            </Box>
          </Box>
        </Box>
        {/* <Box
          sx={{
            borderTop: "1px solid #E5E5E5",
            textAlign: "end",
            p: "15px 20px",
            marginTop: "20px",
          }}
        >
          <CustomButton
            color="primary"
            type="submit"
            isLoading={isLoading}
            onClick={financialsFormik.handleSubmit}
            disabled={isLoading}
            buttonName={id ? "Update" : "Next"}
          />
        </Box> */}
      </Box>
    </>
  );
};

export default PropertyFinancials;
