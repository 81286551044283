import { apiSlice } from "../apiSlice";

export const publicListingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyPublicList: builder.query({
      query: ({
        listing_status,
        id,
        page,
        listing_type,
        property_type,
        search,
        bedrooms,
        bathrooms,
        min_price,
        max_price,
        area_sqft,
        broker_id,
      }) =>
        `/property/public_listings/${page ? `?page=${page}` : ""}${
          id ? `?id=${id}` : ""
        }${listing_status ? `&listing_status=${listing_status}` : ""}${
          listing_type ? `&listing_type=${listing_type}` : ""
        }${property_type ? `&property_type=${property_type}` : ""}${
          search ? `&search=${search}` : ""
        }${bedrooms ? `&bedrooms=${bedrooms}` : ""}${
          bathrooms ? `&bathrooms=${bathrooms}` : ""
        }${min_price ? `&min_price=${min_price}` : ""}${
          max_price ? `&max_price=${max_price}` : ""
        }${area_sqft ? `&area_sqft=${area_sqft}` : ""}${
          broker_id ? `&broker_id=${broker_id}` : ""
        }`,
      keepUnusedDataFor: 0,
      meta: { noAuth: true },
    }),
  }),
});

export const { useGetPropertyPublicListQuery } = publicListingsApiSlice;
