import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Select,
  MenuItem,
  Hidden,
  Menu,
  Input,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import homepageBgImage from "../../asset/Homepage-image.jpg"; // Add your background image
import CustomSelect from "../../theme/style-components/CustomSelect";
import searchLogo from "../../asset/search.svg";
import DownArrow from "../../asset/search.svg";
import bgIMG from "../../asset/Homepage-image-new.png";
import downArrow from "../../asset/down-arrow.svg";
import { useGetPropertyPublicListQuery } from "../../store/public-listings/publicListingsApiSlice";
import { useNavigate } from "react-router-dom";

const HomeContent = () => {
  const [activeTab, setActiveTab] = useState("RENT");
  const [anchorElBads, setAnchorElBads] = useState(null);
  const isBadsMenuOpen = Boolean(anchorElBads);
  const [anchorElPrice, setAnchorElPrice] = useState(null);
  const isPriceMenuOpen = Boolean(anchorElPrice);
  const [filters, setFilters] = useState({
    search: "",
    listing_status: "",
    listing_type: "RESEDENTIAL",
    bedrooms: "",
    bathrooms: "",
    min_price: "",
    max_price: "",
  });
  const navigate = useNavigate();

  const handleBadsMenuClick = (event) => {
    setAnchorElBads(event.currentTarget);
  };

  const handleCloseBadsMenu = () => {
    setAnchorElBads(null);
  };

  const handlePriceMenuClick = (event) => {
    setAnchorElPrice(event.currentTarget);
  };

  const handleClosePriceMenu = () => {
    setAnchorElPrice(null);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSearch = () => {
    const searchParams = {
      ...filters,
      listing_type: activeTab,
    };

    // Convert the search params object to URL query parameters
    const queryString = Object.entries(searchParams)
      .filter(([_, value]) => value !== "") // Remove empty values
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    // Navigate to listings page with query parameters
    navigate(`/listings?${queryString}`);
  };

  const handleReset = (type) => {
    if (type === "bads") {
      setFilters((prev) => ({
        ...prev,
        bedrooms: "",
        bathrooms: "",
      }));
      handleCloseBadsMenu();
    } else if (type === "price") {
      setFilters((prev) => ({
        ...prev,
        min_price: "",
        max_price: "",
      }));
      handleClosePriceMenu();
    }
  };

  const handleDone = (type) => {
    if (type === "bads") {
      handleCloseBadsMenu();
    } else if (type === "price") {
      handleClosePriceMenu();
    }
  };

  return (
    <Box sx={{ padding: "0" }}>
      <Box
        sx={{
          color: "#fff",
          textAlign: "center",
          height: { xs: "500px", md: "600px", lg: "700px" },
          background: `url(${bgIMG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionY: "bottom",
          borderRadius: {
            xs: "0 0 20px 20px",
            md: "0 0 20px 20px",
            lg: "0 0 200px 200px",
          },
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "hidden",
          position: "relative",
          zIndex: "1",
          "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            bottom: 0,
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "0",
            zIndex: "-1",
          },
        }}
      >
        <Box sx={{ marginTop: "-68px" }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "36px", sm: "50px", lg: "70px" },
              fontWeight: 700,
              lineHeight: "1.5",
              fontFamily: "Quicksand",
              m: 0,
            }}
          >
            DISCOVER A PLACE
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: { xs: "20px", lg: "30px" },
              // mb: 4,
              fontFamily: "Quicksand",
              fontWeight: 600,
              lineHeight: "1",
              m: "0",
            }}
          >
            YOU'LL LOVE TO LIVE
          </Typography>
        </Box>
      </Box>

      <Box sx={{ margin: "-140px auto 0", position: "relative", zIndex: "1" }}>
        <Box
          sx={{
            display: "flex",
            borderRadius: "10px",
            justifyContent: "center",
            margin: "0 auto 30px",
            background: "#fff",
            width: "fit-content",
          }}
        >
          {["RENT", "SALE"].map((tab) => (
            <Button
              key={tab}
              onClick={() => setActiveTab(tab)}
              sx={{
                backgroundColor: activeTab === tab ? "#2EC4B6" : "transparent",
                color: activeTab === tab ? "#fff" : "#495251",
                borderRadius: "10px",
                fontSize: "16px",
                fontWeight: "700",
                padding: "15px 40px",
                lineHeight: "20px",
                textTransform: "uppercase",
                position: "relative",
                "&:after": {
                  content: '""',
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  bottom: "-20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: `url(${downArrow}) no-repeat 0 0`,
                  display: activeTab === tab ? "block" : "none",
                },
                "&:hover": {
                  backgroundColor:
                    activeTab === tab ? "#2EC4B6" : "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              {tab}
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            width: "calc(100% - 40px)",
            maxWidth: "980px",
            margin: "0 auto",
            background: "#fff",
            borderRadius: "10px",
            boxShadow: " 0px 0px 50px 0px #00000026",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "0 130px 0 46px",
              borderBottom: "1px solid #E4E5E5",
              position: "relative",
            }}
          >
            <Box sx={{ position: "absolute", top: "20px", left: "20px" }}>
              <img src={searchLogo} alt="" height={22} width={22} />
            </Box>
            <TextField
              fullWidth
              placeholder="Search Location"
              variant="outlined"
              className="srhBox"
              value={filters.search}
              onChange={(e) => handleFilterChange("search", e.target.value)}
              sx={{
                backgroundColor: "transparent",
                borderRadius: "0",
                width: "100%",
                padding: "0",
                color: "#7D8383",
                fontSize: "18px",
                fontWeight: "700",
                border: "0",
                lineHeight: "22px",
                fontFamily: "Quicksand",
              }}
            />
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{
                backgroundColor: "#2EC4B6",
                borderRadius: "6px",
                height: "40px",
                fontSize: "18px",
                textTransform: "uppercase",
                fontWeight: 600,
                width: "110px",
                position: "absolute",
                top: "8px",
                right: "10px",
                boxShadow: "none",
                color: "#fff",
                fontFamily: "Quicksand",
                lineHeight: "28px",
                "&:hover": {
                  backgroundColor: "#2bc4c0",
                },
              }}
            >
              SEARCH
            </Button>
          </Box>

          {/* Filter Options */}
          <Box
            sx={{
              display: { md: "flex" },
            }}
          >
            <Box
              className="cstm-select"
              sx={{
                display: { xs: "grid", sm: "flex" },
                // width: { md: "calc(100% - 304px)" },
                width: "100%",
                borderTop: { xs: "1px solid #E4E5E5", md: "0" },
              }}
            >
              <Box sx={{ borderLeft: { xs: "0", md: "1px solid #E4E5E5" } }}>
                <Select
                  value={filters.listing_status}
                  onChange={(e) =>
                    handleFilterChange("listing_status", e.target.value)
                  }
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="AVAILABLE">AVAILABLE</MenuItem>
                  <MenuItem value="DISABLED">DISABLED</MenuItem>
                  <MenuItem value="SOLD">SOLD</MenuItem>
                  <MenuItem value="RENTED">RENTED</MenuItem>
                </Select>
              </Box>
              <Box sx={{ borderLeft: { xs: "0", md: "1px solid #E4E5E5" } }}>
                <Select
                  value={filters.listing_type}
                  onChange={(e) =>
                    handleFilterChange("listing_type", e.target.value)
                  }
                >
                  <MenuItem value="RESEDENTIAL">RESEDENTIAL</MenuItem>
                  <MenuItem value="COMMERCIAL">COMMERCIAL</MenuItem>
                </Select>
              </Box>
              <Box
                sx={{ borderLeft: { xs: "0", md: "1px solid #E4E5E5" } }}
                onClick={handleBadsMenuClick}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "15px",
                    "& svg": {
                      transform: "rotate(180deg)",
                    },
                    "& svg path": {
                      fill: "#7D8383",
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#7D8383",
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Bads & Baths
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 6"
                    class="_5fdb6c65 FilterDesign2022"
                    width="11"
                    height="11"
                  >
                    <path class="cls-1" d="M12 6L6 0 0 6h12z"></path>
                  </svg>
                </Box>
              </Box>
              <Box
                sx={{ borderLeft: { xs: "0", md: "1px solid #E4E5E5" } }}
                onClick={handlePriceMenuClick}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "15px",
                    "& svg": {
                      transform: "rotate(180deg)",
                    },
                    "& svg path": {
                      fill: "#7D8383",
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#7D8383",
                      fontSize: "16px",
                      lineHeight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Price (AED)
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 12 6"
                    class="_5fdb6c65 FilterDesign2022"
                    width="11"
                    height="11"
                  >
                    <path class="cls-1" d="M12 6L6 0 0 6h12z"></path>
                  </svg>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Experience Journey Button */}
      <Box
        sx={{
          margin: { xs: "30px", md: "50px auto", xl: "84px auto" },
          textAlign: "center",
        }}
      >
        <Button
          // startIcon={<ArrowBackIcon sx={{ transform: 'rotate(180deg)' }} />}
          sx={{
            color: "#495251",
            backgroundColor: "#CAF27C",
            borderRadius: "10px",
            padding: { xs: "15px", md: "15px 30px" },
            fontSize: { xs: "16px", md: "18px" },
            fontWeight: "600",
            lineHeight: "22px",
            textTransform: "none",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            margin: "0 auto",
            "&:hover": {
              backgroundColor: "#b1e89b",
            },
          }}
          onClick={() => navigate("/listings")}
        >
          Experience the Journey
          <Box sx={{ transform: "rotate(180deg) !important" }}>
            <svg
              width="16"
              height="21"
              viewBox="0 0 16 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.460803 9.63541C0.318689 9.73174 0.20249 9.86065 0.122217 10.011C0.0419431 10.1614 5.02192e-07 10.3287 4.94767e-07 10.4986C4.87342e-07 10.6684 0.0419431 10.8358 0.122217 10.9861C0.20249 11.1365 0.318689 11.2654 0.460803 11.3617L14.3265 20.8125C14.4864 20.9219 14.6738 20.986 14.8681 20.998C15.0625 21.0099 15.2565 20.9692 15.429 20.8802C15.6015 20.7912 15.7459 20.6574 15.8466 20.4933C15.9472 20.3292 16.0003 20.141 16 19.9493L16 1.04784C15.9992 0.856557 15.9454 0.669112 15.8445 0.505659C15.7436 0.342206 15.5993 0.208929 15.4272 0.120161C15.2551 0.0313934 15.0617 -0.00950661 14.8678 0.00185943C14.6738 0.0132255 14.4867 0.0764275 14.3265 0.184669L0.460803 9.63541Z"
                fill="#495251"
              />
            </svg>
          </Box>
        </Button>
      </Box>

      {/* bedrooms and Rooms Menu */}
      <Menu
        id="basic-menu"
        anchorEl={anchorElBads}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isBadsMenuOpen}
        onClose={handleCloseBadsMenu}
        sx={{
          "& .MuiMenu-paper": {
            minWidth: "250px",
            maxWidth: "400px",
            padding: "16px",
            backgroundColor: "white",
            color: "black",
          },
        }}
        MenuListProps={{
          "aria-labelledby": "more-filters-button",
          role: "menu",
          sx: { padding: 0 },
        }}
      >
        <Box>
          <Box sx={{ marginBottom: "20px" }}>
            <Typography
              variant="h5"
              sx={{
                color: "#495251",
                fontSize: "18px",
                lineHeight: "26px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              bedrooms
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                className={filters.bedrooms === "1" ? "active" : ""}
                onClick={() => handleFilterChange("bedrooms", "1")}
                sx={{
                  border: "1px solid #7D8383",
                  padding: "2px 20px",
                  borderRadius: "50px",
                  color: "#7D8383",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "600",
                  cursor: "pointer",
                  "&.active": {
                    borderColor: "#2bc4c0",
                    background: "#2bc4c0",
                    color: "#fff",
                  },
                }}
              >
                1
              </Box>
              <Box
                className={filters.bedrooms === "2" ? "active" : ""}
                onClick={() => handleFilterChange("bedrooms", "2")}
                sx={{
                  border: "1px solid #7D8383",
                  padding: "2px 20px",
                  borderRadius: "50px",
                  color: "#7D8383",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "600",
                  cursor: "pointer",
                  "&.active": {
                    borderColor: "#2bc4c0",
                    background: "#2bc4c0",
                    color: "#fff",
                  },
                }}
              >
                2
              </Box>
              <Box
                className={filters.bedrooms === "3" ? "active" : ""}
                onClick={() => handleFilterChange("bedrooms", "3")}
                sx={{
                  border: "1px solid #7D8383",
                  padding: "2px 20px",
                  borderRadius: "50px",
                  color: "#7D8383",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "600",
                  cursor: "pointer",
                  "&.active": {
                    borderColor: "#2bc4c0",
                    background: "#2bc4c0",
                    color: "#fff",
                  },
                }}
              >
                3
              </Box>
              <Box
                className={filters.bedrooms === "4" ? "active" : ""}
                onClick={() => handleFilterChange("bedrooms", "4")}
                sx={{
                  border: "1px solid #7D8383",
                  padding: "2px 20px",
                  borderRadius: "50px",
                  color: "#7D8383",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "600",
                  cursor: "pointer",
                  "&.active": {
                    borderColor: "#2bc4c0",
                    background: "#2bc4c0",
                    color: "#fff",
                  },
                }}
              >
                4
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "5px",
                "& input": {
                  width: "100%",
                  padding: "8px 14px",
                  borderRadius: "50px",
                  border: "1px solid #7D8383",
                },
                "& input:focus": {
                  outline: "0",
                  borderColor: "#1aad9f",
                },
              }}
            >
              <input
                placeholder="bedrooms"
                value={filters.bedrooms}
                onChange={(e) => handleFilterChange("bedrooms", e.target.value)}
              />
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: "#495251",
                fontSize: "18px",
                lineHeight: "26px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              Bathroom
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                className={filters.bathrooms === "1" ? "active" : ""}
                onClick={() => handleFilterChange("bathrooms", "1")}
                sx={{
                  border: "1px solid #7D8383",
                  padding: "2px 20px",
                  borderRadius: "50px",
                  color: "#7D8383",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "600",
                  cursor: "pointer",
                  "&.active": {
                    borderColor: "#2bc4c0",
                    background: "#2bc4c0",
                    color: "#fff",
                  },
                }}
              >
                1
              </Box>
              <Box
                className={filters.bathrooms === "2" ? "active" : ""}
                onClick={() => handleFilterChange("bathrooms", "2")}
                sx={{
                  border: "1px solid #7D8383",
                  padding: "2px 20px",
                  borderRadius: "50px",
                  color: "#7D8383",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "600",
                  cursor: "pointer",
                  "&.active": {
                    borderColor: "#2bc4c0",
                    background: "#2bc4c0",
                    color: "#fff",
                  },
                }}
              >
                2
              </Box>
              <Box
                className={filters.bathrooms === "3" ? "active" : ""}
                onClick={() => handleFilterChange("bathrooms", "3")}
                sx={{
                  border: "1px solid #7D8383",
                  padding: "2px 20px",
                  borderRadius: "50px",
                  color: "#7D8383",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "600",
                  cursor: "pointer",
                  "&.active": {
                    borderColor: "#2bc4c0",
                    background: "#2bc4c0",
                    color: "#fff",
                  },
                }}
              >
                3
              </Box>
              <Box
                className={filters.bathrooms === "4" ? "active" : ""}
                onClick={() => handleFilterChange("bathrooms", "4")}
                sx={{
                  border: "1px solid #7D8383",
                  padding: "2px 20px",
                  borderRadius: "50px",
                  color: "#7D8383",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "600",
                  cursor: "pointer",
                  "&.active": {
                    borderColor: "#2bc4c0",
                    background: "#2bc4c0",
                    color: "#fff",
                  },
                }}
              >
                4
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "5px",
                "& input": {
                  width: "100%",
                  padding: "8px 14px",
                  borderRadius: "50px",
                  border: "1px solid #7D8383",
                },
                "& input:focus": {
                  outline: "0",
                  borderColor: "#1aad9f",
                },
              }}
            >
              <input
                placeholder="Bathroom"
                value={filters.bathrooms}
                onChange={(e) =>
                  handleFilterChange("bathrooms", e.target.value)
                }
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: "20px", display: "flex", gap: "10px" }}>
            <Button
              variant="contained"
              onClick={() => handleReset("bads")}
              sx={{
                backgroundColor: "transparent",
                border: "1px solid #2EC4B6",
                color: "#2EC4B6",
                borderRadius: "6px",
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "uppercase",
                fontWeight: 600,
                width: "50%",
                boxShadow: "none",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "#2bc4c0",
                },
              }}
            >
              reset
            </Button>
            <Button
              variant="contained"
              onClick={() => handleDone("bads")}
              sx={{
                backgroundColor: "#2EC4B6",
                borderRadius: "6px",
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "uppercase",
                fontWeight: 600,
                width: "50%",
                boxShadow: "none",
                color: "#fff",
                fontFamily: "Quicksand",
                "&:hover": {
                  backgroundColor: "#2bc4c0",
                },
              }}
            >
              done
            </Button>
          </Box>
        </Box>
      </Menu>

      {/* Price Menu */}
      <Menu
        id="basic-menu"
        anchorEl={anchorElPrice}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isPriceMenuOpen}
        onClose={handleClosePriceMenu}
        sx={{
          "& .MuiMenu-paper": {
            minWidth: "300px",
            maxWidth: "400px",
            padding: "16px",
            backgroundColor: "white",
            color: "black",
          },
        }}
        MenuListProps={{
          "aria-labelledby": "more-filters-button",
          role: "menu",
          sx: { padding: 0 },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                width: "50%",
                "& input": {
                  width: "100%",
                  padding: "8px 14px",
                  borderRadius: "10px",
                  border: "1px solid #7D8383",
                },
                "& input:focus": {
                  outline: "0",
                  borderColor: "#1aad9f",
                },
                "& label": {
                  color: "#495251",
                  fontSize: "18px",
                  lineHeight: "26px",
                  fontWeight: "500",
                  marginBottom: "5px",
                },
              }}
            >
              <label>Minimum</label>
              <input
                placeholder="0"
                value={filters.min_price}
                onChange={(e) =>
                  handleFilterChange("min_price", e.target.value)
                }
              />
            </Box>
            <Box
              sx={{
                width: "50%",
                "& input": {
                  width: "100%",
                  padding: "8px 14px",
                  borderRadius: "10px",
                  border: "1px solid #7D8383",
                },
                "& input:focus": {
                  outline: "0",
                  borderColor: "#1aad9f",
                },
                "& label": {
                  color: "#495251",
                  fontSize: "18px",
                  lineHeight: "26px",
                  fontWeight: "500",
                  marginBottom: "5px",
                },
              }}
            >
              <label>Maximum</label>
              <input
                placeholder="Any"
                value={filters.max_price}
                onChange={(e) =>
                  handleFilterChange("max_price", e.target.value)
                }
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: "10px", display: "flex", gap: "10px" }}>
            <Button
              variant="contained"
              onClick={() => handleReset("price")}
              sx={{
                backgroundColor: "transparent",
                border: "1px solid #2EC4B6",
                color: "#2EC4B6",
                borderRadius: "6px",
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "uppercase",
                fontWeight: 600,
                width: "50%",
                boxShadow: "none",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "#2bc4c0",
                },
              }}
            >
              reset
            </Button>
            <Button
              variant="contained"
              onClick={() => handleDone("price")}
              sx={{
                backgroundColor: "#2EC4B6",
                borderRadius: "6px",
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "uppercase",
                fontWeight: 600,
                width: "50%",
                boxShadow: "none",
                color: "#fff",
                fontFamily: "Quicksand",
                "&:hover": {
                  backgroundColor: "#2bc4c0",
                },
              }}
            >
              done
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

export default HomeContent;
