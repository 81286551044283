import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Container,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  Pagination,
  Skeleton,
} from "@mui/material";
import dummyProperty from "../../asset/Homepage-image-new.png";
import location from "../../asset/location.svg";
import brokersLogo from "../../asset/brokers-logo.jpg";
import { BuildTwoTone } from "@mui/icons-material";
import agentBadges from "../../asset/agentBadges.svg";
import diamond from "../../asset/diamond.svg";
import response from "../../asset/response.svg";
import searchLogo from "../../asset/search.svg";
import { useNavigate } from "react-router-dom";
import { useGetBrokerListQuery } from "../../store/broker/brokerListingsApiSlice";
import { debounce } from "lodash";

export const Brokers = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [brokersList, setBrokersList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tempSearch, setTempSearch] = useState("");
  const [search, setSearch] = useState("");
  const [brokersLoading, setBrokersLoading] = useState(true);
  const [brokersError, setBrokersError] = useState(false);

  const {
    data: brokersListApiData,
    isLoading: brokersLoadingApi,
    isError: brokersErrorApi,
  } = useGetBrokerListQuery({ id: "", page: page, search: search });

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      setSearch(searchValue);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setTempSearch(searchValue);
    debouncedSearch(searchValue);
  };

  useEffect(() => {
    if (brokersListApiData) {
      setBrokersList(brokersListApiData?.data);
      const totalPages =
        Math.ceil(
          brokersListApiData?.page_details?.count /
            brokersListApiData?.page_details?.page_size
        ) || 1;
      setTotalPages(totalPages);
    }
    setBrokersLoading(brokersLoadingApi);
    setBrokersError(brokersErrorApi);
  }, [brokersListApiData, brokersLoadingApi, brokersErrorApi]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "184px",
          background: `url(${dummyProperty})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: { md: "left bottom -100px", xs: "inherit" },
          position: "relative",
          marginBottom: "40px",
          borderRadius: "0 0 20px 20px",
          zIndex: "1",
          "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            bottom: 0,
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "0 0 20px 20px",
            zIndex: "-1",
          },
        }}
      >
        <Box
          sx={{
            display: { xs: "grid", md: "flex" },
            maxWidth: "1100px",
            width: "100%",
            p: "110px 0 15px",
            gap: { xs: "15px", md: "30px" },
            m: "0 auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "0 20px 0 46px",
              background: "#fff",
              borderRadius: "10px",
              position: "relative",
              border: "1px solid #E4E5E5",
              justifyContent: "center",
              maxWidth: "450px",
              width: "calc(100% - 30px)",
              margin: "0 auto",
            }}
          >
            <Box sx={{ position: "absolute", top: "15px", left: "15px" }}>
              <img src={searchLogo} alt="" height={22} width={22} />
            </Box>
            <TextField
              fullWidth
              placeholder="Search Location"
              variant="outlined"
              className="srhBox"
              name="search"
              onChange={handleSearchChange}
              value={tempSearch}
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: "none",
                  "& fieldset": { border: "none" },
                },
                "& .MuiInputBase-input": {
                  padding: "12px 0",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#7D8383",
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      <Container>
        <Box
          sx={{
            display: { xs: "grid", lg: "flex" },
            gap: "30px",
            m: "0 0 25px",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", lg: "100%" },
              display: "grid",
              border: "1px solid #E4E5E5",
              borderRadius: "10px",
              background: "#fff",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid #E4E5E5",
                display: { xs: "grid", sm: "flex" },
                gap: { xs: "15px", sm: "20px" },
                alignItems: "center",
                p: "15px",
                background: "#f7fffe",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#666",
                  lineHeight: "1.25",
                  fontSize: "15px",
                }}
              >
                Explore agents with a proven track record of high response rates
                and authentic listings.
              </Typography>
            </Box>

            <Box
              sx={{
                display: "grid",
                gap: "20px",
                gridTemplateColumns: {
                  xs: "repeat(1, minmax(0, 1fr))",
                  sm: "repeat(2, minmax(0, 1fr))",
                },
                p: "15px",
                minHeight: "200px",
              }}
            >
              {brokersLoading ? (
                // Loading skeleton
                [...Array(4)].map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      border: "1px solid #E4E5E5",
                      p: "15px",
                      borderRadius: "6px",
                      gap: "15px",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width={90}
                      height={100}
                      sx={{ borderRadius: "6px" }}
                    />
                    <Box sx={{ width: "calc(100% - 105px)" }}>
                      <Skeleton width="70%" height={24} sx={{ mb: 1 }} />
                      <Skeleton width="50%" height={20} sx={{ mb: 1 }} />
                      <Skeleton width="60%" height={20} sx={{ mb: 1 }} />
                      <Box sx={{ display: "flex", gap: "10px", mt: 1 }}>
                        <Skeleton width={60} height={26} />
                        <Skeleton width={60} height={26} />
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : brokersError ? (
                // Error state
                <Box
                  sx={{
                    gridColumn: "1 / -1",
                    textAlign: "center",
                    p: "40px 20px",
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#495251", mb: 1 }}>
                    Oops! Something went wrong
                  </Typography>
                  <Typography sx={{ color: "#666", mb: 2 }}>
                    We're having trouble loading the broker list. Please try
                    again later.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => window.location.reload()}
                    sx={{
                      backgroundColor: "#2EC4B6",
                      "&:hover": { background: "#1aad9f" },
                    }}
                  >
                    Refresh Page
                  </Button>
                </Box>
              ) : brokersList?.length === 0 ? (
                // Empty state
                <Box
                  sx={{
                    gridColumn: "1 / -1",
                    textAlign: "center",
                    p: "40px 20px",
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#495251", mb: 1 }}>
                    No Brokers Found
                  </Typography>
                  <Typography sx={{ color: "#666", mb: 2 }}>
                    {search
                      ? "No brokers match your search criteria. Try different keywords."
                      : "There are no brokers available at the moment."}
                  </Typography>
                  {search && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSearch("");
                        setTempSearch("");
                      }}
                      sx={{
                        backgroundColor: "#2EC4B6",
                        "&:hover": { background: "#1aad9f" },
                      }}
                    >
                      Clear Search
                    </Button>
                  )}
                </Box>
              ) : (
                brokersList?.map((broker) => (
                  <Box
                    key={broker.id}
                    className="broker-card"
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                      },
                      alignItems: "center",
                      border: "1px solid #E4E5E5",
                      p: "20px",
                      borderRadius: "12px",
                      gap: "20px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
                        borderColor: "#2EC4B6",
                      },
                    }}
                    onClick={() => navigate(`/brokers/info?id=${broker?.id}`)}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: { xs: "140px", sm: "140px" },
                        height: { xs: "140px", sm: "140px" },
                      }}
                    >
                      <img
                        src={
                          broker?.profile_picture ||
                          `https://ui-avatars.com/api/?name=${broker?.first_name}+${broker?.last_name}&background=83bab5`
                        }
                        alt={`${broker?.first_name} ${broker?.last_name}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 8,
                          right: 8,
                          background: "#fff",
                          padding: "4px",
                          borderRadius: "4px",
                          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                        }}
                      >
                        <img
                          style={{
                            width: "24px",
                            height: "24px",
                            objectFit: "contain",
                          }}
                          src={
                            broker?.company_profile?.company_logo || brokersLogo
                          }
                          alt="Company logo"
                        />
                      </Box>
                    </Box>

                    <Box sx={{ width: "100%" }}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#2EC4B6",
                          mb: 1,
                        }}
                      >
                        {broker?.first_name} {broker?.last_name}
                      </Typography>

                      <Box
                        sx={{
                          display: "grid",
                          gap: "5px",
                          marginBottom: "15px",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "#666",
                              mb: 0,
                            }}
                          >
                            Company
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#495251",
                              mb: 0,
                            }}
                          >
                            {broker?.company_name}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              color: "#666",
                              mb: 0,
                            }}
                          >
                            Location
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#495251",
                              mb: 0,
                            }}
                          >
                            {broker?.service_areas}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "12px",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          sx={{
                            border: "1px solid #E4E5E5",
                            borderRadius: "20px",
                            fontSize: "13px",
                            fontWeight: "600",
                            py: "4px",
                            px: "12px",
                            color: "#495251",
                            backgroundColor: "#f7fffe",
                            "&:hover": {
                              borderColor: "#2EC4B6",
                              background: "#2EC4B6",
                              color: "#fff",
                            },
                          }}
                        >
                          {broker?.sale_property_count} Sale
                        </Button>
                        <Button
                          sx={{
                            border: "1px solid #E4E5E5",
                            borderRadius: "20px",
                            fontSize: "13px",
                            fontWeight: "600",
                            py: "4px",
                            px: "12px",
                            color: "#495251",
                            backgroundColor: "#f7fffe",
                            "&:hover": {
                              borderColor: "#2EC4B6",
                              background: "#2EC4B6",
                              color: "#fff",
                            },
                          }}
                        >
                          {broker?.rent_property_count} Rentals
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: "25px 15px",
                borderTop: "1px solid #E4E5E5",
                background: "#f5f5f5",
                borderRadius: "0 0 10px 10px",
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                disabled={
                  brokersListApiData?.page_details?.count <=
                  brokersListApiData?.page_details?.page_size
                }
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#000000",
                    fontFamily: "Quicksand",
                    fontWeight: "600",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#2EC4B6 !important",
                    color: "#fff !important",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};
