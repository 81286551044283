import { Box, useTheme } from "@mui/material";
import React from "react";

const CustomPaperBox = ({ children, customStyle }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...customStyle,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 10px 0px",
        padding: "20px",
        borderRadius: "20px",
        marginTop: "20px",
        color: "#dbdbeb99",
      }}
    >
      {children}
    </Box>
  );
};

export default CustomPaperBox;
