import React, { useEffect, useState } from "react";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./Header";
import Sidebar, { DrawerHeader } from "./SideBar";
import { Box } from "@mui/material";
import Loader from "../loader/Loader";

const BoxContainer = styled(Box)({
  display: "flex",
});

const MainContent = styled("main")({
  flexGrow: 1,
  p: 3,
  minHeight: "100vh",
});

const darkTheme = createTheme({
  palette: {
    type: "dark",
    mode: "dark",
    text: {
      primary: "#fff",
      secondary: "#fff",
      disabled: "#000",
    },
    primary: {
      main: "#2ec4b6", // Example primary color for light mode
    },
    secondary: {
      main: "#fff",
    },
    background: {
      default: "#011627",
      paper: "#072035",
    },
  },
});

const lightTheme = createTheme({
  palette: {
    type: "light",
    mode: "light",
    text: {
      primary: "#011627",
      secondary: "#011627",
      disabled: "#000",
    },
    primary: {
      main: "#2ec4b6", // Example primary color for light mode
    },
    secondary: {
      main: "#000",
    },
    background: {
      default: "#f4f7fe",
      paper: "#fff",
    },
  },
});

export default function Layout({ children }) {
  const [open, setOpen] = React.useState(false);

  const handleDrawer = () => {
    setOpen(!open);
  };

  // Initialize the theme mode based on local storage or default to 'light'
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("themeMode") || "light"
  );

  // Toggle between light and dark mode
  const toggleTheme = () => {
    const newThemeMode = themeMode === "light" ? "dark" : "light";
    setThemeMode(newThemeMode);
    localStorage.setItem("themeMode", newThemeMode); // Save to local storage
  };

  // Apply the theme mode when the component mounts
  useEffect(() => {
    const savedThemeMode = localStorage.getItem("themeMode");
    if (savedThemeMode) {
      setThemeMode(savedThemeMode);
    }
  }, []);

  const theme = themeMode === "light" ? lightTheme : darkTheme;

  const [showLoader, setShowLoader] = useState(true); // Initialize with an empty string or null

  useEffect(() => {
    // Simulate loading for 2 seconds
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1000);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  if (showLoader) {
    return (
      <Box
        sx={{
          height: "100vh",
          backgroundColor: theme?.palette?.background?.default,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BoxContainer>
        <Header
          toggleTheme={toggleTheme}
          themeMode={themeMode}
          open={open}
          handleDrawer={handleDrawer}
          setOpen={setOpen}
        />
        <Sidebar open={open} setOpen={setOpen} />
        <MainContent
          sx={{
            backgroundColor: theme?.palette?.background?.default,
            padding: "24px",
            overflow: "hidden",
          }}
        >
          <DrawerHeader />
          <Box
            id="main_box"
            sx={{
              transition: "all 0.3s",
              [theme.breakpoints.up("lg")]: {
                width: open ? "calc(100% - 240px)" : "calc(100% - 90px)",
                marginLeft: open ? "auto" : "auto",
              },
            }}
            onClick={() => setOpen(false)}
          >
            {children}
          </Box>
        </MainContent>
      </BoxContainer>
    </ThemeProvider>
  );
}
