import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  Select,
  MenuItem,
  Container,
  Menu,
  Pagination,
  Skeleton,
} from "@mui/material";
import searchLogo from "../../asset/search.svg";
// import filterIcon from "../../asset/filter.svg";
import filterIcongray from "../../asset/filter-logo-gray.svg";
import dummyProperty from "../../asset/Homepage-image-new.png";
import { Link, useNavigate } from "react-router-dom";
import CustomChips from "../../theme/style-components/CustomChips";
import CustomLoader from "../../theme/style-components/CustomLoader";
import {
  useDeleteMyListingMutation,
  useGetMyPropertyListingQuery,
} from "../../store/property/propertyApiSlice";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { showError } from "../shared/helperFunction";
import CustomTable from "../../theme/style-components/CustomTable";
import CustomModal from "../../theme/style-components/CustomModal";
import { Create } from "@mui/icons-material";
import CreatePropertyOptionsModal from "./CreatePropertyOptionsModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Edit, Delete } from "@mui/icons-material";
import bath from "../../asset/bath.svg";
import bed from "../../asset/bed.svg";
import CustomAutoComplete from "../../theme/style-components/CustomAutoComplete";
import { useGetBrokerListQuery } from "../../store/broker/brokerListingsApiSlice";
import { debounce } from "lodash";
import { selectCurrentUser } from "../../store/auth/authSlice";
import { useSelector } from "react-redux";

const MyListings = () => {
  const currentUser = useSelector(selectCurrentUser);
  const { data: agentList } = useGetBrokerListQuery({
    page: 1,
    search: "",
    id: "",
  });

  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState();
  const [selectedListing, setSelectedListing] = useState({});
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({
    listing_status: "",
    search: "",
    property_type: "",
    bedrooms: "",
    bathrooms: "",
    min_price: "",
    max_price: "",
    area_sqft: "",
    agent: currentUser?.roles?.some((role) => role === 2)
      ? [currentUser?.id]
      : [],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [tempFilterValues, setTempFilterValues] = useState({ ...filterValues });
  const [totalPages, setTotalPages] = useState(1);

  const theme = useTheme();

  //base query hooks
  const {
    isLoading,
    data: myListingReturned,
    isSuccess,
    isError,
    refetch,
  } = useGetMyPropertyListingQuery({ page: page, ...filterValues });

  console.log(query, "query");

  const [deleteMyListing] = useDeleteMyListingMutation();

  const [myListingData, setMyListingData] = useState([]);

  const navigate = useNavigate();

  // Add filter handlers
  const handleMoreFiltersClick = (event) => {
    setTempFilterValues({ ...filterValues });
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreFiltersMenu = () => {
    setTempFilterValues({ ...filterValues });
    setAnchorEl(null);
  };

  const handleFilterChange = (event, newValue = null) => {
    // If newValue is provided, it's from the AutoComplete component
    if (newValue !== null) {
      setTempFilterValues((prev) => ({
        ...prev,
        agent: newValue.map((agent) => agent.id),
      }));
      // Immediately apply the filter for agent selection
      setFilterValues((prev) => ({
        ...prev,
        agent: newValue.map((agent) => agent.id),
      }));
      setPage(1);
      refetch(); // Trigger API call immediately for agent filter
      return;
    }

    // Handle regular input changes
    const { name, value } = event.target;
    setTempFilterValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleApplyFilters = () => {
    // No need to extract IDs here anymore since we're already storing IDs
    setFilterValues(tempFilterValues);
    setPage(1);
    refetch();
    setAnchorEl(null);
  };

  const handleResetFilters = () => {
    const resetValues = {
      listing_status: "",
      search: "",
      property_type: "",
      bedrooms: "",
      bathrooms: "",
      min_price: "",
      max_price: "",
      area_sqft: "",
      agent: [], // Reset to empty array
    };
    setTempFilterValues(resetValues);
    setFilterValues(resetValues);
    setSearchText("");
    setPage(1);
  };

  const columns = [
    { id: "id", label: "Id" },
    { id: "property", label: "Property" },
    { id: "location", label: "Location" },
    { id: "tenant_status", label: "Tenant Status" },
    { id: "tokenomics", label: "Tokenomics" },
    { id: "status", label: "Status" },
    { id: "action", label: "Action" },
  ];

  const renderStatus = (status) => {
    if (status === "SOLDOUT") {
      return <CustomChips label="Soldout" labelColor="error" color="error" />;
    }
    if (status === "AVAILABLE") {
      return (
        <CustomChips label="Enable" labelColor="primary" color="primary" />
      );
    }
    if (status === "DISABLED") {
      return (
        <CustomChips label="Disabled" labelColor="warning" color="warning" />
      );
    }
  };

  // const data =
  //   myListingData?.length > 0
  //     ? myListingData?.map((data) => ({
  //         id: data.id,
  //         property: (
  //           <Box
  //             sx={{
  //               "& a": {
  //                 textDecoration: "none",
  //                 color: theme.palette.primary.main,
  //                 fontWeight: "bold",
  //               },
  //             }}
  //           >
  //             <Link to={`/my-listings/view/${data.id}`}>{data?.name}</Link>
  //           </Box>
  //         ),
  //         location: data?.location?.address ? data?.location?.address : "NA",
  //         tenant_status: (
  //           <Typography sx={{ textTransform: "capitalize" }}>
  //             {data?.tenant_status?.toLowerCase()}
  //           </Typography>
  //         ),
  //         tokenomics: (
  //           <Box>
  //             <Typography>
  //               Token price:{" "}
  //               {data?.tokenomics?.available_tokens
  //                 ? `$${data?.tokenomics?.token_price_aed_cents / 100}`
  //                 : "NA"}
  //             </Typography>
  //             <Typography>
  //               Total tokens:{" "}
  //               {data?.tokenomics?.available_tokens
  //                 ? data?.tokenomics?.total_tokens
  //                 : "NA"}
  //             </Typography>
  //             <Typography>
  //               Available Tokens:{" "}
  //               {data?.tokenomics?.available_tokens
  //                 ? data?.tokenomics?.available_tokens
  //                 : "NA"}
  //             </Typography>
  //             <Typography>
  //               sold_tokens:{" "}
  //               {data?.tokenomics?.available_tokens
  //                 ? data?.tokenomics?.sold_tokens
  //                 : "NA"}
  //             </Typography>
  //           </Box>
  //         ),
  //         status: renderStatus(data?.listing_status),
  //         action: "ACTION",
  //       }))
  //     : [];

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setMyListingData(myListingReturned?.data);
      setCount(myListingReturned?.page_details?.count);
      setTotalPages(myListingReturned?.page_details?.total_pages);
      setRowsPerPage(myListingReturned?.page_details?.page_size);
    }
  }, [isLoading, page, myListingReturned?.data]);

  // Add slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // Add custom arrow components
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <Button
        onClick={onClick}
        sx={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          minWidth: "auto",
          width: "32px",
          height: "32px",
          background: "rgba(255,255,255,0.8)",
          borderRadius: "50%",
          zIndex: 1,
          "&:hover": { background: "rgba(255,255,255,1)" },
          "&::before": {
            content: '"›"',
            fontSize: "24px",
            color: "#495251",
            lineHeight: "10px",
            margin: "-6px 0 0",
          },
        }}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <Button
        onClick={onClick}
        sx={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          minWidth: "auto",
          width: "32px",
          height: "32px",
          background: "rgba(255,255,255,0.8)",
          borderRadius: "50%",
          zIndex: 1,
          "&:hover": { background: "rgba(255,255,255,1)" },
          "&::before": {
            content: '"‹"',
            fontSize: "24px",
            color: "#495251",
            lineHeight: "10px",
            margin: "-6px 0 0",
          },
        }}
      />
    );
  }

  //handle page function
  const onPageChange = (value) => {
    setPage(value);
  };

  //handle row page function
  const onRowsPerPageChange = (value) => {
    setRowsPerPage(value);
  };

  const setSelectedData = (item) => {
    setSelectedListing(item);
  };

  const handleCreateClick = () => {
    // navigate("/my-listings/create-properties")
    setIsCreateModalOpen(true);
  };

  const handleEditClick = (id) => {
    // console.log("click on edit", selectedListing);
    navigate(`/my-listings/edit-properties/${id}`);
  };

  // Add debounced search handler
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      setFilterValues((prev) => ({
        ...prev,
        search: searchValue,
        page: 1,
      }));
    }, 500),
    []
  );

  // Update the handleOnSearch function to use debouncing
  const handleOnSearch = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    debouncedSearch(newSearchText);
  };

  const handleDelteClick = (listing) => {
    console.log("click on delete");
    const id = listing.id;
    swal({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: "Delete",
      },
      dangerMode: true,
    }).then(async (confirmed) => {
      if (confirmed) {
        // User clicked the 'OK' button
        // Perform the desired action
        const response = await deleteMyListing(id)
          .unwrap()
          .then((res) => {
            const filtered = myListingData.filter((item) => item.id !== id);
            if (filtered?.length) {
              setMyListingData(filtered);
            }
            res &&
              toast.success("Listing deleted successfully!!", {
                position: toast.POSITION.TOP_RIGHT,
              });
          })
          .catch((err) => {
            showError(err?.data);
          });
      } else {
        // User clicked the 'Cancel' button
        // Handle the cancellation
      }
    });
  };

  // Update the renderListingCard function
  const renderListingCard = (listing) => (
    <Box
      item
      key={listing.id}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxShadow: "none",
        p: "0",
        border: "1px solid #E5E5E5",
        borderRadius: "10px",
        "&:hover": {
          border: "1px solid #00c6b6",
        },
        "& .MuiCardContent-root": {
          p: "0",
        },
      }}
    >
      <CardContent
        sx={{
          p: "0 !important",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "230px",
            borderRadius: "6px",
            overflow: "hidden",
            position: "relative",
            p: "15px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "30px",
              right: "30px",
              zIndex: "1",
              "& .MuiChip-root ": {
                m: 0,
                borderRadius: "6px",
              },
            }}
          >
            {renderStatus(listing.listing_status)}
          </Box>
          {listing?.images?.length > 0 ? (
            <Slider {...sliderSettings}>
              {listing.images.map((image, index) => (
                <div key={index}>
                  <img
                    src={image.image_url}
                    alt={`Property ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#F8F8F8",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "#7D8383",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                No images available
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ p: "0 15px 15px" }}>
          {/* Title and Status */}
          <Box
            sx={{ display: "flex", justifyContent: "space-between", mb: "5px" }}
          >
            <Link
              to={`/my-listings/view/${listing.id}`}
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
                fontSize: "1rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                height: "24px",
                display: "block",
                fontWeight: "500",
              }}
            >
              {listing.name}
            </Link>
          </Box>

          {/* Property Details */}
          <Typography variant="body2" color="text.secondary" gutterBottom>
            <strong>Location:</strong> {listing.location?.address || "NA"}
          </Typography>

          <Typography variant="body2" color="text.secondary" gutterBottom>
            <strong>Tenant Status:</strong> {listing.tenant_status}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(3, minmax(0, 1fr))",
              },
              gap: "25px",
              background: "#F8F8F8",
              padding: "12px",
              borderRadius: "6px",
              m: "8px 0",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={bed} alt="Bedrooms" style={{ marginRight: "8px" }} />
                <Typography sx={{ fontWeight: "700", color: "#495251" }}>
                  {listing?.features?.bedrooms || "NA"}
                </Typography>
              </Box>
              <Typography sx={{ fontSize: "12px", color: "#7D8383" }}>
                Bedrooms
              </Typography>
            </Box>

            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={bath}
                  alt="Bathrooms"
                  style={{ marginRight: "8px" }}
                />
                <Typography sx={{ fontWeight: "700", color: "#495251" }}>
                  {listing?.features?.bathrooms || "NA"}
                </Typography>
              </Box>
              <Typography sx={{ fontSize: "12px", color: "#7D8383" }}>
                Bathrooms
              </Typography>
            </Box>

            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#495251",
                }}
              >
                {listing?.features?.area_sqft || "NA"}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#7D8383" }}>
                {"Area (Sq.Ft)"}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            <strong>Tenant Status:</strong> {listing.tenant_status}
          </Typography>

          {/* Tokenomics Grid */}
          <Box sx={{ mt: 2 }}></Box>
        </Box>
        {/* Action Buttons */}
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            borderTop: "1px solid #E5E5E5",
            p: "15px",
            marginTop: "auto",
          }}
        >
          <Button
            variant="contained"
            size="small"
            startIcon={<Edit />}
            onClick={() => {
              handleEditClick(listing?.id);
            }}
            sx={{
              width: "calc(50% - 8px)",
              boxShadow: "none",
              background: "#fff",
              border: "1px solid #2ec4b6",
              color: "#2ec4b6",
              "&:hover": {
                background: "#2ec4b6",
                color: "#fff",
              },
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            startIcon={<Delete />}
            onClick={() => {
              setSelectedListing(listing);
              handleDelteClick(listing);
            }}
            sx={{
              width: "calc(50% - 8px)",
              boxShadow: "none",
              background: "#fff",
              border: "1px solid #d32f2f",
              color: "#d32f2f",
              "&:hover": {
                background: "#d32f2f",
                color: "#fff",
              },
            }}
          >
            Delete
          </Button>
        </Box>
      </CardContent>
    </Box>
  );

  // Update page change handler
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    // Reset search text and filters when changing pages
    // setSearchText("");
    // setFilterValues({
    //   listing_status: "",
    //   property_type: "",
    //   bedrooms: "",
    //   bathrooms: "",
    //   min_price: "",
    //   max_price: "",
    //   area_sqft: "",
    // });
  };

  // Add this function near the top of the component, with other helper functions
  const getAgentName = (agentId) => {
    const agent = agentList?.data?.find((agent) => agent.id === agentId);
    return agent ? agent.name : "All Agents";
  };

  console.log("filterValues", tempFilterValues);

  // Add this new component for the listing skeleton
  const ListingSkeleton = () => (
    <Box
      sx={{
        height: "100%",
        border: "1px solid #E5E5E5",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ p: "15px" }}>
        {/* Image skeleton */}
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ borderRadius: "10px" }}
        />
      </Box>

      <Box sx={{ p: "0 15px 15px" }}>
        {/* Title skeleton */}
        <Skeleton width="70%" height={24} sx={{ mb: 1 }} />

        {/* Location and tenant status skeletons */}
        <Skeleton width="90%" height={20} sx={{ mb: 1 }} />
        <Skeleton width="60%" height={20} sx={{ mb: 1 }} />

        {/* Features box skeleton */}
        <Box
          sx={{
            background: "#F8F8F8",
            padding: "12px",
            borderRadius: "6px",
            m: "8px 0",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "25px",
            }}
          >
            {[1, 2, 3].map((item) => (
              <Box key={item} sx={{ textAlign: "center" }}>
                <Skeleton width="40px" height={40} sx={{ m: "0 auto" }} />
                <Skeleton width="100%" height={20} />
              </Box>
            ))}
          </Box>
        </Box>

        {/* Action buttons skeleton */}
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            borderTop: "1px solid #E5E5E5",
            pt: "15px",
            mt: "auto",
          }}
        >
          <Skeleton width="50%" height={36} />
          <Skeleton width="50%" height={36} />
        </Box>
      </Box>
    </Box>
  );

  // Add useEffect to handle initial agent filter
  useEffect(() => {
    if (currentUser?.roles?.some((role) => role === 2)) {
      setFilterValues((prev) => ({
        ...prev,
        agent: [currentUser.id],
      }));
      setTempFilterValues((prev) => ({
        ...prev,
        agent: [currentUser.id],
      }));
    }
  }, [currentUser]);

  return (
    <Box sx={{ background: "#FFF", borderRadius: "16px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: "15px",
          alignItems: "center",
          borderBottom: "1px solid #E5E5E5",
        }}
      >
        <Typography variant="h5" sx={{ fontSize: "1.25rem", m: "0" }}>
          My Listings
        </Typography>
        <Button
          variant="contained"
          startIcon={<Create />}
          onClick={handleCreateClick}
          sx={{
            background: "#fff",
            boxShadow: "none",
            color: theme.palette.primary.main,
            border: "1px solid #00c6b6",
            "&:hover": {
              background: theme.palette.primary.main,
              color: "#fff",
            },
          }}
        >
          Create Property
        </Button>
      </Box>

      {/* Add Hero Section with Filters */}
      {/* <Box
        sx={{
          // minHeight: "184px",
          background: `url(${dummyProperty})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: { md: "left bottom -100px", xs: "inherit" },
          position: "relative",
          marginBottom: "40px",
          borderRadius: "20px 20px 20px 20px",
          zIndex: "1",
          "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            bottom: 0,
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "20px 20px 20px 20px",
            zIndex: "-1",
          },
        }}
      > */}
      <Box sx={{ p: { xs: "20px", md: "25px" } }}>
        <Box
          sx={{
            display: { xs: "flex", sm: "flex" },
            flexWrap: "wrap",
            width: "100%",
            gap: "15px",
            justifyContent: "space-between",
            m: "0 0 20px",
          }}
        >
          {/* Agent Dropdown */}
          {currentUser?.roles?.some((role) => role === 2) && (
            <Box
              sx={{
                display: "flex",
                background: "#fff",
                borderRadius: "10px",
                width: { xs: "100%", md: "380px" },
                border: "1px solid #E5E5E5",
              }}
            >
              <CustomAutoComplete
                multiple
                options={agentList?.data || []}
                value={(agentList?.data || []).filter((agent) =>
                  tempFilterValues.agent.includes(agent.id)
                )}
                onChange={handleFilterChange}
                getOptionLabel={(option) =>
                  `${option.first_name || ""} ${
                    option.last_name || ""
                  }`.trim() || "All Agents"
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    {`${option.first_name || ""} ${
                      option.last_name || ""
                    }`.trim() || "All Agents"}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      tempFilterValues.agent.length === 0 ? "Select Agents" : ""
                    }
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      "& .MuiInputBase-input": {
                        padding: "10px 15px 9px !important",
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#7D8383",
                      },
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Box>
          )}

          {/* Search Box */}
          <Box
            sx={{
              display: { xs: "grid", sm: "flex" },
              background: "#fff",
              borderRadius: "10px",
              maxWidth: {
                xs: "100%",
                sm: "calc(100% - 200px)",
                md: "calc(100% - 600px)",
              },
              width: "100%",
              m: { xs: "auto", md: "0" },
              height: { xs: "auto", sm: "44px" },
              border: "1px solid #E5E5E5",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "0 20px 0 46px",
                position: "relative",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: "15px",
                  height: "20px",
                }}
              >
                <img
                  src={searchLogo}
                  alt=""
                  height={20}
                  width={20}
                  style={{ verticalAlign: "top" }}
                />
              </Box>
              <TextField
                fullWidth
                placeholder="Search Properties"
                variant="outlined"
                className="srhBox"
                onChange={handleOnSearch}
                value={searchText}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                    "& fieldset": { border: "none" },
                  },
                  "& .MuiInputBase-input": {
                    padding: "9px 0",
                    fontSize: "16px !important",
                    fontWeight: "600 !important",
                    color: "#7D8383",
                    height: "24px !important",
                  },
                }}
              />
            </Box>
          </Box>

          {/* Filter Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              border: "1px solid #E5E5E5",
              borderRadius: "10px",
              height: { xs: "auto", sm: "44px" },
              width: { xs: "100%", sm: "185px" },
            }}
          >
            <Button
              onClick={handleMoreFiltersClick}
              sx={{
                background: "#fff",
                padding: "10px 15px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                color: "#495251",
                textTransform: "uppercase",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "20px",
                borderRadius: "10px",
                width: "100%",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#2EC4B6",
                },
              }}
            >
              <img
                src={filterIcongray}
                alt=""
                style={{ verticalAlign: "top" }}
              />
              More Filters
            </Button>
          </Box>
        </Box>
        {/* </Box> */}
        {/* Filter Menu */}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleCloseMoreFiltersMenu}
          sx={{
            "& .MuiMenu-paper": {
              minWidth: "300px",
              maxWidth: "400px",
              padding: "16px",
              backgroundColor: "white",
              color: "black",
            },
          }}
          MenuListProps={{
            "aria-labelledby": "more-filters-button",
            role: "menu",
            sx: { padding: 0 },
          }}
        >
          <Box sx={{ p: "4px" }}>
            {/* Header with Reset button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "10px",
                borderRadius: "6px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontFamily: "Quicksand", fontWeight: "700" }}
              >
                Filters
              </Typography>
              <Button
                onClick={handleResetFilters}
                sx={{
                  color: "#2EC4B6",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "rgba(46, 196, 182, 0.04)" },
                }}
              >
                Reset
              </Button>
            </Box>

            {/* Price Range */}
            <Box sx={{ mb: 2 }}>
              <Typography
                sx={{ mb: 1, fontFamily: "Quicksand", fontWeight: "600" }}
              >
                Price Range (AED)
              </Typography>
              <Box sx={{ display: "flex", gap: 1, borderRadius: "6px" }}>
                <TextField
                  name="min_price"
                  type="number"
                  placeholder="Min"
                  size="small"
                  value={tempFilterValues.min_price}
                  onChange={handleFilterChange}
                  sx={{ width: "50%" }}
                />
                <TextField
                  name="max_price"
                  type="number"
                  placeholder="Max"
                  size="small"
                  value={tempFilterValues.max_price}
                  onChange={handleFilterChange}
                  sx={{ width: "50%" }}
                />
              </Box>
            </Box>

            {/* Property Features */}
            <Box sx={{ mb: 2 }}>
              <Typography
                sx={{ mb: 1, fontFamily: "Quicksand", fontWeight: "600" }}
              >
                Property Features
              </Typography>
              <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                <TextField
                  name="bedrooms"
                  type="number"
                  placeholder="Bedrooms"
                  size="small"
                  value={tempFilterValues.bedrooms}
                  onChange={handleFilterChange}
                  sx={{ width: { xs: "100%", sm: "calc(50% - 8px)" } }}
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <TextField
                  name="bathrooms"
                  type="number"
                  placeholder="Bathrooms"
                  size="small"
                  value={tempFilterValues.bathrooms}
                  onChange={handleFilterChange}
                  sx={{ width: { xs: "100%", sm: "calc(50% - 8px)" } }}
                  InputProps={{ inputProps: { min: 0 } }}
                />
                <TextField
                  name="area_sqft"
                  type="number"
                  placeholder="Area (Sq.Ft)"
                  size="small"
                  value={tempFilterValues.area_sqft}
                  onChange={handleFilterChange}
                  sx={{ width: "100%" }}
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Box>
            </Box>

            {/* Status and Property Type */}
            <Box sx={{ mb: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
              <Box sx={{ width: { xs: "100%", sm: "calc(50% - 8px)" } }}>
                <Typography
                  sx={{ mb: 1, fontFamily: "Quicksand", fontWeight: "600" }}
                >
                  Status
                </Typography>
                <Select
                  name="listing_status"
                  value={tempFilterValues.listing_status}
                  onChange={handleFilterChange}
                  fullWidth
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="AVAILABLE">Available</MenuItem>
                  <MenuItem value="DISABLED">Disabled</MenuItem>
                  <MenuItem value="SOLD">Sold</MenuItem>
                  <MenuItem value="RENTED">Rented</MenuItem>
                </Select>
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "calc(50% - 8px)" } }}>
                <Typography
                  sx={{ mb: 1, fontFamily: "Quicksand", fontWeight: "600" }}
                >
                  Type
                </Typography>
                <Select
                  name="property_type"
                  value={tempFilterValues.property_type}
                  onChange={handleFilterChange}
                  fullWidth
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="RESEDENTIAL">Residential</MenuItem>
                  <MenuItem value="COMMERCIAL">Commercial</MenuItem>
                </Select>
              </Box>
            </Box>

            {/* Action Buttons */}
            <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
              <Button
                onClick={handleCloseMoreFiltersMenu}
                sx={{
                  color: "#495251",
                  "&:hover": { backgroundColor: "rgba(73, 82, 81, 0.04)" },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleApplyFilters}
                variant="contained"
                sx={{
                  backgroundColor: "#2EC4B6",
                  "&:hover": { backgroundColor: "#2AB1A4" },
                }}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Menu>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, minmax(0, 1fr))",
              sm: "repeat(2, minmax(0, 1fr))",
              md: "repeat(3, minmax(0, 1fr))",
            },
            gap: "25px",
          }}
        >
          {isLoading
            ? Array(6)
                .fill(0)
                .map((_, index) => <ListingSkeleton key={index} />)
            : myListingData?.map((listing) => renderListingCard(listing))}
        </Box>
      </Box>

      <Box
        sx={{
          borderTop: "1px solid #E5E5E5",
          display: "flex",
          gap: "20px",
          flexWrap: "wrap",
          alignItems: "center",
          p: "15px 25px",
        }}
      >
        {/* Add Pagination */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            m: 0,
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            size="large"
            showFirstButton
            showLastButton
            sx={{
              "& .Mui-selected": {
                color: "white !important",
                borderRadius: "10px",
              },
            }}
          />
        </Box>
        {/* Show total results count */}
        <Box sx={{ textAlign: "center", m: "0 0 0 auto" }}>
          <Typography variant="body2" color="text.secondary">
            Showing {myListingData?.length || 0} of {count || 0} results
          </Typography>
        </Box>
      </Box>
      {isCreateModalOpen && (
        <CreatePropertyOptionsModal
          isCreateModalOpen={isCreateModalOpen}
          setIsCreateModalOpen={setIsCreateModalOpen}
        />
      )}
    </Box>
  );
};

export default MyListings;
