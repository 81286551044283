import React, { useState } from "react";
import CustomModal from "../../theme/style-components/CustomModal";
import { Box, Grid, Typography, Skeleton } from "@mui/material"; // Added Skeleton
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";

export const PropertyGalleryModal = ({
  propertyImages,
  isGalleryModalOpen,
  setIsGalleryModalOpen,
  mapLink,
  defaultSelectionTab,
  isLoading, // Add this prop
}) => {
  const [activeTab, setActiveTab] = useState(defaultSelectionTab || "gallery");
  const [isMapLoading, setIsMapLoading] = useState(true);

  // Gallery loading skeleton
  const GallerySkeleton = () => (
    <Grid container spacing={2}>
      {[1, 2, 3, 4].map((item) => (
        <Grid item xs={12} sm={6} key={item}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              paddingTop: "66.67%",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <Skeleton
              variant="rectangular"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              animation="wave"
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  // Map loading skeleton
  const MapSkeleton = () => (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        borderRadius: 1,
        overflow: "hidden",
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height="100%"
        animation="wave"
      />
    </Box>
  );

  return (
    <>
      <CustomModal
        handleClose={() => setIsGalleryModalOpen(false)}
        title=""
        isModalOpen={isGalleryModalOpen}
        isShowActionButton={false}
        maxWidth="lg"
      >
        <Box
          sx={{
            width: "100%",
            height: "70vh",
            maxHeight: "600px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Tab Headers - Fixed */}
          <Box
            sx={{
              display: "flex",
              gap: 2,
              borderBottom: 1,
              borderColor: "divider",
              p: 2,
              backgroundColor: "white",
              minHeight: "50px",
            }}
          >
            <Box
              onClick={() => setActiveTab("gallery")}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
                color: activeTab === "gallery" ? "#00A693" : "#666",
                borderBottom:
                  activeTab === "gallery" ? "2px solid #00A693" : "none",
                pb: 1,
              }}
            >
              <PhotoLibraryOutlinedIcon />
              <Typography sx={{ fontWeight: 600 }}>
                Photos ({propertyImages?.length || 0})
              </Typography>
            </Box>
            <Box
              onClick={() => setActiveTab("map")}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
                color: activeTab === "map" ? "#00A693" : "#666",
                borderBottom:
                  activeTab === "map" ? "2px solid #00A693" : "none",
                pb: 1,
              }}
            >
              <LocationOnOutlinedIcon />
              <Typography sx={{ fontWeight: 600 }}>Map</Typography>
            </Box>
          </Box>

          {/* Scrollable Content Area */}
          <Box
            sx={{
              flexGrow: 1,
              overflow: "auto",
              px: 2,
              py: 2,
              height: "calc(100% - 50px)",
            }}
          >
            {activeTab === "gallery" && (
              <>
                {isLoading ? (
                  <GallerySkeleton />
                ) : (
                  <Grid container spacing={2}>
                    {propertyImages?.map((image, index) => (
                      <Grid item xs={12} sm={6} key={index}>
                        <Box
                          sx={{
                            position: "relative",
                            width: "100%",
                            paddingTop: "66.67%",
                            borderRadius: 1,
                            overflow: "hidden",
                          }}
                        >
                          <Box
                            component="img"
                            src={image}
                            alt={`Property ${index + 1}`}
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              transition: "transform 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                              },
                            }}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            )}

            {activeTab === "map" && (
              <>
                {isMapLoading && <MapSkeleton />}
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 1,
                    overflow: "hidden",
                    display: isMapLoading ? "none" : "block",
                  }}
                >
                  <iframe
                    src={mapLink}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    onLoad={() => setIsMapLoading(false)}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};
