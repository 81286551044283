import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const HomeFooter = () => {
    return (
        <Box sx={{
            width: '100%',
            borderTop:'1px solid #E4E5E5',
            padding: '15px 0',            
        }}>
            <Container maxWidth="xl">
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color:'#7D8383',
                    fontSize:'16px',
                    fontWeight:'500',
                    lineHeight:'20px'
                }}>
                    <Typography variant="body2">
                        ©2025
                        <Typography variant="span" sx={{color:'#2EC4B6'}}>1byX</Typography>
                        - All right reserved
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default HomeFooter; 