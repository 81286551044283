import React from "react";
import { useState } from "react";
import { Checkbox, InputAdornment, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { encryptFn } from "../shared/helperFunction";
import { useLoginMutation } from "../../store/auth/authApiSlice";
import { useLazyGetUserProfileQuery } from "../../store/user-profile/userProfileApiSlice";
import CustomInput from "../../theme/style-components/CustomInput";
import CustomButton from "../../theme/style-components/CustomButton";
import { setUser } from "../../store/auth/authSlice";
import { useNavigate } from "react-router-dom";

const SignIn = ({ handleForget }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();
  const [isRemember, setIsRemember] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  // const [loginWithGoogle, { isLoading: isGoogleLoading }] =
  //   useLoginWithGoogleMutation();
  const theme = useTheme();
  const [getUserProfile] = useLazyGetUserProfileQuery();
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please enter a email"),
    password: yup.string().required("Please enter a password"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { email, password } = values;
      try {
        const userData = await login({ email, password }).unwrap();
        // localStorage.setItem("refreshToken", encryptFn(userData.refresh));
        // localStorage.setItem("accessToken", encryptFn(userData.access));
        // localStorage.setItem("email", email);
        // localStorage.setItem("isAuthenticated", true);
        // secureLocalStorage.setItem("password", password);
        localStorage.setItem("ACCESS_TOKEN", encryptFn(userData?.access));
        localStorage.setItem("REFRESH_TOKEN", encryptFn(userData?.refresh));
        if (userData?.access) {
          try {
            const response = await getUserProfile().unwrap();
            console.log(response, "response");
            if (response) {
              dispatch(setUser(response?.data));
              if (response?.data?.roles?.length === 0) {
                console.log("Going to register");
                navigate("/register");
              } else {
                console.log("Going to dashboard");
                navigate("/dashboard");
              }
            }
          } catch (error) {
            toast.error(error?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }

        // try {
        //   await getUserProfile()
        //     .unwrap()
        //     .then((res) => {
        //       if (res) {
        //         dispatch(setCredentials({ ...userData, email }));
        //         localStorage.setItem("userType", res.data.user_type);
        //         dispatch(setUserType(res.data.user_type));
        //         dispatch(setUser(res.data));
        //       }

        //       navigate("/dashboard", { replace: true });
        //     });
        // } catch (err) {
        //   console.log(err);
        // }
        toast.success("You are logged in!");
      } catch (error) {
        if (error) {
          toast.error("Wrong username or password");
        }
      }
    },
  });

  const handleChangeRemember = (event) => {
    setIsRemember(event.target.checked);
  };

  // const handleLoginWithGoogle = async (token) => {
  //   try {
  //     const userProfile = await loginWithGoogle(token)
  //       .unwrap()
  //       .then((res) => {
  //         if (res) {
  //           navigate("/employee");
  //         }
  //       });
  //     toast.success("Login successful!");
  //   } catch (error) {
  //     console.log("Something went wrong!");
  //     if (error?.data) {
  //       toast.error(error.data.non_field_errors[0]);
  //     }
  //   }
  // };

  // const handleGoogleLogin = useGoogleLogin({
  //   scope: "https://www.googleapis.com/auth/userinfo.email",
  //   onSuccess: (tokenResponse) => {
  //     if (tokenResponse?.access_token) {
  //       handleLoginWithGoogle(tokenResponse?.access_token);
  //     }
  //   },
  //   onError: () => {
  //     toast.error("Login Failed");
  //   },
  // });

  return (
    <>
      <Box>
        <Box sx={{ marginBottom: "15px" }}>
          <CustomInput
            id="email"
            variant="outlined"
            margin="normal"
            placeholder="Email"
            name="email"
            label="Email"
            required
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Box>
        <Box sx={{ marginBottom: "15px" }}>
          <CustomInput
            id="password"
            variant="outlined"
            margin="normal"
            placeholder="Password"
            label="Password"
            name="password"
            type={!passwordShow ? "text" : "password"}
            required
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    cursor: "pointer",
                    "& svg": {
                      fill: theme.palette.grey["A400"],
                    },
                  }}
                >
                  {passwordShow ? (
                    <VisibilityOffIcon
                      onClick={() => setPasswordShow(!passwordShow)}
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() => setPasswordShow(!passwordShow)}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            sx={{
              color: "#1F6EB9",
              "&.Mui-checked": {
                color: "#1F6EB9",
              },
            }}
            checked={isRemember}
            onChange={handleChangeRemember}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Typography
            sx={{
              color: "black",
              fontSize: { xs: "12px", sm: "14px", md: "14px" },
              lineHeight: "20px",
              fontWeight: "400",
            }}
          >
            Remember me?
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: "right",
            color: "blue",
            fontSize: { xs: "12px", sm: "14px", md: "14px" },
            lineHeight: "20px",
            fontWeight: "400",
            cursor: "pointer",
          }}
          onClick={() => handleForget()}
        >
          Forgot Password?
        </Box>
      </Box>
      <Box sx={{ marginTop: "10px", width: "100% !important" }}>
        <CustomButton
          fullWidth
          disabled={isLoading}
          size="large"
          color="primary"
          onClick={formik.handleSubmit}
          buttonName={isLoading ? "Signing..." : "Sign In"}
        />
      </Box>
      {/* <Box sx={{ marginTop: "10px" }}>
        <Button
          sx={{
            border: `1px solid ${theme.palette.border.primary}`,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.default,
            textTransform: "none",
            padding: "13px",
            borderRadius: "10px",
            fontWeight: "600",
            gap: "10px",
          }}
          onClick={() => handleGoogleLogin()}
          fullWidth
          disabled={isGoogleLoading}
        >
          <img src={GoogleIcons} alt="profile-img" />
          Sign In with Google
        </Button>
      </Box> */}
    </>
  );
};

export default SignIn;
