import React from "react";
import { useTheme } from "@mui/material";
import PublicLayout from "../../../component/layout/PublicLayout";
import ChatbotButton from "../../../component/chatbot/ChatbotButton";
import { Brokers } from "../../../component/brokers/Brokers";

const BrokersPage = () => {
  const theme = useTheme();

  return (
    <PublicLayout>
      <Brokers />
      <ChatbotButton />
    </PublicLayout>
  );
};

export default BrokersPage;
