import React, { useState, useRef, useEffect } from 'react';
import AudioRecorder from './AudioRecorder';
import { Box, Typography, CircularProgress, Fade, Paper } from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import moment from 'moment';
import './ChatBot.css';

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  const handleTranscription = async (transcription) => {
    // Add user message
    const userMessage = {
      text: transcription,
      type: 'user',
      timestamp: moment(),
    };
    setMessages(prev => [...prev, userMessage]);
    setIsTyping(true);

    try {
      // Get AI response
      const response = await fetch('http://localhost:5000/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: transcription }),
      });

      const data = await response.json();
      
      // Simulate natural typing delay
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Add AI response
      setMessages(prev => [...prev, {
        text: data.response,
        type: 'bot',
        timestamp: moment(),
      }]);
    } catch (error) {
      console.error('Error getting bot response:', error);
      setMessages(prev => [...prev, {
        text: "Sorry, I encountered an error. Please try again.",
        type: 'bot',
        timestamp: moment(),
        isError: true,
      }]);
    } finally {
      setIsTyping(false);
    }
  };

  const MessageBubble = ({ message }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: message.type === 'user' ? 'flex-end' : 'flex-start',
        mb: 1.5,
        mx: 1,
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1, 
        mb: 0.5,
        px: 0.5,
      }}>
        {message.type === 'bot' ? (
          <SmartToyOutlinedIcon fontSize="small" color="primary" />
        ) : (
          <PersonOutlineIcon fontSize="small" />
        )}
        <Typography variant="caption" color="text.secondary">
          {moment(message.timestamp).format('HH:mm')}
        </Typography>
      </Box>
      <Paper
        elevation={1}
        sx={{
          p: 2,
          maxWidth: '85%',
          borderRadius: 2.5,
          bgcolor: message.type === 'user' ? 'primary.main' : 'background.paper',
          color: message.type === 'user' ? 'primary.contrastText' : 'text.primary',
          ...(message.isError && {
            bgcolor: 'error.light',
            color: 'error.contrastText',
          }),
        }}
      >
        <Typography variant="body2" sx={{ lineHeight: 1.5 }}>{message.text}</Typography>
      </Paper>
    </Box>
  );

  return (
    <Box className="chatbot-container">
      <Box 
        className="messages-container" 
        sx={{ 
          p: 2,
          pb: 1,
        }}
      >
        {messages.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              gap: 2,
              color: 'text.secondary',
              p: 3,
            }}
          >
            <SmartToyOutlinedIcon sx={{ fontSize: 48 }} />
            <Typography variant="body1" align="center">
              Start a conversation by recording your message
            </Typography>
            <Typography variant="caption" align="center">
              Click the microphone button below to begin
            </Typography>
          </Box>
        ) : (
          <>
            {messages.map((message, index) => (
              <MessageBubble key={index} message={message} />
            ))}
            {isTyping && (
              <Fade in={true}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1, 
                  ml: 2,
                  mt: 1,
                }}>
                  <SmartToyOutlinedIcon fontSize="small" color="primary" />
                  <CircularProgress size={20} />
                </Box>
              </Fade>
            )}
          </>
        )}
        <div ref={messagesEndRef} />
      </Box>
      <Box 
        className="input-container"
        sx={{
          p: 2,
          borderTop: 1,
          borderColor: 'divider',
        }}
      >
        <AudioRecorder onTranscription={handleTranscription} />
      </Box>
    </Box>
  );
};

export default ChatBot; 