import React from 'react'
import PublicLayout from '../../../component/layout/PublicLayout'
import ChatbotButton from '../../../component/chatbot/ChatbotButton'
import { BrokerInfo } from '../../../component/brokers/BrokerInfo'

export const BrokerInfoPage = () => {
  return (
    <PublicLayout>
        <BrokerInfo />
      <ChatbotButton />
    </PublicLayout>
  )
}
