import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/token/",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    googleLogin: builder.mutation({
      query: (auth) => ({
        url: "/user/auth/google/",
        method: "POST",
        body: { auth_token: auth },
      }),
    }),
  }),
});

export const { useLoginMutation, useGoogleLoginMutation } = authApiSlice;
