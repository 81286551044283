import React, { useRef, useState } from "react";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import CustomInput from "../../theme/style-components/CustomInput";
import {
  Avatar,
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
  tooltipClasses,
} from "@mui/material";
import CustomButton from "../../theme/style-components/CustomButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setUser } from "../../store/auth/authSlice";
import { useEffect } from "react";
import CustomSelect from "../../theme/style-components/CustomSelect";
import { toast } from "react-toastify";
import { showError } from "../shared/helperFunction";
import {
  useGetCompanyDetailQuery,
  useUpdateBrokerProfileMutation,
  useUpdateDevProfileMutation,
  useUpdateUserProfileMutation,
  useUpdateCompanyDetailMutation,
} from "../../store/settings/settingsApiSlice";
import CustomCheckBox from "../../theme/style-components/CustomCheckBox";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/styles";
import {
  useGetAllCompanyMembersQuery,
  useLazyGetUserProfileQuery,
} from "../../store/user-profile/userProfileApiSlice";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const Settings = () => {
  //ref variables
  const inputRef = useRef(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const user = useSelector(selectCurrentUser);
  const theme = useTheme();
  const dispatch = useDispatch();

  const {
    data: companyMembersListApiData,
    isLoading: brokersLoadingApi,
    isError: brokersErrorApi,
  } = useGetAllCompanyMembersQuery({
    page: 1,
    page_size: 15,
    search: user?.email,
    company_id: user?.company,
  });

  const [updateUserProfile, { isLoading: isUpdateUserProfileLoading }] =
    useUpdateUserProfileMutation();
  const [updateBrokerProfile, { isLoading: isUpdateBrokerProfileLoading }] =
    useUpdateBrokerProfileMutation();
  const [updateDevProfile, { isLoading: isUpdateDevLoading }] =
    useUpdateDevProfileMutation();
  const [
    getUserProfile,
    {
      isLoading: isGetUserLoading,
      data: userData,
      isSuccess: isGetUserSuccess,
      isError: isGetUserError,
    },
  ] = useLazyGetUserProfileQuery();
  const {
    isLoading: isCompanyDetailLoading,
    data: CompanyDetail,
    isSuccess: isCompanyDetailSuccess,
    isError: isCompanyDetailError,
    error: companyDetailError,
  } = useGetCompanyDetailQuery(
    { id: user?.company, search: "" },
    {
      skip: !user?.company,
    }
  );

  const [updateCompanyDetail, { isLoading: isUpdateCompanyLoading }] =
    useUpdateCompanyDetailMutation();

  const companyLogoRef = useRef(null);
  const [companyLogoPreview, setCompanyLogoPreview] = useState(null);

  console.log("user==>", user);

  const userValidationSchema = yup.object({
    first_name: yup
      .string("Pleae enter first name")
      .min(3, "First name must contain 3 characters minimum")
      .required("First Name is required")
      .max(50, "First name can contain a maximum of 50 characters"),
    last_name: yup
      .string("Enter last name")
      .min(3, "Last name must contain 3 characters minimum")
      .required("Last Name is required")
      .max(50, "Last name can contain a maximum of 50 characters"),
  });

  const brokerValidationSchema = yup.object({
    title: yup
      .string("Please enter title")
      .min(3, "Title must contain 3 characters minimum")
      .required("Title is required")
      .max(50, "Title can contain a maximum of 50 characters"),
    description: yup
      .string("Please enter description")
      .min(3, "Description must contain 3 characters minimum")
      .required("Description is required")
      .max(250, "Description can contain a maximum of 50 characters"),
    weblink: yup
      .string("Please enter Web Link")
      .required("Web Link is required"),
  });

  const devValidationSchema = yup.object({
    title: yup
      .string("Please enter title")
      .min(3, "Title must contain 3 characters minimum")
      .required("Title is required")
      .max(50, "Title can contain a maximum of 50 characters"),
    description: yup
      .string("Please enter description")
      .min(3, "Description must contain 3 characters minimum")
      .required("Description is required")
      .max(250, "Description can contain a maximum of 50 characters"),
    weblink: yup
      .string("Please enter Web Link")
      .required("Web Link is required"),
  });

  // Update company validation schema
  const companyValidationSchema = yup.object({
    company_name: yup
      .string("Please enter company name")
      .min(3, "Company name must contain 3 characters minimum")
      .required("Company name is required")
      .max(50, "Company name can contain a maximum of 50 characters"),
    company_description: yup
      .string("Please enter company description")
      .required("Description is required")
      .max(250, "Description can contain a maximum of 250 characters"),
    company_website: yup
      .string("Please enter website")
      .required("Website is required"),
    company_email: yup
      .string("Please enter email")
      .email("Invalid email format")
      .required("Email is required"),
    company_phone: yup
      .string("Please enter phone number")
      .required("Phone number is required"),
    company_address: yup
      .string("Please enter address")
      .required("Address is required"),
    company_city: yup.string("Please enter city").required("City is required"),
    company_state: yup
      .string("Please enter state")
      .required("State is required"),
    company_country: yup
      .string("Please enter country")
      .required("Country is required"),
  });

  // Update company formik
  const companyFormik = useFormik({
    initialValues: {
      company_name: "",
      company_description: "",
      company_website: "",
      company_email: "",
      company_phone: "",
      company_address: "",
      company_city: "",
      company_state: "",
      company_country: "",
      company_logo: null,
    },
    validationSchema: companyValidationSchema,
    enableReinitialize: false,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          if (values[key] !== null) {
            formData.append(key, values[key]);
          }
        });

        const response = await updateCompanyDetail({
          id: user?.company,
          formData,
        }).unwrap();
        if (response) {
          toast.success("Company details updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        showError(error?.data);
      }
    },
  });

  // Modify the data setting useEffect to handle the array directly
  useEffect(() => {
    if (
      CompanyDetail &&
      Array.isArray(CompanyDetail) &&
      CompanyDetail.length > 0
    ) {
      console.log("Setting company data...");
      try {
        const companyData = CompanyDetail[0];
        companyFormik.setValues({
          company_name: companyData.company_name || "",
          company_description: companyData.company_description || "",
          company_website: companyData.company_website || "",
          company_email: companyData.company_email || "",
          company_phone: companyData.company_phone || "",
          company_address: companyData.company_address || "",
          company_city: companyData.company_city || "",
          company_state: companyData.company_state || "",
          company_country: companyData.company_country || "",
          company_logo: null,
        });
        setCompanyLogoPreview(companyData.company_logo || null);
        console.log("Company data set successfully");
      } catch (error) {
        console.error("Error setting company data:", error);
      }
    } else {
      console.log("Waiting for CompanyDetail data...");
    }
  }, [CompanyDetail]);

  // Update the debug useEffect
  useEffect(() => {
    console.log("CompanyDetail:", CompanyDetail);
    console.log("Is Array:", Array.isArray(CompanyDetail));
    console.log("Length:", CompanyDetail?.length);
    console.log("Form values:", companyFormik.values);
  }, [CompanyDetail]);

  // Add this useEffect to log query status
  useEffect(() => {
    console.log("Query Status:", {
      isLoading: isCompanyDetailLoading,
      isSuccess: isCompanyDetailSuccess,
      isError: isCompanyDetailError,
      error: companyDetailError,
    });
  }, [
    isCompanyDetailLoading,
    isCompanyDetailSuccess,
    isCompanyDetailError,
    companyDetailError,
  ]);

  useEffect(() => {
    if (
      companyMembersListApiData?.data &&
      Array.isArray(companyMembersListApiData?.data) &&
      companyMembersListApiData?.data.length > 0
    ) {
      setAvatarPreview(companyMembersListApiData?.data[0]?.profile_picture);
    }
  }, [companyMembersListApiData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.readyState === 2) {
          setAvatarPreview(fileReader.result);
          userFormik.setFieldValue("profile_picture", file);
        }
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleCompanyLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.readyState === 2) {
          setCompanyLogoPreview(fileReader.result);
          companyFormik.setFieldValue("company_logo", file);
        }
      };
      fileReader.readAsDataURL(file);
    }
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  //user setting formik variables
  const userFormik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      profile_picture: null,
    },
    validationSchema: userValidationSchema,
    onSubmit: async (values) => {
      delete values.email;
      console.log("values===>>>>", values);

      // Create FormData and append all values
      const formData = new FormData();
      formData.append("user_id", user?.id);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      if (values.profile_picture) {
        formData.append("profile_picture", values.profile_picture);
      }

      try {
        const response = await updateUserProfile(formData).unwrap();
        if (response) {
          try {
            const response = await getUserProfile().unwrap();
            console.log(response, "response");
            if (response) {
              dispatch(setUser(response?.data));
            }
          } catch (error) {
            toast.error(error?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          toast.success("User profile updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        showError(error?.data);
      }
    },
  });

  //broker setting formik variables
  const brokerFormik = useFormik({
    initialValues: {
      title: user?.broker_profile?.title || "",
      description: user?.broker_profile?.description || "",
      weblink: user?.broker_profile?.weblink || "",
    },
    validationSchema: brokerValidationSchema,
    onSubmit: async (values) => {
      const data = { ...values, user_id: user?.id };
      try {
        const response = await updateBrokerProfile(data).unwrap();
        if (response) {
          toast.success("Broker profile updated succesfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        showError(error?.data);
      }
    },
  });

  //broker setting formik variables
  const devFormik = useFormik({
    initialValues: {
      title: user?.dev_profile?.title || "",
      description: user?.dev_profile?.description || "",
      weblink: user?.dev_profile?.weblink || "",
    },
    validationSchema: devValidationSchema,
    onSubmit: async (values) => {
      const data = { ...values, user_id: user?.id };
      try {
        const response = await updateDevProfile(data).unwrap();
        if (response) {
          toast.success("Developer profile updated succesfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        showError(error?.data);
      }
    },
  });

  useEffect(() => {
    if (user?.first_name) {
      userFormik.setFieldValue("first_name", user?.first_name);
      userFormik.setFieldValue("last_name", user?.last_name);
      userFormik.setFieldValue("email", user?.email);
    }
  }, [user]);

  return (
    <>
      <CustomPaperBox>
        <Box
          sx={{
            "& .MuiTypography-body1": {
              color: theme.palette.primary.main,
              fontSize: "22px",
              marginBottom: "10px",
            },
          }}
        >
          <Typography>User Settings</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Avatar
                sx={{
                  height: "80px",
                  width: "80px",
                  "& img": {
                    objectFit: "contain",
                  },
                  border: `2px solid ${theme.palette.primary.main}`,
                  cursor: "pointer",
                }}
                src={avatarPreview || null}
                onClick={() => {
                  inputRef.current.click();
                }}
              />

              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    inputRef.current.click();
                  }}
                  startIcon={<CloudUploadIcon />}
                  sx={{
                    textTransform: "none",
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    "&:hover": {
                      borderColor: theme.palette.primary.dark,
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  {avatarPreview ? "Change Photo" : "Upload Photo"}
                </Button>
              </Box>

              <Typography variant="caption" color="textSecondary">
                Recommended: 80x80px (Max 2MB)
              </Typography>

              <input
                name="dp"
                ref={inputRef}
                style={{ display: "none" }}
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
              />
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomInput
              id="firstName"
              name="first_name"
              label="First Name"
              value={userFormik.values.first_name}
              onChange={userFormik.handleChange}
              error={
                userFormik.touched.first_name &&
                Boolean(userFormik.errors.first_name)
              }
              helperText={
                userFormik.touched.first_name && userFormik.errors.first_name
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomInput
              id="last_name"
              name="last_name"
              label="Last Name"
              value={userFormik.values.last_name}
              onChange={userFormik.handleChange}
              error={
                userFormik.touched.last_name &&
                Boolean(userFormik.errors.last_name)
              }
              helperText={
                userFormik.touched.last_name && userFormik.errors.last_name
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <CustomInput
              id="email"
              name="email"
              value={userFormik.values.email}
              onChange={userFormik.handleChange}
              disabled={true}
              helperText={userFormik.touched.email && userFormik.errors.email}
              label="Email"
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                textAlign: "end",
                marginTop: "15px",
              }}
            >
              <CustomButton
                color="primary"
                type="submit"
                isLoading={isUpdateUserProfileLoading}
                onClick={userFormik.handleSubmit}
                disabled={isUpdateUserProfileLoading ? true : false}
                buttonName={
                  isUpdateUserProfileLoading ? "Loading..." : "Update"
                }
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPaperBox>

      {user?.company && user?.roles?.some((role) => role.name === "admin") && (
        <CustomPaperBox>
          <Box
            sx={{
              "& .MuiTypography-body1": {
                color: theme.palette.primary.main,
                fontSize: "22px",
                marginBottom: "20px",
              },
            }}
          >
            <Typography>Company Settings</Typography>
          </Box>
          {isCompanyDetailLoading ? (
            <Box sx={{ textAlign: "center", py: 2 }}>Loading...</Box>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Avatar
                    sx={{
                      height: "80px",
                      width: "80px",
                      "& img": {
                        objectFit: "contain",
                      },
                      border: `2px solid ${theme.palette.primary.main}`,
                      cursor: "pointer",
                    }}
                    src={companyLogoPreview || null}
                    onClick={() => {
                      companyLogoRef.current.click();
                    }}
                  />

                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        companyLogoRef.current.click();
                      }}
                      startIcon={<CloudUploadIcon />}
                      sx={{
                        textTransform: "none",
                        borderColor: theme.palette.primary.main,
                        color: theme.palette.primary.main,
                        "&:hover": {
                          borderColor: theme.palette.primary.dark,
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      {companyLogoPreview ? "Change Logo" : "Upload Logo"}
                    </Button>
                  </Box>

                  <Typography variant="caption" color="textSecondary">
                    Recommended: 80x80px (Max 2MB)
                  </Typography>

                  <input
                    name="company_logo"
                    ref={companyLogoRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    type="file"
                    onChange={handleCompanyLogoChange}
                  />
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <CustomInput
                  id="company_name"
                  name="company_name"
                  label="Company Name"
                  value={companyFormik.values.company_name}
                  onChange={companyFormik.handleChange}
                  error={
                    companyFormik.touched.company_name &&
                    Boolean(companyFormik.errors.company_name)
                  }
                  helperText={
                    companyFormik.touched.company_name &&
                    companyFormik.errors.company_name
                  }
                />
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <CustomInput
                  id="company_website"
                  name="company_website"
                  label="Website"
                  value={companyFormik.values.company_website}
                  onChange={companyFormik.handleChange}
                  error={
                    companyFormik.touched.company_website &&
                    Boolean(companyFormik.errors.company_website)
                  }
                  helperText={
                    companyFormik.touched.company_website &&
                    companyFormik.errors.company_website
                  }
                />
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <CustomInput
                  id="company_email"
                  name="company_email"
                  label="Email"
                  value={companyFormik.values.company_email}
                  onChange={companyFormik.handleChange}
                  error={
                    companyFormik.touched.company_email &&
                    Boolean(companyFormik.errors.company_email)
                  }
                  helperText={
                    companyFormik.touched.company_email &&
                    companyFormik.errors.company_email
                  }
                />
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <CustomInput
                  id="company_phone"
                  name="company_phone"
                  label="Phone Number"
                  value={companyFormik.values.company_phone}
                  onChange={companyFormik.handleChange}
                  error={
                    companyFormik.touched.company_phone &&
                    Boolean(companyFormik.errors.company_phone)
                  }
                  helperText={
                    companyFormik.touched.company_phone &&
                    companyFormik.errors.company_phone
                  }
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  id="company_description"
                  name="company_description"
                  label="Description"
                  multiline={true}
                  minRows={3}
                  value={companyFormik.values.company_description}
                  onChange={companyFormik.handleChange}
                  error={
                    companyFormik.touched.company_description &&
                    Boolean(companyFormik.errors.company_description)
                  }
                  helperText={
                    companyFormik.touched.company_description &&
                    companyFormik.errors.company_description
                  }
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <CustomInput
                  id="company_address"
                  name="company_address"
                  label="Address"
                  multiline={true}
                  minRows={2}
                  value={companyFormik.values.company_address}
                  onChange={companyFormik.handleChange}
                  error={
                    companyFormik.touched.company_address &&
                    Boolean(companyFormik.errors.company_address)
                  }
                  helperText={
                    companyFormik.touched.company_address &&
                    companyFormik.errors.company_address
                  }
                />
              </Grid>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <CustomInput
                  id="company_city"
                  name="company_city"
                  label="City"
                  value={companyFormik.values.company_city}
                  onChange={companyFormik.handleChange}
                  error={
                    companyFormik.touched.company_city &&
                    Boolean(companyFormik.errors.company_city)
                  }
                  helperText={
                    companyFormik.touched.company_city &&
                    companyFormik.errors.company_city
                  }
                />
              </Grid>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <CustomInput
                  id="company_state"
                  name="company_state"
                  label="State"
                  value={companyFormik.values.company_state}
                  onChange={companyFormik.handleChange}
                  error={
                    companyFormik.touched.company_state &&
                    Boolean(companyFormik.errors.company_state)
                  }
                  helperText={
                    companyFormik.touched.company_state &&
                    companyFormik.errors.company_state
                  }
                />
              </Grid>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <CustomInput
                  id="company_country"
                  name="company_country"
                  label="Country"
                  value={companyFormik.values.company_country}
                  onChange={companyFormik.handleChange}
                  error={
                    companyFormik.touched.company_country &&
                    Boolean(companyFormik.errors.company_country)
                  }
                  helperText={
                    companyFormik.touched.company_country &&
                    companyFormik.errors.company_country
                  }
                />
              </Grid>
            </Grid>
          )}
          <Box
            sx={{
              textAlign: "end",
              marginTop: "15px",
            }}
          >
            <CustomButton
              color="primary"
              type="submit"
              onClick={companyFormik.handleSubmit}
              isLoading={isUpdateCompanyLoading}
              disabled={isUpdateCompanyLoading}
              buttonName={isUpdateCompanyLoading ? "Loading..." : "Update"}
            />
          </Box>
        </CustomPaperBox>
      )}

      {/* {user?.roles?.includes("REALESTATE_BROKER") && (
        <CustomPaperBox>
          <Box
            sx={{
              "& .MuiTypography-body1": {
                color: theme.palette.primary.main,
                fontSize: "22px",
                marginBottom: "20px",
              },
            }}
          >
            <Typography>Broker Settings</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <CustomInput
                id="title"
                name="title"
                value={brokerFormik.values.title}
                onChange={brokerFormik.handleChange}
                error={
                  brokerFormik.touched.title &&
                  Boolean(brokerFormik.errors.title)
                }
                helperText={
                  brokerFormik.touched.title && brokerFormik.errors.title
                }
                label="Title"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <CustomInput
                id="weblink"
                name="weblink"
                value={brokerFormik.values.weblink}
                onChange={brokerFormik.handleChange}
                error={
                  brokerFormik.touched.weblink &&
                  Boolean(brokerFormik.errors.weblink)
                }
                helperText={
                  brokerFormik.touched.weblink && brokerFormik.errors.weblink
                }
                label="Web Link"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <CustomInput
                id="description"
                name="description"
                multiline={true}
                minRows={3}
                value={brokerFormik.values.description}
                onChange={brokerFormik.handleChange}
                error={
                  brokerFormik.touched.description &&
                  Boolean(brokerFormik.errors.description)
                }
                helperText={
                  brokerFormik.touched.description &&
                  brokerFormik.errors.description
                }
                label="Description"
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              textAlign: "end",
              marginTop: "15px",
            }}
          >
            <CustomButton
              // isLoading={isPostUserLoading || isPutUserLoading}
              // disabled={isPostUserLoading || isPutUserLoading}
              color="primary"
              type="submit"
              onClick={brokerFormik.handleSubmit}
              isLoading={isUpdateBrokerProfileLoading}
              disabled={isUpdateBrokerProfileLoading ? true : false}
              buttonName={
                isUpdateBrokerProfileLoading ? "Loading..." : "Update"
              }
            />
          </Box>
        </CustomPaperBox>
      )}

      {user?.roles?.includes("REALESTATE_DEV") && (
        <CustomPaperBox>
          <Box
            sx={{
              "& .MuiTypography-body1": {
                color: theme.palette.primary.main,
                fontSize: "22px",
                marginBottom: "20px",
              },
            }}
          >
            <Typography>Developer Settings</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <CustomInput
                id="title"
                name="title"
                value={devFormik.values.title}
                onChange={devFormik.handleChange}
                error={
                  devFormik.touched.title && Boolean(devFormik.errors.title)
                }
                helperText={devFormik.touched.title && devFormik.errors.title}
                label="Title"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <CustomInput
                id="Web Link"
                name="weblink"
                value={devFormik.values.weblink}
                onChange={devFormik.handleChange}
                error={
                  devFormik.touched.weblink && Boolean(devFormik.errors.weblink)
                }
                helperText={
                  devFormik.touched.weblink && devFormik.errors.weblink
                }
                label="Web Link"
              />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <CustomInput
                id="description"
                name="description"
                value={devFormik.values.description}
                onChange={devFormik.handleChange}
                multiline={true}
                minRows={3}
                error={
                  devFormik.touched.description &&
                  Boolean(devFormik.errors.description)
                }
                helperText={
                  devFormik.touched.description && devFormik.errors.description
                }
                label="Description"
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              textAlign: "end",
              marginTop: "15px",
            }}
          >
            <CustomButton
              color="primary"
              type="submit"
              isLoading={isUpdateDevLoading}
              onClick={devFormik.handleSubmit}
              disabled={isUpdateDevLoading ? true : false}
              buttonName={isUpdateDevLoading ? "Loading..." : "Update"}
            />
          </Box>
        </CustomPaperBox>
      )} */}
    </>
  );
};

export default Settings;
