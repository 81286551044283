import React, { useRef } from "react";
import CreateProperty from "./CreateProperty";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import CustomInput from "../../theme/style-components/CustomInput";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CustomSelect from "../../theme/style-components/CustomSelect";
// import { DropzoneArea } from 'react-mui-dropzone'
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLazyGetMyPropertyListingQuery } from "../../store/property/propertyApiSlice";
import { useEffect } from "react";
import PropertyFeatures from "./PropertyFeatures";
import PropertyImages from "./PropertyImages";
import PropertyLocation from "./PropertyLocation";
import PropertyFinancials from "./PropertyFinancials";
import PropertyDocs from "./PropertyDocs";
import ComponentTitle from "../shared/ComponentTitle";
import CustomButton from "../../theme/style-components/CustomButton";

const EditProperty = () => {
  const theme = useTheme();

  const { id } = useParams();

  const navigate = useNavigate();

  const [
    getMyPropertyListing,
    {
      isLoading: isGetPropertyLoading,
      data: property,
      isSuccess: isGetPropertySuccess,
      isError: isGetPropertyError,
      error: getPropertyError,
    },
  ] = useLazyGetMyPropertyListingQuery();

  const financialsSubmitRef = useRef();
  const locationSubmitRef = useRef();
  const featuresSubmitRef = useRef();
  const createPropertySubmitRef = useRef();

  useEffect(() => {
    if (id) {
      getMyPropertyListing({ id: id, page: 1, query: "" });
    }
  }, [id]);

  const breadcrumbs = [
    <Link key="1" to="/my-listings">
      Properties
    </Link>,
    <Typography key="2" variant="body1">
      Edit Property
    </Typography>,
  ];

  const handleSubmit = () => {
    if (createPropertySubmitRef.current) {
      createPropertySubmitRef.current();
    }
    if (featuresSubmitRef.current) {
      featuresSubmitRef.current();
    }
    if (locationSubmitRef.current) {
      locationSubmitRef.current();
    }
    if (financialsSubmitRef.current) {
      financialsSubmitRef.current();
    }
  };

  return (
    <>
      {/* <ComponentTitle breadcrumbs={breadcrumbs} /> */}
      <Box sx={{ background: "#FFF", borderRadius: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "15px",
            alignItems: "center",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <Typography variant="h5" sx={{ fontSize: "1.25rem", m: "0" }}>
            Edit Properties
          </Typography>
          <CustomButton
            buttonName="preview"
            onClick={() =>
              navigate(`/my-listings/view/${id}`, {
                state: {
                  disabled: true,
                },
              })
            }
            sx={{
              "& .MuiButtonBase-root": {
                background: "#fff",
                boxShadow: "none",
                color: theme.palette.primary.main,
                border: "1px solid #00c6b6",
                "&:hover": {
                  background: theme.palette.primary.main,
                  color: "#fff",
                },
              },
            }}
          />
        </Box>
        <Box sx={{ p: "20px", display: "grid", gap: "20px" }}>
          {property && (
            <CreateProperty
              property={property?.data[0]}
              id={id}
              submitRef={createPropertySubmitRef}
            />
          )}
          <PropertyFeatures
            features={property?.data[0]?.features}
            id={id}
            submitRef={featuresSubmitRef}
          />
          <PropertyLocation
            id={id}
            location={property?.data[0]?.location}
            submitRef={locationSubmitRef}
          />
          <PropertyFinancials
            id={id}
            financials={property?.data[0]?.financials}
            submitRef={financialsSubmitRef}
          />
          <PropertyImages
            id={id}
            images={property?.data[0]?.images}
            videos={property?.data[0]?.videos}
          />
          <PropertyDocs id={id} documents={property?.data[0]?.documents} />
          <Box>
            <Button
              onClick={handleSubmit}
              sx={{
                display: "flex",
                p: "8px 50px",
                textAlign: "center",
                border: "1px solid #2ec4b6",
                maxWidth: "200px",
                margin: "0 auto",
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EditProperty;
