import React, { useState } from "react";
import ChatbotModal from "./ChatbotModal";
import { Box, Fab, styled, Typography } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import chatGPT from "../../asset/chatGPT.svg";

const StyledFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: 20,
  right: 20,
  zIndex: 999,
  background: "#2EC4B6",
  padding: "5px",
  width: "fit-content",
  justifyContent: "start",
  height: "48px",
  borderRadius: "100px",
  display: "flex",
  gap: "10px",
  fontWeight: "700",
  fontSize: "18px",
  boxShadow: "0 4px 12px rgba(33, 150, 243, 0.4)",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 16px rgba(33, 150, 243, 0.5)",
    background: "#1aad9f",
  },
  transition: "all 0.3s",
}));

const ChatbotButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <StyledFab
        color="primary"
        onClick={() => setIsModalOpen(true)}
        aria-label="chat"
      >
        {/* <ChatIcon /> */}
        <Box
          display="flex"
          gap="10px"
          backgroundColor="#c5f286"
          sx={{
            width: "38px",
            height: "38px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.21737 1.13294C5.28177 -0.190327 3.40963 -0.413584 2.2778 0.778016L0.828986 2.30335C0.334361 2.82409 -0.0415694 3.54748 0.00369696 4.39084C0.0953096 6.09776 0.820069 9.59138 4.63519 13.608C8.72373 17.9125 12.6237 18.1289 14.3073 17.9627C14.9968 17.8947 15.5548 17.5269 15.9641 17.096L17.2753 15.7155C18.4799 14.4474 18.129 12.3251 16.6218 11.4575L14.8583 10.4425C13.8443 9.85888 12.5869 10.0261 11.7803 10.8753L11.4032 11.2724C11.3764 11.2819 11.2926 11.3045 11.1322 11.2784C10.7607 11.2181 9.95886 10.89 8.63696 9.49834C7.31913 8.1109 6.99454 7.25643 6.93292 6.83598C6.9032 6.63313 6.9334 6.52626 6.94535 6.4927L6.94732 6.48734L7.17338 6.24939C8.06643 5.30911 8.12966 3.83761 7.38134 2.77918L6.21737 1.13294ZM3.28169 1.73155C3.7643 1.22347 4.6139 1.26338 5.08683 1.93226L6.25078 3.57851C6.64044 4.12963 6.58069 4.86289 6.16943 5.29585L5.90469 5.57458L6.39544 6.04072C5.90468 5.57459 5.90341 5.57593 5.90341 5.57593L5.90211 5.57732L5.89943 5.58019L5.89379 5.58631L5.8815 5.60014C5.87286 5.61007 5.86338 5.62143 5.85323 5.63425C5.83294 5.65988 5.80999 5.6914 5.78591 5.72903C5.73761 5.8045 5.68525 5.90387 5.64089 6.02851C5.55071 6.28195 5.50157 6.61757 5.56298 7.03669C5.68363 7.8602 6.22321 8.96758 7.63306 10.4518C9.03867 11.9318 10.1004 12.5136 10.9103 12.6451C11.3259 12.7126 11.6623 12.6587 11.9171 12.5582C12.0416 12.509 12.1402 12.4514 12.2141 12.3989C12.251 12.3728 12.2817 12.348 12.3065 12.3262C12.3189 12.3153 12.3299 12.3052 12.3394 12.296L12.3526 12.283L12.3585 12.277L12.3612 12.2741L12.3626 12.2728C12.3626 12.2728 12.3638 12.2715 11.8772 11.8093L12.3639 12.2715L12.7842 11.8289C13.14 11.4543 13.6943 11.3701 14.1676 11.6425L15.9311 12.6576C16.6888 13.0936 16.8371 14.1665 16.2714 14.762L14.9602 16.1425C14.7026 16.4136 14.4332 16.559 14.1712 16.5849C12.8466 16.7156 9.39054 16.6041 5.63908 12.6544C2.06123 8.88765 1.46108 5.71026 1.38628 4.31664C1.3665 3.94805 1.52699 3.57893 1.83288 3.25689L3.28169 1.73155Z"
              fill="#7D8383"
            />
          </svg>
        </Box>
      </StyledFab>

      <ChatbotModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default ChatbotButton;
