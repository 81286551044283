import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  Button,
  Select,
  MenuItem,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Container,
  Menu,
  Skeleton,
  Pagination,
} from "@mui/material";
import searchLogo from "../../asset/search.svg";
import listingIMG from "../../asset/img.jpg";
import locationLogo from "../../asset/location.svg";
import visited from "../../asset/visited.svg";
import brokersLogo from "../../asset/brokers-logo.jpg";
import heart from "../../asset/heart.svg";
import bath from "../../asset/bath.svg";
import bed from "../../asset/bed.svg";
import bell from "../../asset/bell.svg";
import list from "../../asset/list.svg";
import check from "../../asset/check.svg";
import brokerImg from "../../asset/broker-img.jpg";
import dummyProperty from "../../asset/Homepage-image-new.png";
import filterIcon from "../../asset/filter.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetPropertyPublicListQuery } from "../../store/public-listings/publicListingsApiSlice";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrokerEmailModal } from "../brokerDetailModals/BrokerEmailModal";
import { BrokerPhoneModal } from "../brokerDetailModals/BrokerPhoneModal";
import { debounce } from "lodash";

const ListingsContent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isInitialRender = useRef(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [brokerEmailModal, setBrokerEmailModal] = useState(false);
  const [brokerPhoneModal, setBrokerPhoneModal] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [filterValues, setFilterValues] = useState({
    page: 1,
    listing_status: "",
    listing_type: "RENT",
    property_type: "",
    search: "",
    bedrooms: "",
    bathrooms: "",
    min_price: "",
    max_price: "",
    area_sqft: "",
  });

  console.log("filterValues", filterValues);

  // Add temporary filter values state
  const [tempFilterValues, setTempFilterValues] = useState({ ...filterValues });

  // Add pagination state
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  //base query hooks
  const { isLoading, data: publicListingsData } = useGetPropertyPublicListQuery(
    {
      ...filterValues,
    }
  );

  useEffect(() => {
    if (!isLoading) {
      setData(publicListingsData?.data);
      // Calculate total pages based on count and page_size
      const totalPages =
        Math.ceil(
          publicListingsData?.page_details?.count /
            publicListingsData?.page_details?.page_size
        ) || 1;
      setTotalPages(totalPages);
    }
  }, [publicListingsData]);

  // Function to update URL with current filters
  const updateQueryParams = (filters) => {
    const queryParams = new URLSearchParams();

    // Only add params that have values
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        queryParams.set(key, value);
      }
    });

    // Add search location if exists
    if (search) {
      queryParams.set("searchLocation", search);
    }

    // Get current URL params
    const currentParams = new URLSearchParams(location.search).toString();
    const newParams = queryParams.toString();

    // Only update if params have changed
    if (currentParams !== newParams) {
      navigate(
        {
          pathname: location.pathname,
          search: newParams,
        },
        { replace: true }
      );
    }
  };

  // Update URL whenever filters change, but skip initial render
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    updateQueryParams(filterValues);
  }, [filterValues, search, location.pathname, navigate]);

  // Handle initial URL params and browser navigation
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const queryFilters = {
      page: queryParams.get("page") || 1,
      listing_status: queryParams.get("listing_status") || "",
      listing_type: queryParams.get("listing_type") || "RENT",
      property_type: queryParams.get("property_type") || "",
      search: queryParams.get("search") || "",
      bedrooms: queryParams.get("bedrooms") || "",
      bathrooms: queryParams.get("bathrooms") || "",
      min_price: queryParams.get("min_price") || "",
      max_price: queryParams.get("max_price") || "",
      area_sqft: queryParams.get("area_sqft") || "",
    };

    // Update search field if exists in URL
    const searchLocation = queryParams.get("searchLocation");
    if (searchLocation) {
      setSearch(searchLocation);
    }

    // Only update if filters are different
    if (JSON.stringify(queryFilters) !== JSON.stringify(filterValues)) {
      setFilterValues(queryFilters);
      setTempFilterValues(queryFilters);
      setPage(Number(queryFilters.page)); // Update page state
    }
  }, [location.search]); // Only run when URL changes

  const viewDetail = (id) => {
    navigate(`/listings/property?id=${id}`);
  };

  const handleMoreFiltersClick = (event) => {
    setTempFilterValues({ ...filterValues }); // Save current values
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreFiltersMenu = () => {
    setTempFilterValues({ ...filterValues }); // Reset temp values
    setAnchorEl(null);
  };

  // Add filter change handler
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setTempFilterValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Add apply filters handler
  const handleApplyFilters = () => {
    setFilterValues({ ...tempFilterValues });
    setAnchorEl(null);
  };

  // Add reset handler
  const handleResetFilters = () => {
    const resetValues = {
      page: 1,
      listing_status: "",
      listing_type: "RENT",
      property_type: "",
      search: "",
      bedrooms: "",
      bathrooms: "",
      min_price: "",
      max_price: "",
      area_sqft: "",
    };
    setTempFilterValues(resetValues);
    setSearch(""); // Reset search field
    setFilterValues(resetValues); // Update main filters
    setPage(1); // Reset page state
  };

  //   const handleSearch = () => {
  //     setFilterValues((prev) => ({
  //       ...prev,
  //       search,
  //       page: 1, // Reset page when searching
  //     }));
  //   };
  // Add debounced search handler
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      setFilterValues((prev) => ({
        ...prev,
        search: searchValue,
        page: 1, // Reset page when searching
      }));
    }, 500), // 500ms delay
    [] // Empty dependency array since we don't need to recreate this function
  );

  // Update the search handler to use debouncing
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue); // Update local state immediately for UI
    debouncedSearch(searchValue); // Debounce the actual search
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // Custom arrow components
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <Button
        onClick={onClick}
        sx={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          minWidth: "auto",
          width: "32px",
          height: "32px",
          background: "rgba(255,255,255,0.8)",
          borderRadius: "50%",
          zIndex: 1,
          "&:hover": {
            background: "rgba(255,255,255,1)",
          },
          "&::before": {
            content: '"›"',
            fontSize: "24px",
            color: "#495251",
            lineHeight: "10px",
            margin: "-6px 0 0",
          },
        }}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <Button
        onClick={onClick}
        sx={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          minWidth: "auto",
          width: "32px",
          height: "32px",
          background: "rgba(255,255,255,0.8)",
          borderRadius: "50%",
          zIndex: 1,
          "&:hover": {
            background: "rgba(255,255,255,1)",
          },
          "&::before": {
            content: '"‹"',
            fontSize: "24px",
            color: "#495251",
            lineHeight: "10px",
            margin: "-6px 0 0",
          },
        }}
      />
    );
  }

  // Skeleton for a single property card
  const PropertyCardSkeleton = () => (
    <Box
      sx={{
        border: "1px solid #E4E5E5",
        borderRadius: "6px",
        p: "10px",
        display: { xs: "grid", sm: "flex" },
        gap: { xs: "20px", md: "30px" },
        mb: 2,
      }}
    >
      {/* Image slider skeleton */}
      <Box
        sx={{
          width: { xs: "304px", sm: "240px", md: "340px" },
          height: "220px",
          m: { xs: "0 auto", sm: "0" },
        }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{ borderRadius: "6px" }}
        />
      </Box>

      {/* Content skeleton */}
      <Box sx={{ flex: 1 }}>
        {/* Price and status */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Skeleton variant="text" width="120px" height={32} />
          <Skeleton variant="text" width="80px" height={32} />
        </Box>

        {/* Title */}
        <Skeleton variant="text" width="80%" height={24} sx={{ mb: 1 }} />

        {/* Features */}
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <Skeleton variant="text" width="60px" height={24} />
          <Skeleton variant="text" width="60px" height={24} />
          <Skeleton variant="text" width="60px" height={24} />
        </Box>

        {/* Location */}
        <Skeleton variant="text" width="60%" height={20} sx={{ mb: 1 }} />

        {/* Last visited */}
        <Skeleton variant="text" width="40%" height={20} />
      </Box>
    </Box>
  );

  // Skeleton for the right sidebar
  const SidebarSkeleton = () => (
    <Box
      sx={{ width: { xs: "100%", lg: "384px" }, m: { xs: "0 auto", lg: "0" } }}
    >
      <Skeleton variant="text" width="80%" height={24} sx={{ mb: 2 }} />
      <Skeleton
        variant="rectangular"
        height={50}
        sx={{ borderRadius: "6px", mb: 3 }}
      />
      <Box sx={{ mb: 3 }}>
        <Skeleton
          variant="rectangular"
          height={200}
          sx={{ borderRadius: "6px" }}
        />
      </Box>
      <Box>
        <Skeleton
          variant="rectangular"
          height={200}
          sx={{ borderRadius: "6px" }}
        />
      </Box>
    </Box>
  );

  // Add handle page change function
  const handlePageChange = (event, newPage) => {
    setFilterValues((prev) => ({
      ...prev,
      page: newPage,
    }));
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "184px",
          background: `url(${dummyProperty})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: { md: "left bottom -100px", xs: "inherit" },
          position: "relative",
          marginBottom: "40px",
          borderRadius: "0 0 20px 20px",
          zIndex: "1",
          "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            bottom: 0,
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "0 0 20px 20px",
            zIndex: "-1",
          },
        }}
      >
        {/* Search Box */}
        <Box
          sx={{
            display: { xs: "grid", md: "flex" },
            maxWidth: "845px",
            width: "calc(100% - 40px)",
            p: "110px 0 15px",
            gap: { xs: "15px", md: "30px" },
            m: "0 auto",
          }}
        >
          <Box
            sx={{
              display: { xs: "grid", sm: "flex" },
              background: "#fff",
              borderRadius: "10px",
              maxWidth: "600px",
              width: "100%",
              m: { xs: "auto", md: "0" },
              height: { xs: "auto", sm: "50px" },
            }}
          >
            <Box className="cstm-select">
              <Select
                value={filterValues.listing_type}
                name="listing_type"
                onChange={(e) =>
                  setFilterValues({
                    ...filterValues,
                    listing_type: e.target.value,
                  })
                }
                sx={{
                  "& .MuiSelect-select": {
                    fontFamily: "Quicksand",
                    textTransform: "uppercase",
                    padding: "15px",
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#7D8383",
                    width: { xs: "100%", sm: "130px" },
                    border: "0",
                    borderRight: { xs: "0", sm: "1px solid #E4E5E5" },
                    borderBottom: { xs: "1px solid #E4E5E5", sm: "0" },
                    borderRadius: 0,
                  },
                }}
              >
                <MenuItem value="RENT">RENT</MenuItem>
                <MenuItem value="SALE">SALE</MenuItem>
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                padding: "0 20px 0 46px",
                // borderBottom: '1px solid #E4E5E5',
                position: "relative",
                width: { xs: "100%", md: "100%" },
              }}
            >
              <Box sx={{ position: "absolute", top: "15px", left: "15px" }}>
                <img src={searchLogo} alt="" height={22} width={22} />
              </Box>
              <TextField
                fullWidth
                placeholder="Search Location"
                variant="outlined"
                className="srhBox"
                name="search"
                onChange={handleSearchChange}
                value={search}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                    "& fieldset": { border: "none" },
                  },
                  "& .MuiInputBase-input": {
                    padding: "12px 0",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#7D8383",
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleMoreFiltersClick}
              sx={{
                background: "#fff",
                padding: "14px 15px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                color: "#495251",
                textTransform: "capitalize",
                fontSize: "16px",
                fontWeight: "600",
                textTransform: "uppercase",
                lineHeight: "20px",
                borderRadius: "6px",
                width: "185px",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#2EC4B6",
                },
              }}
            >
              <img src={filterIcon} alt="" style={{ verticalAlign: "top" }} />
              More Filters
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={isMenuOpen}
              onClose={handleCloseMoreFiltersMenu}
              sx={{
                "& .MuiMenu-paper": {
                  minWidth: "300px",
                  maxWidth: "400px",
                  padding: "16px",
                  backgroundColor: "white",
                  color: "black",
                },
              }}
              MenuListProps={{
                "aria-labelledby": "more-filters-button",
                role: "menu",
                sx: { padding: 0 },
              }}
            >
              <Box sx={{ p: "4px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: "10px",
                    borderRadius: "6px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontFamily: "Quicksand", fontWeight: "700" }}
                  >
                    Filters
                  </Typography>
                  <Button
                    onClick={handleResetFilters}
                    sx={{
                      color: "#2EC4B6",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "rgba(46, 196, 182, 0.04)",
                      },
                    }}
                  >
                    Reset
                  </Button>
                </Box>

                {/* Price Range */}
                <Box sx={{ mb: 2 }}>
                  <Typography
                    sx={{ mb: 1, fontFamily: "Quicksand", fontWeight: "600" }}
                  >
                    Price Range (AED)
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1, borderRadius: "6px" }}>
                    <TextField
                      name="min_price"
                      type="number"
                      placeholder="Min"
                      size="small"
                      value={tempFilterValues.min_price}
                      onChange={handleFilterChange}
                      sx={{
                        width: "50%",
                      }}
                    />
                    <TextField
                      name="max_price"
                      type="number"
                      placeholder="Max"
                      size="small"
                      value={tempFilterValues.max_price}
                      onChange={handleFilterChange}
                      sx={{
                        width: "50%",
                      }}
                    />
                  </Box>
                </Box>

                <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
                  {/* Status */}
                  <Box sx={{ width: "50%", borderRadius: "6px" }}>
                    <Typography
                      sx={{ mb: 1, fontFamily: "Quicksand", fontWeight: "600" }}
                    >
                      Status
                    </Typography>
                    <Select
                      name="listing_status"
                      value={tempFilterValues.listing_status}
                      onChange={handleFilterChange}
                      fullWidth
                      size="small"
                      displayEmpty
                    >
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value="AVAILABLE">AVAILABLE</MenuItem>
                      <MenuItem value="DISABLED">DISABLED</MenuItem>
                      <MenuItem value="SOLD">SOLD</MenuItem>
                      <MenuItem value="RENTED">RENTED</MenuItem>
                    </Select>
                  </Box>

                  {/* Property Type */}
                  <Box sx={{ width: "50%", borderRadius: "6px" }}>
                    <Typography
                      sx={{ mb: 1, fontFamily: "Quicksand", fontWeight: "600" }}
                    >
                      Type
                    </Typography>
                    <Select
                      name="property_type"
                      value={tempFilterValues.property_type}
                      onChange={handleFilterChange}
                      fullWidth
                      size="small"
                      displayEmpty
                    >
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value="RESEDENTIAL">RESEDENTIAL</MenuItem>
                      <MenuItem value="COMMERCIAL">COMMERCIAL</MenuItem>
                    </Select>
                  </Box>
                </Box>

                {/* Bedrooms & Bathrooms */}
                <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
                  <Box sx={{ width: "50%", borderRadius: "6px" }}>
                    <Typography
                      sx={{ mb: 1, fontFamily: "Quicksand", fontWeight: "600" }}
                    >
                      Bedrooms
                    </Typography>
                    <TextField
                      name="bedrooms"
                      type="number"
                      size="small"
                      value={tempFilterValues.bedrooms}
                      onChange={handleFilterChange}
                      fullWidth
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Box>
                  <Box sx={{ width: "50%", borderRadius: "6px" }}>
                    <Typography
                      sx={{ mb: 1, fontFamily: "Quicksand", fontWeight: "600" }}
                    >
                      Bathrooms
                    </Typography>
                    <TextField
                      name="bathrooms"
                      type="number"
                      size="small"
                      value={tempFilterValues.bathrooms}
                      onChange={handleFilterChange}
                      fullWidth
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Box>
                </Box>

                {/* Area */}
                <Box sx={{ mb: 3, borderRadius: "6px" }}>
                  <Typography
                    sx={{ mb: 1, fontFamily: "Quicksand", fontWeight: "600" }}
                  >
                    Area (sq ft)
                  </Typography>
                  <TextField
                    name="area_sqft"
                    type="number"
                    placeholder="Enter area"
                    size="small"
                    value={tempFilterValues.area_sqft}
                    onChange={handleFilterChange}
                    fullWidth
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>

                {/* Action Buttons */}
                <Box
                  sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}
                >
                  <Button
                    onClick={handleCloseMoreFiltersMenu}
                    sx={{
                      color: "#495251",
                      "&:hover": { backgroundColor: "rgba(73, 82, 81, 0.04)" },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleApplyFilters}
                    variant="contained"
                    sx={{
                      backgroundColor: "#2EC4B6",
                      "&:hover": { backgroundColor: "#2AB1A4" },
                    }}
                  >
                    Done
                  </Button>
                </Box>
              </Box>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Container>
        <Box>
          <Typography
            sx={{
              fontFamily: "Quicksand",
              color: "#495251",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "20px",
              m: "0 0 15px",
              p: "0",
            }}
            variant="h2"
          >
            Properties for sale in{" "}
            <Typography
              sx={{ fontFamily: "Quicksand", color: "#2EC4B6" }}
              variant="span"
            >
              UAE
            </Typography>
          </Typography>
          <Box
            sx={{
              display: { xs: "grid", lg: "flex" },
              gap: "40px",
              m: "0 0 25px",
              alignItems: "baseline",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", lg: "100%" },
                gap: "20px",
                display: "grid",
              }}
            >
              {isLoading ? (
                // Show multiple property card skeletons while loading
                <>
                  {[1, 2, 3, 4].map((item) => (
                    <PropertyCardSkeleton key={item} />
                  ))}
                </>
              ) : data?.length > 0 ? (
                <>
                  {data.map((item) => {
                    return (
                      <Box
                        className="slickWrapper"
                        sx={{
                          border: "1px solid #E4E5E5",
                          borderRadius: "6px",
                          p: "10px",
                          display: { xs: "grid", sm: "flex" },
                          gap: { xs: "20px", md: "30px" },
                          mb: 2,
                        }}
                      >
                        {/* Image Section */}
                        <Box
                          sx={{
                            borderRadius: "6px",
                            overflow: "hidden",
                            position: "relative",
                            width: { xs: "100%", sm: "240px", md: "340px" },
                            // minHeight: "220px",
                            height: "auto",
                            display: "flex",
                            m: { xs: "0 auto", sm: "0" },
                          }}
                        >
                          <Slider
                            {...sliderSettings}
                            className="property-slider"
                            style={{ width: "100%", height: "100%" }}
                          >
                            {item?.images?.map((image, index) => (
                              <div
                                key={index}
                                onClick={() => viewDetail(item.id)}
                                style={{ height: "100%" }}
                              >
                                <img
                                  src={image?.image_url}
                                  alt={`Property ${index + 1}`}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    verticalAlign: "top",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            ))}
                          </Slider>

                          {/* Property Type Badge */}
                          <Box
                            sx={{
                              position: "absolute",
                              top: "20px",
                              right: "15px",
                            }}
                          >
                            <img src={heart} alt="" />
                          </Box>
                          <Box
                            sx={{
                              background: "#ffffff6b",
                              p: "5px 10px",
                              backdropFilter: "blur(6px)",
                              border: "1px solid #ffffff9e",
                              boxShadow: "0 0 25px rgb(0 0 0 / 0.3)",
                              borderRadius: "50px",
                              position: "absolute",
                              top: "15px",
                              left: "15px",
                              display: "flex",
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              {/* <img src={check} alt="" style={{ width: "20px" }} /> */}
                              <Typography
                                sx={{
                                  fontFamily: "Quicksand",
                                  fontWeight: "800",
                                  fontSize: "12px",
                                  lineHeight: "18px",
                                  color: "#000",
                                }}
                                variant="h6"
                              >
                                {item?.property_type}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              background: "#fff",
                              // p: "5px",
                              position: "absolute",
                              bottom: "15px",
                              right: "15px",
                              display: "flex",
                              width: "50px",
                              height: "50px",
                            }}
                          >
                            <img
                              src={item?.broker?.company?.logo}
                              alt=""
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain", //Change to cover after testing
                              }}
                            />
                          </Box>
                        </Box>

                        {/* Content Section */}
                        <Box sx={{ flex: 1, p: "10px 0" }}>
                          {/* Price and Status */}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mb: 2.5,
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: "Quicksand",
                                display: "flex",
                                alignItems: "baseline",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  color: "#2EC4B6",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                AED
                              </Typography>
                              <Typography
                                component="span"
                                sx={{
                                  color: "#495251",
                                  fontSize: "24px",
                                  fontWeight: "700",
                                }}
                              >
                                {item?.financials?.total_amount}
                              </Typography>
                            </Typography>

                            <Typography
                              sx={{
                                backgroundColor: "#bef1ed",
                                color: "#495251",
                                px: 2,
                                py: 0.5,
                                borderRadius: "6px",
                                fontSize: "14px",
                                fontWeight: "600",
                                textTransform: "uppercase",
                                textAlign: "center",
                                lineHeight: 2,
                                border: "1px solid #a3ddd8",
                              }}
                            >
                              {item?.listing_status}
                            </Typography>
                          </Box>

                          {/* Property Features Grid */}
                          <Grid container spacing={2} sx={{ mb: 3 }}>
                            <Grid item xs={4}>
                              <Box
                                sx={{
                                  p: 1.5,
                                  bgcolor: "#F8F8F8",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mb: 0.5,
                                  }}
                                >
                                  <img
                                    src={bed}
                                    alt="Bedrooms"
                                    style={{ marginRight: "8px" }}
                                  />
                                  <Typography
                                    sx={{ fontWeight: "700", color: "#495251" }}
                                  >
                                    {item?.features?.bedrooms}
                                  </Typography>
                                </Box>
                                <Typography
                                  sx={{ fontSize: "12px", color: "#7D8383" }}
                                >
                                  Bedrooms
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={4}>
                              <Box
                                sx={{
                                  p: 1.5,
                                  bgcolor: "#F8F8F8",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mb: 0.5,
                                  }}
                                >
                                  <img
                                    src={bath}
                                    alt="Bathrooms"
                                    style={{ marginRight: "8px" }}
                                  />
                                  <Typography
                                    sx={{ fontWeight: "700", color: "#495251" }}
                                  >
                                    {item?.features?.bathrooms}
                                  </Typography>
                                </Box>
                                <Typography
                                  sx={{ fontSize: "12px", color: "#7D8383" }}
                                >
                                  Bathrooms
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item xs={4}>
                              <Box
                                sx={{
                                  p: 1.5,
                                  bgcolor: "#F8F8F8",
                                  borderRadius: "6px",
                                  textAlign: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "700",
                                    color: "#495251",
                                    mb: 0.5,
                                  }}
                                >
                                  {item?.features?.area_sqft}
                                </Typography>
                                <Typography
                                  sx={{ fontSize: "12px", color: "#7D8383" }}
                                >
                                  {"Area (Sq.Ft)"}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>

                          {/* Location and Visit Info */}
                          <Box sx={{ mb: 3 }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 2,
                              }}
                            >
                              <img
                                src={locationLogo}
                                alt="Location"
                                style={{ marginRight: "10px" }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#495251",
                                  fontFamily: "Quicksand",
                                }}
                              >
                                {item?.location?.address}
                              </Typography>
                            </Box>

                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                src={visited}
                                alt="Last Visited"
                                style={{ marginRight: "10px" }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "#7D8383",
                                  fontFamily: "Quicksand",
                                }}
                              >
                                Agent last visited on February 5th
                              </Typography>
                            </Box>
                          </Box>

                          {/* Contact Buttons */}
                          <Box
                            sx={{
                              display: "flex",
                              gap: 2,
                              mt: "auto",
                              pt: 2,
                              borderTop: "1px solid #E4E5E5",
                            }}
                          >
                            <Button
                              onClick={() => {
                                setSelectedBroker(item?.broker);
                                setBrokerPhoneModal(true);
                              }}
                              startIcon={
                                <svg
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.21737 1.13294C5.28177 -0.190327 3.40963 -0.413584 2.2778 0.778016L0.828986 2.30335C0.334361 2.82409 -0.0415694 3.54748 0.00369696 4.39084C0.0953096 6.09776 0.820069 9.59138 4.63519 13.608C8.72373 17.9125 12.6237 18.1289 14.3073 17.9627C14.9968 17.8947 15.5548 17.5269 15.9641 17.096L17.2753 15.7155C18.4799 14.4474 18.129 12.3251 16.6218 11.4575L14.8583 10.4425C13.8443 9.85888 12.5869 10.0261 11.7803 10.8753L11.4032 11.2724C11.3764 11.2819 11.2926 11.3045 11.1322 11.2784C10.7607 11.2181 9.95886 10.89 8.63696 9.49834C7.31913 8.1109 6.99454 7.25643 6.93292 6.83598C6.9032 6.63313 6.9334 6.52626 6.94535 6.4927L6.94732 6.48734L7.17338 6.24939C8.06643 5.30911 8.12966 3.83761 7.38134 2.77918L6.21737 1.13294Z"
                                    fill="#2EC4B6"
                                  />
                                </svg>
                              }
                              sx={{
                                flex: 1,
                                color: "#2EC4B6",
                                border: "1px solid #2EC4B6",
                                "&:hover": {
                                  backgroundColor: "rgba(46, 196, 182, 0.04)",
                                },
                              }}
                            >
                              Call
                            </Button>

                            <Button
                              onClick={() => {
                                setSelectedBroker(item?.broker);
                                setBrokerEmailModal(true);
                              }}
                              startIcon={
                                <svg
                                  width="22"
                                  height="16"
                                  viewBox="0 0 22 16"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.888889 0L0 0.888889V15.1111L0.888889 16H20.4444L21.3333 15.1111V0.888889L20.4444 0H0.888889ZM1.77778 2.89837V14.2222H19.5556V2.89807L10.6665 10.9791L1.77778 2.89837ZM18.1451 1.77778H3.18798L10.6665 8.57647L18.1451 1.77778Z"
                                    fill="#2EC4B6"
                                  />
                                </svg>
                              }
                              sx={{
                                flex: 1,
                                color: "#2EC4B6",
                                border: "1px solid #2EC4B6",
                                "&:hover": {
                                  backgroundColor: "rgba(46, 196, 182, 0.04)",
                                },
                              }}
                            >
                              Email
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}

                  {/* Add pagination inside property listing box */}
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                      disabled={
                        publicListingsData?.page_details?.count <=
                        publicListingsData?.page_details?.page_size
                      }
                      sx={{
                        "& .MuiPaginationItem-root": {
                          color: "#495251",
                          fontFamily: "Quicksand",
                          fontWeight: "600",
                        },
                        "& .Mui-selected": {
                          backgroundColor: "#2EC4B6 !important",
                          color: "#fff !important",
                        },
                      }}
                    />
                  </Box>
                </>
              ) : (
                "No data found"
              )}
            </Box>

            {/* <Box
              sx={{
                width: { xs: "100%", lg: "384px" },
                m: { xs: "0 auto", lg: "0" },
              }}
            >
              {isLoading ? (
                <SidebarSkeleton />
              ) : (
                <>
                  <Box sx={{ width: { sm: "384px", lg: "100%" }, m: "0 auto" }}>
                    <Typography
                      sx={{
                        m: "0 0 15px",
                        p: 0,
                        fontSize: "16px",
                        color: "#7D8383",
                        fontWeight: "600",
                        fontFamily: "Quicksand",
                      }}
                      variant="h4"
                    >
                      Be the first to hear about new properties
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #2EC4B6",
                        display: "flex",
                        borderRadius: "6px",
                        marginBottom: "25px",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Box
                        sx={{ p: "9px 10px", borderRight: "1px solid #2EC4B6" }}
                      >
                        <img
                          src={bell}
                          alt=""
                          style={{ verticalAlign: "top" }}
                        />
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "Quicksand",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#2EC4B6",
                          lineHeight: "20px",
                        }}
                      >
                        Alert me of new properties
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row", lg: "column" },
                      gap: "25px",
                      m: "0 0 25px",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "100%", sm: "50%", lg: "100%" },
                        border: "1px solid #E4E5E5",
                        borderRadius: "6px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          borderBottom: "1px solid #E4E5E5",
                          gap: "15px",
                          p: "14px 15px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={list}
                          alt=""
                          style={{ verticalAlign: "top" }}
                        />
                        <Typography
                          variant="h4"
                          sx={{
                            fontFamily: "Quicksand",
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#495251",
                            lineHeight: "20px",
                          }}
                        >
                          Recommended searches
                        </Typography>
                      </Box>
                      <Box sx={{ p: "15px" }}>
                        <List sx={{ m: 0, p: 0 }}>
                          <ListItem
                            sx={{
                              fontSize: "15px",
                              color: "#7D8383",
                              lineHeight: "20px",
                              fontWeight: "500",
                              display: "flex",
                              gap: "14px",
                              p: "5px 10px",
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "6px" }}>
                              <svg
                                width="6"
                                height="6"
                                viewBox="0 0 4 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="2" cy="2" r="2" fill="#7D8383" />
                              </svg>
                            </ListItemIcon>
                            <Typography
                              variant="span"
                              sx={{
                                fontFamily: "Quicksand",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#7D8383",
                              }}
                            >
                              Apartments for sale in UAE
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{
                              fontSize: "15px",
                              color: "#7D8383",
                              lineHeight: "20px",
                              fontWeight: "500",
                              display: "flex",
                              gap: "14px",
                              p: "5px 10px",
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "6px" }}>
                              <svg
                                width="6"
                                height="6"
                                viewBox="0 0 4 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="2" cy="2" r="2" fill="#7D8383" />
                              </svg>
                            </ListItemIcon>
                            <Typography
                              variant="span"
                              sx={{
                                fontFamily: "Quicksand",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#7D8383",
                              }}
                            >
                              Apartments for sale in UAE
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{
                              fontSize: "15px",
                              color: "#7D8383",
                              lineHeight: "20px",
                              fontWeight: "500",
                              display: "flex",
                              gap: "14px",
                              p: "5px 10px",
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "6px" }}>
                              <svg
                                width="6"
                                height="6"
                                viewBox="0 0 4 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="2" cy="2" r="2" fill="#7D8383" />
                              </svg>
                            </ListItemIcon>
                            <Typography
                              variant="span"
                              sx={{
                                fontFamily: "Quicksand",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#7D8383",
                              }}
                            >
                              Apartments for sale in UAE
                            </Typography>
                          </ListItem>
                        </List>
                        <Box sx={{ margin: "10px 0 10px 30px" }}>
                          <Link
                            sx={{
                              fontFamily: "Quicksand",
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#2EC4B6",
                            }}
                          >
                            View More
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: { xs: "100%", sm: "50%", lg: "100%" },
                        border: "1px solid #E4E5E5",
                        borderRadius: "6px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          borderBottom: "1px solid #E4E5E5",
                          gap: "15px",
                          p: "14px 15px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={list}
                          alt=""
                          style={{ verticalAlign: "top" }}
                        />
                        <Typography
                          variant="h4"
                          sx={{
                            fontFamily: "Quicksand",
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#495251",
                            lineHeight: "20px",
                          }}
                        >
                          Invest in Off Plan
                        </Typography>
                      </Box>
                      <Box sx={{ p: "15px" }}>
                        <List sx={{ m: 0, p: 0 }}>
                          <ListItem
                            sx={{
                              fontSize: "15px",
                              color: "#7D8383",
                              lineHeight: "20px",
                              fontWeight: "500",
                              display: "flex",
                              gap: "14px",
                              p: "5px 10px",
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "6px" }}>
                              <svg
                                width="6"
                                height="6"
                                viewBox="0 0 4 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="2" cy="2" r="2" fill="#7D8383" />
                              </svg>
                            </ListItemIcon>
                            <Typography
                              variant="span"
                              sx={{
                                fontFamily: "Quicksand",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#7D8383",
                              }}
                            >
                              Off Plan Properties in UAE
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{
                              fontSize: "15px",
                              color: "#7D8383",
                              lineHeight: "20px",
                              fontWeight: "500",
                              display: "flex",
                              gap: "14px",
                              p: "5px 10px",
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: "6px" }}>
                              <svg
                                width="6"
                                height="6"
                                viewBox="0 0 4 4"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="2" cy="2" r="2" fill="#7D8383" />
                              </svg>
                            </ListItemIcon>
                            <Typography
                              variant="span"
                              sx={{
                                fontFamily: "Quicksand",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#7D8383",
                              }}
                            >
                              New Projects in UAE
                            </Typography>
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box> */}
          </Box>
        </Box>

        <BrokerEmailModal
          setBrokerEmailModal={setBrokerEmailModal}
          brokerEmailModal={brokerEmailModal}
          setSelectedBroker={setSelectedBroker}
          selectedBroker={selectedBroker}
        />

        <BrokerPhoneModal
          setBrokerPhoneModal={setBrokerPhoneModal}
          brokerPhoneModal={brokerPhoneModal}
          setSelectedBroker={setSelectedBroker}
          selectedBroker={selectedBroker}
        />
      </Container>
    </>
  );
};

export default ListingsContent;
