import { TextField, Typography, useTheme } from "@mui/material";
import React from "react";

const CustomInput = ({
  name,
  id,
  min,
  required,
  readOnly,
  minRows,
  multiline,
  endAdornment,
  label,
  value,
  type,
  placeholder,
  onChange,
  onBlur,
  error,
  disabled,
  helperText,
  InputProps,
}) => {
  const theme = useTheme();
  return (
    <>
      <Typography
        variant="label"
        sx={{ color: "#333", whiteSpace: "nowrap", pr: 1 }}
      >
        {label}
      </Typography>
      <TextField
        name={name}
        fullWidth
        InputLabelProps={{
          shrink: false,
        }}
        sx={{
          "& .MuiInputBase-root": {
            "&:hover fieldset": {
              borderColor: theme.palette.primary.main,
            },
            "&.Mui-disabled": {
              backgroundColor: "#f5f5f5",
              "& .MuiInputBase-input": {
                color: "rgba(0, 0, 0, 0.6)",
                WebkitTextFillColor: "rgba(0, 0, 0, 0.6)",
                cursor: "not-allowed",
              },
            },
          },
          "& .MuiInputBase-input": {
            padding: "10px 14px",
            lineHeight: "24px",
          },
        }}
        id={id}
        inputProps={{
          readOnly: readOnly,
          min: 0,
        }}
        variant="outlined"
        multiline={multiline}
        minRows={minRows}
        type={type}
        required={required}
        endadornment={endAdornment}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
        value={value}
        helperText={helperText}
        onChange={onChange}
        InputProps={InputProps}
      />
    </>
  );
};

export default CustomInput;
