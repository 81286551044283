import React, { useEffect, useRef } from "react";
import CustomPaperBox from "../../theme/style-components/CustomPaperBox";
import {
  Backdrop,
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
  useTheme,
} from "@mui/material";
import CustomButton from "../../theme/style-components/CustomButton";
import { useState } from "react";
import {
  useDeletePropertyImagesMutation,
  useDeletePropertyVideoURLMutation,
  useLazyGetMyPropertyListingQuery,
  useUpdatePropertyImagesMutation,
  useUpdatePropertyVideoURLMutation,
} from "../../store/property/propertyApiSlice";
import { showError } from "../shared/helperFunction";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../loader/Loader";
import CustomInput from "../../theme/style-components/CustomInput";
import { videoURLValidationSchema } from "../../component/formik/validationSchema";
import useCustomFormik from "../../component/formik/useFormikForm";

const PropertyImages = ({ id, images, videos }) => {
  const theme = useTheme();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadedVideoURLs, setUploadedVideoURLs] = useState([]);
  const [imageError, setImageError] = useState(false);
  const inputRef = useRef(null);

  const [getMyPropertyListing] = useLazyGetMyPropertyListingQuery();
  const [
    deletePropertyImages,
    {
      data: deleteReturedData,
      isLoading: isDeleteLoading,
      error: isDeleteError,
    },
  ] = useDeletePropertyImagesMutation();
  const [updatePropertyImages, { data, isLoading, error }] =
    useUpdatePropertyImagesMutation();
  const [
    deletePropertyVideoURL,
    {
      data: videoURLdeleteReturedData,
      isLoading: isDeleteVideoURLLoading,
      error: isDeleteVideoURLError,
    },
  ] = useDeletePropertyVideoURLMutation();
  const [
    updatePropertyVideoURL,
    { data: videoURLReturnedData, isLoading: isVideoURLLoading },
  ] = useUpdatePropertyVideoURLMutation();

  useEffect(() => {
    if (images?.length > 0) {
      // const formatedImages = images.map((img) => ({ ...img, formatedURL: `${process.env.REACT_APP_BACKEND_URL}${img.image_url}` }))
      setUploadedImages(images);
    }
  }, [images]);

  useEffect(() => {
    if (videos?.length > 0) {
      setUploadedVideoURLs(videos);
    }
  }, [videos]);

  const handleImageUpload = async () => {
    const uploadPromises = selectedImages.map(async (file) => {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("id", id);

      try {
        const result = await updatePropertyImages(formData).unwrap();
        return true; // Indicates success for this file
      } catch (error) {
        showError(error?.data);
        return false; // Indicates failure for this file
      }
    });

    try {
      const results = await Promise.all(uploadPromises);
      if (results.every((success) => success)) {
        setSelectedImages([]);
        const result = await getMyPropertyListing({
          id: id,
          page: 1,
          query: "",
        }).unwrap();
        // All uploads were successful
        toast.success("Image uploaded successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        // Some uploads failed
        toast.error("Some images failed to upload", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const handleSubmit = async () => {
    if (selectedImages?.length > 0) {
      await handleImageUpload();
    } else {
      setImageError(true);
    }
  };

  const handleFileChange = (e) => {
    setImageError(false);

    const files = e.target.files;

    // Convert the FileList object to an array
    const filesArray = Array.from(files);

    // Update the state with the selected images
    setSelectedImages((pre) => [...pre, ...filesArray]);
  };

  const handleDeleteImage = async (deleteID) => {
    try {
      const result = await deletePropertyImages(deleteID).unwrap();
      if (result) {
        const res = await getMyPropertyListing({
          id: id,
          page: 1,
          query: "",
        }).unwrap();
        const updatedImages = uploadedImages.filter(
          (item) => item.id !== deleteID
        );
        setUploadedImages(updatedImages);
        toast.success("Image deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      showError(error?.data);
    }
  };

  const handleRemoveImage = (removeIndex) => {
    const filteredImages = selectedImages.filter(
      (item, index) => index !== removeIndex
    );
    setSelectedImages(filteredImages);
  };

  const initialValues = {
    videoURLs: [""],
  };

  const handleVideoURLUpload = async (urls) => {
    const uploadPromises = urls.map(async (url) => {
      try {
        const result = await updatePropertyVideoURL({
          video_url: url,
          id: id,
        }).unwrap();
        return true; // Indicates success for this file
      } catch (error) {
        showError(error?.data);
        return false; // Indicates failure for this file
      }
    });

    try {
      const results = await Promise.all(uploadPromises);
      if (results.every((success) => success)) {
        videoURLformik.setFieldValue("videoURLs", [""]);
        const result = await getMyPropertyListing({
          id: id,
          page: 1,
          query: "",
        }).unwrap();
        // All uploads were successful
        toast.success("Video URLs uploaded successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        // Some uploads failed
        toast.error("Some video URLS failed to upload", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error uploading video URLs:", error);
    }
  };

  const handleVideoURLSubmit = async (values) => {
    await handleVideoURLUpload(values.videoURLs);
  };

  const handleDeleteUploadedVideoURL = async (deleteID) => {
    try {
      const result = await deletePropertyVideoURL(deleteID).unwrap();
      if (result) {
        const res = await getMyPropertyListing({
          id: id,
          page: 1,
          query: "",
        }).unwrap();
        const updatedVideos = uploadedVideoURLs.filter(
          (item) => item.id !== deleteID
        );
        setUploadedVideoURLs(updatedVideos);
        toast.success("Video URLs deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      showError(error?.data);
    }
  };

  const videoURLformik = useCustomFormik(
    initialValues,
    videoURLValidationSchema,
    handleVideoURLSubmit
  );

  const handleAddVideoURL = () => {
    videoURLformik.setFieldValue("videoURLs", [
      ...videoURLformik.values.videoURLs,
      "",
    ]);
  };

  const handleDeleteVideoURL = (index) => {
    const updatedVideoURLs = [...videoURLformik.values.videoURLs];
    updatedVideoURLs.splice(index, 1);
    videoURLformik.setFieldValue("videoURLs", updatedVideoURLs);
  };

  const handleVideoURLChange = (index, value) => {
    const updatedVideoURLs = [...videoURLformik.values.videoURLs];
    updatedVideoURLs[index] = value;
    videoURLformik.setFieldValue("videoURLs", updatedVideoURLs);
  };

  return (
    <Box sx={{ display: "grid", gap: "20px" }}>
      <Box
        sx={{
          background: "#fff",
          border: "1px solid #E5E5E5",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            "& .MuiTypography-body1": {
              color: theme.palette.primary.main,
              fontSize: "18px",
              lineHeight: 1,
              marginBottom: "15px",
              p: "15px 20px",
              borderBottom: "1px solid #E5E5E5",
              background: "#f2f4f7",
              borderRadius: "10px 10px 0 0",
              m: 0,
            },
          }}
        >
          <Typography sx={{ color: "#011627" }}>Images</Typography>
        </Box>
        <Box sx={{ p: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  flex: "0 0 100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: "10px",
                  marginRight: "10px",
                  "& button": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <Button
                  onClick={() => {
                    inputRef.current.click();
                  }}
                >
                  <AddIcon />
                </Button>
                <input
                  name="dp"
                  ref={inputRef}
                  multiple
                  style={{ display: "none" }}
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleFileChange}
                />
              </Box>
              {uploadedImages?.length > 0 &&
                uploadedImages?.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        width: "100px",
                        height: "100px",
                        flex: "0 0 100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderRadius: "10px",
                        marginRight: "20px",
                        marginBottom: "20px",
                        position: "relative",
                        "& img": {
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          objectFit: "contain",
                        },
                      }}
                    >
                      <img
                        src={item?.image_url}
                        alt={`${item?.image_url} - ${index}`}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "-15px",
                          right: "-15px",
                          background: theme.palette.primary.main,
                          borderRadius: "50%",
                          padding: "7px",
                          width: "35px",
                          height: "35px",
                          "& svg": {
                            fontSize: "22px",
                            fill: theme.palette.common.white,
                          },
                        }}
                      >
                        <DeleteIcon
                          cursor="pointer"
                          onClick={() => handleDeleteImage(item.id)}
                        />
                      </Box>
                    </Box>
                  );
                })}
              {selectedImages?.length > 0 &&
                selectedImages?.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        width: "100px",
                        height: "100px",
                        flex: "0 0 100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #2ec4b6",
                        borderRadius: "10px",
                        marginRight: "20px",
                        marginBottom: "20px",
                        position: "relative",
                        "& img": {
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          objectFit: "contain",
                        },
                      }}
                    >
                      <img
                        src={URL.createObjectURL(item)}
                        alt={`Selected ${index}`}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "-15px",
                          right: "-15px",
                          background: theme.palette.primary.main,
                          borderRadius: "50%",
                          padding: "7px",
                          width: "35px",
                          height: "35px",
                          "& svg": {
                            fontSize: "22px",
                            fill: theme.palette.common.white,
                          },
                        }}
                      >
                        <DeleteIcon
                          cursor="pointer"
                          onClick={() => handleRemoveImage(index)}
                        />
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: "1px solid #E5E5E5",
            textAlign: "end",
            p: "15px 20px",
            marginTop: "20px",
          }}
        >
          {imageError && (
            <FormHelperText error={imageError}>
              Please select the images
            </FormHelperText>
          )}
          <CustomButton
            color="primary"
            type="submit"
            isLoading={isLoading}
            onClick={handleSubmit}
            disabled={isLoading}
            buttonName="Upload"
          />
        </Box>
      </Box>

      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isDeleteLoading || isLoading}
      >
        <Loader />
      </Backdrop>

      <Box
        sx={{
          background: "#fff",
          border: "1px solid #E5E5E5",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            "& .MuiTypography-body1": {
              color: theme.palette.primary.main,
              fontSize: "18px",
              lineHeight: 1,
              marginBottom: "15px",
              p: "15px 20px",
              borderBottom: "1px solid #E5E5E5",
              background: "#f2f4f7",
              borderRadius: "10px 10px 0 0",
              m: 0,
            },
          }}
        >
          <Typography sx={{ color: "#011627" }}>Videos</Typography>
        </Box>

        <Box sx={{ p: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            <Box alignItems="center">
              <List>
                {videoURLformik.values.videoURLs.map((url, index) => (
                  <ListItem key={index}>
                    <CustomInput
                      label={`Video URL`}
                      name={`videoURLs.${index}`}
                      onChange={(e) =>
                        handleVideoURLChange(index, e.target.value)
                      }
                      onBlur={videoURLformik.handleBlur}
                      value={url}
                      error={
                        videoURLformik.touched.videoURLs &&
                        Boolean(videoURLformik.errors.videoURLs?.[index])
                      }
                      helperText={
                        videoURLformik.touched.videoURLs &&
                        videoURLformik.errors.videoURLs?.[index]
                      }
                    />
                    <Box
                      sx={{
                        marginBottom:
                          videoURLformik.touched.videoURLs &&
                          videoURLformik.errors.videoURLs?.[index]
                            ? "22px"
                            : 0,
                      }}
                    >
                      <IconButton
                        edge="end"
                        color="error"
                        onClick={() => handleDeleteVideoURL(index)}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Box>
                  </ListItem>
                ))}
                {uploadedVideoURLs.map((item, index) => (
                  <ListItem key={index}>
                    <CustomInput
                      label={`Video URL`}
                      name={`videoURLs.${index}`}
                      disabled={true}
                      value={item.url}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        color="error"
                        onClick={() => handleDeleteUploadedVideoURL(item?.id)}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>
            <CustomButton buttonName="Add URL" onClick={handleAddVideoURL} />
          </Box>
        </Box>

        <Box
          sx={{
            borderTop: "1px solid #E5E5E5",
            textAlign: "end",
            p: "15px 20px",
            marginTop: "20px",
          }}
        >
          <Box
            sx={{
              textAlign: "end",
              marginTop: "15px",
              "& p": {
                textAlign: "end",
              },
            }}
          >
            {imageError && (
              <FormHelperText error={imageError}>
                Please select the images
              </FormHelperText>
            )}
            <CustomButton
              color="primary"
              type="submit"
              isLoading={isLoading}
              onClick={videoURLformik.handleSubmit}
              disabled={isLoading}
              buttonName="Submit"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PropertyImages;
