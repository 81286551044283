import { Box } from '@mui/material';
import HomeHeader from './HomeHeader'
import HomeFooter from './HomeFooter'

const PublicLayout = ({ children }) => {
    
    return (
            <Box >
                <HomeHeader  />
                {children}
                <HomeFooter />
            </Box>
    );
};

export default PublicLayout; 