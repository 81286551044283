import { apiSlice } from "../apiSlice";

export const userProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => `/user/user/me/`,
      keepUnusedDataFor: 0,
    }),
    getPublicUser: builder.query({
      query: ({ page, query }) => `/user/public_user/?page=${page}${query}`,
      keepUnusedDataFor: 0,
    }),
    getAllUsersData: builder.query({
      query: ({ company_id }) =>
        `/user_extended/users/?company_id=${company_id}`,
      keepUnusedDataFor: 0,
    }),
    getAllCompanyMembers: builder.query({
      query: ({ company_id, search, page, page_size }) =>
        `/user_extended/user-public-profiles/?page=${page}&page_size=${page_size}&company_id=${company_id}&search=${search}`,
      keepUnusedDataFor: 0,
    }),
    deleteUserPublicProfile: builder.mutation({
      query: (id) => ({
        url: `/user_extended/user-public-profiles/${id}/`,
        method: "DELETE",
      }),
    }),
    createUserPublicProfile: builder.mutation({
      query: (formData) => ({
        url: `/user_extended/user-public-profiles/`,
        method: "POST",
        body: formData,
      }),
    }),
    updateUserPublicProfile: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/user_extended/user-public-profiles/${id}/`,
        method: "PUT",
        body: formData,
        formData: true,
      }),
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useGetPublicUserQuery,
  useLazyGetPublicUserQuery,
  useGetAllUsersDataQuery,
  useGetAllCompanyMembersQuery,
  useDeleteUserPublicProfileMutation,
  useCreateUserPublicProfileMutation,
  useUpdateUserPublicProfileMutation,
} = userProfileApiSlice;
