import React from "react";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Typography, Box } from "@mui/material";

const CustomAutoComplete = ({
  options,
  label,
  placeholder,
  onChange,
  getOptionLabel,
  multiple = false,
  value,
  sx,
  disabled = false,
  ...props
}) => {
  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      value={value}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          sx={{
            backgroundColor: option.id === "add" ? "#f0f0f0" : "inherit",
            fontWeight: option.id === "add" ? "bold" : "normal",
            color: option.id === "add" ? "#007bff" : "inherit",
            "&:hover": {
              backgroundColor:
                option.id === "add" ? "#e0e0e0" : "rgba(0, 0, 0, 0.08)",
            },
          }}
        >
          <Typography>{option.name}</Typography>
        </Box>
      )}
      sx={{
        width: "100%",
        "& .MuiFormControl-root": {
          "& .MuiInputLabel-root": {
            transform: "translate(14px, 12px) scale(1)",
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
          "& .MuiInputBase-root": {
            p: 0,
            "& .MuiInputBase-input": {
              p: "4px 5px",
              height: "23px",
              lineHeight: "20px",
            },
            "& .MuiChip-root": {
              height: "22px",
              lineHeight: "22px",
              "& .MuiChip-label": {
                paddingLeft: "8px",
                paddingRight: "8px",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "18px",
                m: "0 2px 0 0",
              },
            },
          },
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export default CustomAutoComplete;
