import { useState } from "react";
import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import loginBg from "../../asset/images/logos/real-estate-banner.jpg";
import { ReactComponent as Logo } from "../../asset/images/logos/1byx-logo-final.svg";
import SignIn from "./SignIn";

const Auth = () => {
  const theme = useTheme();
  //   const [forgetPassword, { isLoading: isForgotLoading }] =
  //     useForgetPasswordMutation();
  //   const [isForget, setIsForget] = useState(false);
  const [value, setValue] = useState(0);
  //   console.log(theme, "theme");

  //   const forgotValidationSchema = yup.object({
  //     forgotEmail: yup
  //       .string()
  //       .email("Plase enter a valid email")
  //       .required("Please enter a email"),
  //   });

  //   const themeLogo = blackLogo;

  //   const forgotFormik = useFormik({
  //     initialValues: {
  //       email: "",
  //     },
  //     validationSchema: forgotValidationSchema,
  //     onSubmit: async (values) => {
  //       const { email } = values;
  //       try {
  //         const userData = await forgetPassword({ email: email }).unwrap();
  //         toast.success("Email sent successfully!");
  //         setIsForget(!isForget);
  //       } catch (err) {
  //         showError(err?.data);
  //       }
  //     },
  //   });

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     "aria-controls": `simple-tabpanel-${index}`,
  //   };
  // }

  const handleForget = () => {
    // setIsForget(!isForget);
  };

  // const headingDetails =
  //   value === 0
  //     ? "Enter your email and password to access your account."
  //     : "Please enter your contact details to connect";
  return (
    <Box sx={{ height: "100vh" }}>
      <Grid container spacing={0} sx={{ height: "100%" }}>
        <Grid
          items
          xs={12}
          md={6}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              background: theme.palette.background.default,
              //   padding: "40px",
            }}
          >
            <img
              src={loginBg}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
        </Grid>
        <Grid items xs={12} md={6} sx={{ height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              background: theme.palette.background.paper,
              padding: { sm: "30px", md: "30px", xs: "30px" },
              position: "relative",
            }}
          >
            <Box sx={{ maxWidth: "500px", width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "30px",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                {/* <img src={themeLogo} /> */}
                <Box
                  sx={{
                    maxWidth: "100px",
                    width: "100px",
                    "& svg": {
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    },
                  }}
                >
                  <Logo />
                </Box>
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "30px",
                    fontWeight: "600",
                  }}
                >
                  Sign In
                </Typography>
              </Box>
              {/* <Tabs
                value={value}
                sx={{
                  marginBottom: "25px",
                  "& .Mui-selected": {
                    color: `${theme.palette.common.white} !important`,
                    backgroundColor: `${theme.palette.primary.main} !important`,
                    border: "none",
                  },
                  "& .MuiTabs-flexContainer": {
                    display: "block !important",
                    textAlign: "center",
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab
                  value={0}
                  sx={{
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: "6px 0 0 6px",
                    width: "50%",
                    color: theme.palette.text.primary,
                    fontSize: "16px",
                    lineHeight: "20px",
                    textTransform: "none",
                    fontWeight: "400",
                  }}
                  label="Sign In"
                  {...a11yProps(0)}
                />
                <Tab
                  value={1}
                  sx={{
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: "0 6px 6px 0",
                    width: "50%",
                    color: theme.palette.text.primary,
                    fontSize: "16px",
                    lineHeight: "20px",
                    textTransform: "none",
                    fontWeight: "500",
                  }}
                  label="Sign Up"
                  {...a11yProps(0)}
                />
              </Tabs> */}
              <CustomTabPanel value={value} index={0}>
                <SignIn handleForget={handleForget} />
              </CustomTabPanel>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Auth;
