import React from "react";
import CustomModal from "../../theme/style-components/CustomModal";
import { Box, Typography, IconButton } from "@mui/material";
import response from "../../asset/response.svg";
import CloseIcon from "@mui/icons-material/Close";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic"; // For the responsive/support icon

export const ResponsiveBrokerBadge = ({
  setIsResponsiveBrokerModalOpen,
  isResponsiveBrokerModalOpen,
}) => {
  return (
    <CustomModal
      handleClose={() => setIsResponsiveBrokerModalOpen(false)}
      isModalOpen={isResponsiveBrokerModalOpen}
      isShowActionButton={false}
      maxWidth="sm"
    >
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "20px",
          maxWidth: "500px",
          width: "100%",
        }}
      >
        {/* Responsive Broker Badge */}
        <Box
          sx={{
            width: "320px",
            margin: "0 auto 20px",
            background: "rgb(244, 233, 245)",
            padding: "15px 20px",
            borderRadius: "10px",
            textAlign: "center",
            // boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <img src={response} alt="" width={25} height={25} />
          <Typography
            sx={{
              color: "#495251",
              fontSize: "20px",
              fontWeight: "700",
              fontFamily: "Quicksand",
            }}
          >
            Responsive Broker
          </Typography>
        </Box>

        {/* Modal Content */}
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#495251",
              lineHeight: "1.5",
              fontFamily: "Quicksand",
            }}
          >
            Agents with the Responsive Broker badge have showcased that they are
            easily reachable, highly responsive and always available.
          </Typography>
        </Box>
      </Box>
    </CustomModal>
  );
};
