import { apiSlice } from "../apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateUserProfile: builder.mutation({
      query: (formData) => ({
        url: "/user_extended/update_user/",
        method: "PUT",
        body: formData,
        formData: true,
      }),
    }),
    updateBrokerProfile: builder.mutation({
      query: (data) => ({
        url: "/user/update_broker/",
        method: "PUT",
        body: { ...data },
      }),
    }),
    updateDevProfile: builder.mutation({
      query: (data) => ({
        url: "/user/update_developer/",
        method: "PUT",
        body: { ...data },
      }),
    }),
    getCompanyDetail: builder.query({
      query: ({ id, user_id, search }) =>
        `/user_extended/company-profiles/${id ? `?id=${id}` : ``}${
          user_id ? `?user_id=${user_id}` : ``
        }&search=${search}`,
      keepUnusedDataFor: 0,
      meta: { noAuth: true },
    }),
    updateCompanyDetail: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/user_extended/company-profiles/${id}/`,
        method: "PUT",
        body: formData,
        formData: true,
      }),
    }),
  }),
});

export const {
  useUpdateUserProfileMutation,
  useUpdateBrokerProfileMutation,
  useUpdateDevProfileMutation,
  useGetCompanyDetailQuery,
  useUpdateCompanyDetailMutation,
} = settingsApiSlice;
