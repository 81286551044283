import React from 'react';
import ChatBot from './ChatBot';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton, 
  Typography,
  Box,
  styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '380px',
    height: '600px',
    maxWidth: '100%',
    margin: '20px',
    borderRadius: '12px',
    position: 'fixed',
    bottom: 0,
    right: 0,
    animation: 'slideUp 0.3s ease-out',
    '@keyframes slideUp': {
      from: {
        transform: 'translateY(100%)',
        opacity: 0,
      },
      to: {
        transform: 'translateY(0)',
        opacity: 1,
      },
    },
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor:'#2EC4B6',
  color: theme.palette.primary.contrastText,
  padding: '16px 20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .MuiIconButton-root': {
    color: theme.palette.primary.contrastText,
    marginRight: -8,
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundColor: theme.palette.grey[100],
}));

const ChatbotModal = ({ isOpen, onClose }) => {
  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      TransitionProps={{
        enter: true,
        exit: true,
      }}
    >
      <StyledDialogTitle>
        <Typography variant="h6" component="div">
          Chat Assistant
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <ChatBot />
        </Box>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ChatbotModal; 