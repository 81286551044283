import React, { useState } from "react";
import CustomModal from "../../theme/style-components/CustomModal";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import { usePostContactBrokerMutation } from "../../store/broker/brokerListingsApiSlice";

export const BrokerEmailModal = ({
  setBrokerEmailModal,
  brokerEmailModal,
  setSelectedBroker,
  selectedBroker,
}) => {
  console.log("selectedBroker", selectedBroker);

  const [formData, setFormData] = useState({
    broker_id: selectedBroker?.id,
    name: "",
    email: "",
    phone_number: "",
    description: "",
    keepInformed: false,
  });

  const [postContactBroker] = usePostContactBrokerMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
    console.log(selectedBroker);
    postContactBroker(formData);
  };

  return (
    <CustomModal
      handleClose={() => {
        setBrokerEmailModal(false);
        setSelectedBroker(null);
      }}
      isModalOpen={brokerEmailModal}
      isShowActionButton={false}
      maxWidth="sm"
    >
      <Box sx={{ position: "relative" }}>
        {/* Title */}
        <Typography
          variant="h6"
          sx={{
            mb: 3,
            fontWeight: 500,
            textAlign: "center",
            color: "#000",
          }}
        >
          Email agent for more information
        </Typography>

        {/* Form */}
        <Box component="form" onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            <TextField
              fullWidth
              placeholder="Enter name*"
              variant="outlined"
              size="small"
              sx={{
                mb: 2,
                border: "1px solid #ddd",
                borderRadius: "10px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0",
                },
              }}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />

            <TextField
              fullWidth
              placeholder="Enter email*"
              variant="outlined"
              size="small"
              sx={{
                mb: 2,
                border: "1px solid #ddd",
                borderRadius: "10px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0",
                },
              }}
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </Box>

          <TextField
            fullWidth
            placeholder="+971"
            variant="outlined"
            size="small"
            sx={{
              mb: 2,
              border: "1px solid #ddd",
              borderRadius: "10px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "0",
              },
            }}
            value={formData.phone_number}
            onChange={(e) =>
              setFormData({ ...formData, phone_number: e.target.value })
            }
          />

          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="I would like to inquire about your property Bayut - 103732-DyPhyX. Please contact me at your earliest convenience."
            variant="outlined"
            size="small"
            sx={{
              mb: 2,
              border: "1px solid #ddd",
              borderRadius: "10px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "0",
              },
            }}
            value={formData.description}
            onChange={(e) =>
              setFormData({ ...formData, description: e.target.value })
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.keepInformed}
                onChange={(e) =>
                  setFormData({ ...formData, keepInformed: e.target.checked })
                }
                sx={{ "&.Mui-checked": { color: "#00796B" } }}
              />
            }
            label="Keep me informed about similar properties."
            sx={{ mb: 2, color: "#000" }}
          />

          <Button
            fullWidth
            variant="contained"
            startIcon={<EmailIcon />}
            type="submit"
            sx={{
              backgroundColor: "#00796B",
              "&:hover": {
                backgroundColor: "#00695C",
              },
              textTransform: "none",
              py: 1.5,
            }}
          >
            Send Email
          </Button>
        </Box>
      </Box>
    </CustomModal>
  );
};
