import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Skeleton,
  Button,
} from "@mui/material";
import gallery01 from "../../asset/01.jpg";
import gallery02 from "../../asset/02.jpg";
import gallery03 from "../../asset/03.jpg";
import gallery04 from "../../asset/01.jpg";
import boxes from "../../asset/boxes.svg";
import bed from "../../asset/bed.svg";
import bath from "../../asset/bath.svg";
import furniture from "../../asset/furniture.svg";
import parking from "../../asset/parking.svg";
import pets from "../../asset/pets.svg";
import balcony from "../../asset/balcony.svg";
import building from "../../asset/building.svg";
import share from "../../asset/share.svg";
import heartPrimary from "../../asset/heart-primary.svg";
import star from "../../asset/star.svg";
import call from "../../asset/call.svg";
import email from "../../asset/email.svg";
import whatsapp from "../../asset/whatsapp.svg";
import response from "../../asset/response.svg";
import diamond from "../../asset/diamond.svg";
import dummyProperty from "../../asset/Homepage-image-new.png";
import { useGetPropertyPublicListQuery } from "../../store/public-listings/publicListingsApiSlice";
import { PropertyGalleryModal } from "./PropertyGalleryModal";
import { BrokerEmailModal } from "../brokerDetailModals/BrokerEmailModal";
import { BrokerPhoneModal } from "../brokerDetailModals/BrokerPhoneModal";
import moment from "moment";
import { toast } from "react-toastify";

const PropertyDetail = () => {
  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get("id");
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("gallery");
  const [brokerEmailModal, setBrokerEmailModal] = useState(false);
  const [brokerPhoneModal, setBrokerPhoneModal] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState(null);

  const { isLoading, data: publicListingsData } = useGetPropertyPublicListQuery(
    { id: propertyId }
  );

  useEffect(() => {
    console.log("publicListingsData", publicListingsData);
    setPropertyDetail(publicListingsData?.data[0]);
  }, [publicListingsData]);

  // Use only API images
  const propertyImages =
    propertyDetail?.images?.map((img) => img.image_url) || [];

  // Handler for image clicks
  const handleImageClick = (tab = "gallery") => {
    setSelectedTab(tab);
    setIsGalleryModalOpen(true);
  };

  // Loading skeleton for images section
  const ImagesSkeleton = () => (
    <Box sx={{ display: "flex", gap: "10px", m: "0 0 20px" }}>
      <Skeleton
        variant="rectangular"
        sx={{
          width: { xs: "100%", md: "calc(100% - 320px)" },
          height: "400px",
          borderRadius: "4px",
        }}
      />
      <Box
        sx={{
          width: "320px",
          display: { xs: "none", md: "grid" },
          gap: "10px",
          height: "400px",
        }}
      >
        {[1, 2, 3].map((item) => (
          <Skeleton
            key={item}
            variant="rectangular"
            sx={{ height: "126px", borderRadius: "4px" }}
          />
        ))}
      </Box>
    </Box>
  );

  // Loading skeleton for property details
  const DetailsSkeleton = () => (
    <Box sx={{ width: { xs: "100%", md: "calc(100% - 320px)" } }}>
      {/* Title and Share button */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Skeleton variant="text" width="60%" height={40} />
        <Skeleton
          variant="rectangular"
          width={100}
          height={40}
          sx={{ borderRadius: "6px" }}
        />
      </Box>

      {/* Property name and location */}
      <Skeleton variant="text" width="80%" height={30} sx={{ mb: 2 }} />

      {/* Property features */}
      <Box sx={{ display: "flex", gap: "20px", mb: 3 }}>
        {[1, 2, 3, 4].map((item) => (
          <Skeleton
            key={item}
            variant="rectangular"
            width={80}
            height={80}
            sx={{ borderRadius: "6px" }}
          />
        ))}
      </Box>

      {/* Description */}
      <Box sx={{ mb: 3 }}>
        <Skeleton variant="text" width="40%" height={30} sx={{ mb: 1 }} />
        {[1, 2, 3].map((item) => (
          <Skeleton
            key={item}
            variant="text"
            width="100%"
            height={20}
            sx={{ mb: 1 }}
          />
        ))}
      </Box>

      {/* Features list */}
      <Box sx={{ mb: 3 }}>
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <Skeleton
            key={item}
            variant="text"
            width="90%"
            height={24}
            sx={{ mb: 1 }}
          />
        ))}
      </Box>
    </Box>
  );

  const handleShare = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        console.log("URL copied to clipboard");
        toast.success("URL copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy URL:", err);
        toast.error("Failed to copy URL");
      });
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "184px",
          background: `url(${dummyProperty})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: { md: "left bottom -100px", xs: "inherit" },
          position: "relative",
          marginBottom: "40px",
          borderRadius: "0 0 20px 20px",
          zIndex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 0 0",
          "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            bottom: 0,
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "0 0 20px 20px",
            zIndex: "-1",
          },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: "Quicksand",
            color: "#fff",
            fontSize: "40px",
            lineHeight: 1,
            fontWeight: "600",
          }}
        >
          Property Detail
        </Typography>
      </Box>
      <Container>
        {isLoading ? (
          <>
            <ImagesSkeleton />
            <Box sx={{ display: { xs: "grid", md: "flex" }, gap: "10px" }}>
              <DetailsSkeleton />
              {/* Right sidebar skeleton */}
              <Box sx={{ width: { xs: "100%", md: "320px" } }}>
                <Skeleton
                  variant="rectangular"
                  height={200}
                  sx={{ borderRadius: "6px", mb: 2 }}
                />
                <Skeleton
                  variant="rectangular"
                  height={300}
                  sx={{ borderRadius: "6px" }}
                />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", gap: "10px", m: "0 0 20px" }}>
              <Box
                sx={{
                  width:
                    propertyImages.length === 1
                      ? "100%"
                      : { xs: "100%", md: "calc(100% - 320px)" },
                  cursor: "pointer",
                  height: "400px",
                  position: "relative",
                  borderRadius: "4px",
                  overflow: "hidden",
                }}
                onClick={() => handleImageClick("gallery")}
              >
                <img
                  src={propertyImages[0]}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    transition: "transform 0.3s ease",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "16px",
                    right: "16px",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    color: "white",
                    padding: "4px 12px",
                    borderRadius: "20px",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {propertyImages.length} Photos
                </Box>
              </Box>
              {propertyImages.length > 1 && (
                <Box
                  sx={{
                    width: "320px",
                    display: { xs: "none", sm: "grid" },
                    gap: "10px",
                    height: "400px",
                    gridTemplateRows:
                      propertyImages.length === 2
                        ? "1fr"
                        : propertyImages.length === 3
                        ? "1fr 1fr"
                        : "1fr 1fr 1fr",
                  }}
                >
                  {propertyImages.slice(1, 4).map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        cursor: "pointer",
                        height:
                          propertyImages.length === 2
                            ? "400px" // Full height for 2 images
                            : propertyImages.length === 3
                            ? "195px" // Half height for 3 images
                            : "126px", // One-third height for 4 or more images
                        position: "relative",
                        borderRadius: "4px",
                        overflow: "hidden",
                      }}
                      onClick={() => handleImageClick("gallery")}
                    >
                      <img
                        src={image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: { xs: "grid", md: "flex" },
                gap: "10px",
                m: "0 0 20px",
              }}
            >
              <Box sx={{ width: { xs: "100%", md: "calc(100% - 320px)" } }}>
                <Box
                  sx={{
                    m: "0 0 25px",
                    display: { xs: "grid", sm: "flex" },
                    gap: "10px",
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      fontFamily: "Quicksand",
                      fontSize: "35px",
                      fontWeight: "600",
                      display: "flex",
                      gap: "8px",
                      alignItems: "end",
                      lineHeight: 1,
                      color: "#495251",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#2EC4B6",
                        lineHeight: 1,
                        fontFamily: "Quicksand",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                      variant="span"
                    >
                      AED
                    </Typography>
                    {propertyDetail?.financials?.total_amount}
                  </Typography>
                  <Box
                    sx={{
                      m: { xs: "0", md: "0 0 0 auto" },
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    {/* <Link
                      sx={{
                        background: "#fff",
                        border: "1px solid #E4E5E5",
                        borderRadius: "6px",
                        p: "6px 10px",
                        display: "flex",
                        gap: "6px",
                        textDecoration: "none",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ width: "15px" }}
                        src={heartPrimary}
                        alt=""
                      />
                      <Typography
                        sx={{
                          fontFamily: "Quicksand !important",
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#2EC4B6",
                        }}
                        variant="span"
                      >
                        Save
                      </Typography>
                    </Link> */}
                    <Button
                      sx={{
                        background: "#fff",
                        border: "1px solid #E4E5E5",
                        borderRadius: "6px",
                        p: "6px 10px",
                        display: "flex",
                        gap: "6px",
                        textDecoration: "none",
                        alignItems: "center",
                      }}
                      onClick={() => handleShare()}
                    >
                      <img style={{ width: "15px" }} src={share} alt="" />
                      <Typography
                        sx={{
                          fontFamily: "Quicksand !important",
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#2EC4B6",
                        }}
                        variant="span"
                      >
                        Share{" "}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ m: "0 0 25px" }}>
                  <Typography
                    sx={{
                      m: "0 0 10px",
                      fontFamily: "Quicksand !important",
                      fontWeight: "700",
                      fontSize: "18px",
                      color: "#495251",
                    }}
                  >
                    {propertyDetail?.name}
                    {" | "}
                    {propertyDetail?.location?.address}
                  </Typography>
                  <Box sx={{ display: "flex", gap: "20px", m: "0 0 25px" }}>
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <img src={bed} alt="" style={{ maxWidth: "100%" }} />
                      <Typography
                        sx={{
                          fontFamily: "Quicksand !important",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#495251",
                        }}
                        variant="span"
                      >
                        {propertyDetail?.features?.bedrooms} Bedroom
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <img src={bath} alt="" style={{ maxWidth: "100%" }} />
                      <Typography
                        sx={{
                          fontFamily: "Quicksand !important",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#495251",
                        }}
                        variant="span"
                      >
                        {propertyDetail?.features?.bathrooms} Bath
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <img src={boxes} alt="" style={{ maxWidth: "100%" }} />
                      <Typography
                        sx={{
                          fontFamily: "Quicksand !important",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#495251",
                        }}
                        variant="span"
                      >
                        {propertyDetail?.features?.area_sqft} sqft
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Typography
                    sx={{
                      m: "0 0 5px",
                      lineHeight: 1.25,
                      fontFamily: "Quicksand !important",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: 1,
                      color: "#495251",
                    }}
                    variant="h6"
                  >
                    Furnished Unit |High Floor | 1% Payment plan
                  </Typography> */}
                </Box>
                {/* <Box>
                  <Typography
                    sx={{
                      fontFamily: "Quicksand !important",
                      fontSize: "20px",
                      fontWeight: "600",
                      display: "flex",
                      gap: "8px",
                      lineHeight: 1,
                      alignItems: "end",
                      m: "0 0 5px",
                      lineHeight: 1,
                      color: "#2EC4B6",
                    }}
                    variant="h6"
                  >
                    Property Details:
                  </Typography>
                  <List>

                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      * With one parking
                    </ListItem>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      * Completion: September 2025
                    </ListItem>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      * Middle Floor
                    </ListItem>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      * With one parking
                    </ListItem>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      * Completion: September 2025
                    </ListItem>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      * Middle Floor
                    </ListItem>
                  </List>
                </Box> */}
                {/* <Box>
                  <Typography
                    sx={{
                      fontFamily: "Quicksand !important",
                      fontSize: "20px",
                      fontWeight: "600",
                      display: "flex",
                      gap: "8px",
                      lineHeight: 1,
                      alignItems: "end",
                      m: "0 0 5px",
                      lineHeight: 1,
                      color: "#2EC4B6",
                    }}
                    variant="h6"
                  >
                    Community Amenities:
                  </Typography>
                  <List>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      - Swimming Pool
                    </ListItem>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      - Fitness Center
                    </ListItem>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      - Landscaped Gardens
                    </ListItem>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      - Children's Play Area
                    </ListItem>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      - Security
                    </ListItem>
                    <ListItem
                      sx={{
                        p: "0 0 10px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#495251",
                      }}
                    >
                      - Communal Area
                    </ListItem>
                  </List>
                </Box> */}
                <Typography
                  sx={{
                    fontFamily: "Quicksand !important",
                    fontSize: "16px",
                    fontWeight: "500",
                    display: "flex",
                    gap: "8px",
                    lineHeight: 1,
                    m: "0 0 10px",
                    lineHeight: 1.5,
                    color: "#495251",
                  }}
                  variant="p"
                >
                  {propertyDetail?.description}
                </Typography>
                <Box sx={{ m: "0 0 20px" }}>
                  <List>
                    <ListItem
                      sx={{
                        fontFamily: "Quicksand !important",
                        fontSize: "16px",
                        fontWeight: "500",
                        display: "flex",
                        gap: "8px",
                        lineHeight: 1,
                        alignItems: "end",
                        m: "0",
                        p: "0",
                        lineHeight: 1.75,
                        color: "#495251",
                      }}
                    >
                      Company name: {propertyDetail?.broker?.company?.name}
                    </ListItem>
                    {/* <ListItem
                      sx={{
                        fontFamily: "Quicksand !important",
                        fontSize: "16px",
                        fontWeight: "500",
                        display: "flex",
                        gap: "8px",
                        lineHeight: 1,
                        alignItems: "end",
                        m: "0",
                        p: "0",
                        lineHeight: 1.75,
                        color: "#495251",
                      }}
                    >
                      RERA ORN: 12108
                    </ListItem> */}
                    <ListItem
                      sx={{
                        fontFamily: "Quicksand !important",
                        fontSize: "16px",
                        fontWeight: "500",
                        display: "flex",
                        gap: "8px",
                        lineHeight: 1,
                        alignItems: "end",
                        m: "0",
                        p: "0",
                        lineHeight: 1.75,
                        color: "#495251",
                      }}
                    >
                      Address: {propertyDetail?.broker?.company?.address}{" "}
                    </ListItem>
                    <ListItem
                      sx={{
                        fontFamily: "Quicksand !important",
                        fontSize: "16px",
                        fontWeight: "500",
                        display: "flex",
                        gap: "8px",
                        lineHeight: 1,
                        alignItems: "end",
                        m: "0",
                        p: "0",
                        lineHeight: 1.75,
                        color: "#495251",
                      }}
                    >
                      Office phone no: {propertyDetail?.broker?.company?.phone}
                    </ListItem>
                    <ListItem
                      sx={{
                        fontFamily: "Quicksand !important",
                        fontSize: "16px",
                        fontWeight: "500",
                        display: "flex",
                        gap: "8px",
                        lineHeight: 1,
                        alignItems: "end",
                        m: "0",
                        p: "0",
                        lineHeight: 1.75,
                        color: "#495251",
                      }}
                    >
                      Primary email: {propertyDetail?.broker?.company?.email}
                    </ListItem>
                    <ListItem
                      sx={{
                        fontFamily: "Quicksand !important",
                        fontSize: "16px",
                        fontWeight: "500",
                        display: "flex",
                        gap: "8px",
                        lineHeight: 1,
                        alignItems: "end",
                        m: "0",
                        p: "0",
                        lineHeight: 1.75,
                        color: "#495251",
                      }}
                    >
                      Website: {propertyDetail?.broker?.company?.website}
                    </ListItem>
                    <ListItem
                      sx={{
                        fontFamily: "Quicksand !important",
                        fontSize: "16px",
                        fontWeight: "500",
                        display: "flex",
                        gap: "8px",
                        lineHeight: 1,
                        alignItems: "end",
                        m: "0",
                        p: "0",
                        lineHeight: 1.75,
                        color: "#495251",
                      }}
                    >
                      Company Profile:{" "}
                      {propertyDetail?.broker?.company?.description}
                    </ListItem>
                  </List>
                </Box>
                <Box sx={{ m: "0 0 25px" }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Quicksand !important",
                      fontSize: "20px",
                      fontWeight: "600",
                      display: "flex",
                      gap: "8px",
                      lineHeight: 1,
                      alignItems: "end",
                      m: "0 0 5px",
                      lineHeight: 1,
                      color: "#2EC4B6",
                    }}
                  >
                    Property Information
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gap: "40px",
                      gridTemplateColumns: {
                        xs: "repeat(1, minmax(0, 1fr))",
                        md: "repeat(2, minmax(0, 1fr))",
                      },
                    }}
                  >
                    <List>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Type
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          {propertyDetail?.property_type}
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Purpose
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          For {propertyDetail?.listing_type}
                        </Typography>
                      </ListItem>
                      {/* <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Reference no.
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          Bayut - BI-S-20-9984
                        </Typography>
                      </ListItem> */}
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Status
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          {propertyDetail?.listing_status}
                        </Typography>
                      </ListItem>
                    </List>
                    <List>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Furnishing
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          Furnished
                        </Typography>
                      </ListItem>
                      {/* <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          TruCheck™on
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          6 February 2025
                        </Typography>
                      </ListItem> */}
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Added on
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          {moment(propertyDetail?.created_at).format(
                            "DD MMM YYYY"
                          )}
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>
                </Box>
                {/* <Box sx={{ m: "0 0 25px" }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Quicksand !important",
                      fontSize: "20px",
                      fontWeight: "600",
                      display: "flex",
                      gap: "8px",
                      lineHeight: 1,
                      alignItems: "end",
                      m: "0 0 5px",
                      lineHeight: 1,
                      color: "#2EC4B6",
                    }}
                  >
                    Validated Information
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gap: "40px",
                      gridTemplateColumns: {
                        xs: "repeat(1, minmax(0, 1fr))",
                        md: "repeat(2, minmax(0, 1fr))",
                      },
                    }}
                  >
                    <List>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Type
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          Apartment
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Purpose
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          For Sale
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Reference no.
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          Bayut - BI-S-20-9984
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Completion
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          Off-Plan
                        </Typography>
                      </ListItem>
                    </List>
                    <List>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Furnishing
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          Furnished
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          TruCheck™on
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          6 February 2025
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Added on
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          6 February 2025
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>
                </Box> */}
                {/* <Box sx={{ m: "0 0 25px" }}>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Quicksand !important",
                      fontSize: "20px",
                      fontWeight: "600",
                      display: "flex",
                      gap: "8px",
                      lineHeight: 1,
                      alignItems: "end",
                      m: "0 0 5px",
                      lineHeight: 1,
                      color: "#2EC4B6",
                    }}
                  >
                    Building Information
                  </Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gap: "40px",
                      gridTemplateColumns: {
                        xs: "repeat(1, minmax(0, 1fr))",
                        md: "repeat(2, minmax(0, 1fr))",
                      },
                    }}
                  >
                    <List>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Type
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          Apartment
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Purpose
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          For Sale
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Reference no.
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          Bayut - BI-S-20-9984
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Completion
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          Off-Plan
                        </Typography>
                      </ListItem>
                    </List>
                    <List>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Furnishing
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          Furnished
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          TruCheck™on
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          6 February 2025
                        </Typography>
                      </ListItem>
                      <ListItem
                        sx={{ p: "8px 0", borderBottom: "1px solid #E4E5E5" }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "35%",
                            fontWeight: "400",
                          }}
                          variant="span"
                        >
                          Added on
                        </Typography>
                        <Typography
                          sx={{
                            lineHeight: 1.5,
                            fontFamily: "Quicksand !important",
                            fontSize: "16px",
                            color: "#495251",
                            width: "65%",
                            fontWeight: "700",
                          }}
                          variant="strong"
                        >
                          6 February 2025
                        </Typography>
                      </ListItem>
                    </List>
                  </Box>
                </Box> */}
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "Quicksand !important",
                      fontSize: "20px",
                      fontWeight: "600",
                      display: "flex",
                      gap: "8px",
                      lineHeight: 1,
                      alignItems: "end",
                      m: "0 0 5px",
                      lineHeight: 1,
                      color: "#2EC4B6",
                    }}
                  >
                    Features / Amenities
                  </Typography>
                  <List
                    sx={{
                      display: "grid",
                      gap: "15px",
                      gridTemplateColumns: {
                        xs: "repeat(1, minmax(0, 1fr))",
                        sm: "repeat(2, minmax(0, 1fr))",
                        md: "repeat(3, minmax(0, 1fr))",
                        lg: "repeat(5, minmax(0, 1fr))",
                      },
                    }}
                  >
                    {propertyDetail?.amenities?.map((amenity) => (
                      <ListItem
                        key={amenity?.id}
                        sx={{
                          background: "#E4E5E5",
                          borderRadius: "4px",
                          p: "15px",
                          display: "grid",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <ListItemIcon sx={{ textAlign: "center", m: "0 auto" }}>
                          <img
                            src={amenity?.icon}
                            alt={amenity?.name}
                            style={{
                              margin: "0 auto",
                              height: "24px",
                              width: "24px",
                            }}
                          />
                        </ListItemIcon>
                        <Typography
                          variant="h6"
                          sx={{
                            textAlign: "center",
                            fontWeight: "600",
                            fontFamily: "Quicksand !important",
                            display: "block",
                            fontSize: "16px",
                            color: "#495251",
                            lineHeight: 1.25,
                          }}
                        >
                          {amenity?.name}
                        </Typography>
                      </ListItem>
                    ))}

                    {/* <ListItem
                      sx={{
                        background: "#E4E5E5",
                        borderRadius: "4px",
                        p: "15px",
                        display: "grid",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemIcon sx={{ textAlign: "center", m: "0 auto" }}>
                        <img
                          src={furniture}
                          alt=""
                          style={{ margin: "0 auto" }}
                        />
                      </ListItemIcon>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          fontWeight: "600",
                          fontFamily: "Quicksand !important",
                          display: "block",
                          fontSize: "16px",
                          color: "#495251",
                          lineHeight: 1.25,
                        }}
                      >
                        Furnished
                      </Typography>
                    </ListItem>
                    <ListItem
                      sx={{
                        background: "#E4E5E5",
                        borderRadius: "4px",
                        p: "15px",
                        display: "grid",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemIcon sx={{ textAlign: "center", m: "0 auto" }}>
                        <img
                          src={balcony}
                          alt=""
                          style={{ margin: "0 auto" }}
                        />
                      </ListItemIcon>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          fontWeight: "600",
                          fontFamily: "Quicksand !important",
                          display: "block",
                          fontSize: "16px",
                          color: "#495251",
                          lineHeight: 1.25,
                        }}
                      >
                        Balcony or Terrace
                      </Typography>
                    </ListItem>
                    <ListItem
                      sx={{
                        background: "#E4E5E5",
                        borderRadius: "4px",
                        p: "15px",
                        display: "grid",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemIcon sx={{ textAlign: "center", m: "0 auto" }}>
                        <img
                          src={parking}
                          alt=""
                          style={{ margin: "0 auto" }}
                        />
                      </ListItemIcon>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          fontWeight: "600",
                          fontFamily: "Quicksand !important",
                          display: "block",
                          fontSize: "16px",
                          color: "#495251",
                          lineHeight: 1.25,
                        }}
                      >
                        Parking Spaces
                      </Typography>
                    </ListItem>
                    <ListItem
                      sx={{
                        background: "#E4E5E5",
                        borderRadius: "4px",
                        p: "15px",
                        display: "grid",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemIcon sx={{ textAlign: "center", m: "0 auto" }}>
                        <img
                          src={building}
                          alt=""
                          style={{ margin: "0 auto" }}
                        />
                      </ListItemIcon>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          fontWeight: "600",
                          fontFamily: "Quicksand !important",
                          display: "block",
                          fontSize: "16px",
                          color: "#495251",
                          lineHeight: 1.25,
                        }}
                      >
                        View
                      </Typography>
                    </ListItem>
                    <ListItem
                      sx={{
                        background: "#E4E5E5",
                        borderRadius: "4px",
                        p: "15px",
                        display: "grid",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemIcon sx={{ textAlign: "center", m: "0 auto" }}>
                        <img src={pets} alt="" style={{ margin: "0 auto" }} />
                      </ListItemIcon>
                      <Typography
                        variant="h6"
                        sx={{
                          textAlign: "center",
                          fontWeight: "600",
                          fontFamily: "Quicksand !important",
                          display: "block",
                          fontSize: "16px",
                          color: "#495251",
                          lineHeight: 1.25,
                        }}
                      >
                        Pets Allowed
                      </Typography>
                    </ListItem> */}
                  </List>
                </Box>
              </Box>
              <Box sx={{ width: "320px", m: "0 auto" }}>
                <Box
                  sx={{
                    background: "#fff",
                    borderRadius: "6px",
                    p: "15px",
                    border: "1px solid #E4E5E5",
                    m: "0 0 25px",
                  }}
                >
                  {/* <Box
                    sx={{
                      width: "100%",
                      background: "#2EC4B6",
                      height: "60px",
                      borderRadius: "6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      p: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Quicksand",
                        textAlign: "right",
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#fff",
                      }}
                      variant="span"
                    >
                      Tru Broker ™
                    </Typography>
                  </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "15px",
                      p: "5px 15px 0",
                      alignItems: "center",
                      m: "0 0 20px",
                    }}
                  >
                    <Box
                      sx={{
                        p: "4px",
                        border: "1px solid #2ec4b6",
                        // margin: "-30px 0 0",
                        display: "flex",
                        borderRadius: "4px",
                        boxShadow: "0 0 10px  0 rgba(0,0,0,.1)",
                      }}
                    >
                      <img
                        src={propertyDetail?.broker?.profile_picture}
                        alt=""
                        style={{
                          borderRadius: "4px",
                          height: "60px",
                          width: "60px",
                        }}
                      />
                    </Box>
                    <Box sx={{ display: "grid", gap: "8px" }}>
                      <Typography
                        variant="h4"
                        sx={{
                          m: "0",
                          p: "0",
                          lineHeight: 1,
                          fontFamily: "Quicksand",
                          fontWeight: "700",
                          fontSize: "15px",
                          color: "#495251",
                        }}
                      >
                        {propertyDetail?.broker?.first_name +
                          " " +
                          propertyDetail?.broker?.last_name || ""}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gap: "10px",
                      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                      m: "0 0 15px",
                    }}
                  >
                    <Link
                      sx={{
                        p: "8px 5px",
                        gap: "5px",
                        textDecoration: "none",
                        background: "rgb(232, 245, 251)",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={response} alt="" />
                      <Typography
                        sx={{
                          fontSize: "11px",
                          lineHeight: "1",
                          color: "#333",
                          fontWeight: "600",
                        }}
                        variant="span"
                      >
                        Quality Lister
                      </Typography>
                    </Link>
                    <Link
                      sx={{
                        p: "8px 5px",
                        gap: "5px",
                        textDecoration: "none",
                        background: "rgb(244, 233, 245)",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={diamond} alt="" />
                      <Typography
                        sx={{
                          fontSize: "11px",
                          lineHeight: "1",
                          color: "#333",
                          fontWeight: "600",
                        }}
                        variant="span"
                      >
                        Responsive Broker
                      </Typography>
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gap: "10px",
                      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                    }}
                  >
                    <Button
                      sx={{
                        p: "8px 10px",
                        gap: "10px",
                        textDecoration: "none",
                        background: "#fff",
                        border: "1px solid #E4E5E5",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        setSelectedBroker(propertyDetail?.broker);
                        setBrokerPhoneModal(true);
                      }}
                    >
                      <img src={call} alt="" width={15} height={15} />
                      <Typography
                        sx={{
                          fontSize: "13px",
                          lineHeight: "1",
                          color: "#2EC4B6",
                          fontWeight: "600",
                        }}
                        variant="span"
                      >
                        Call
                      </Typography>
                    </Button>
                    <Button
                      sx={{
                        p: "8px 10px",
                        gap: "10px",
                        textDecoration: "none",
                        background: "#fff",
                        border: "1px solid #E4E5E5",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "capitalize",
                      }}
                      onClick={() => {
                        setSelectedBroker(propertyDetail?.broker);
                        setBrokerEmailModal(true);
                      }}
                    >
                      <img src={email} alt="" width={15} height={15} />
                      <Typography
                        sx={{
                          fontSize: "13px",
                          lineHeight: "1",
                          color: "#2EC4B6",
                          fontWeight: "600",
                        }}
                        variant="span"
                      >
                        Email
                      </Typography>
                    </Button>
                    {/* <Link
                      sx={{
                        p: "8px 10px",
                        gap: "5px",
                        textDecoration: "none",
                        background: "#fff",
                        border: "1px solid #E4E5E5",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img src={whatsapp} alt="" width={15} height={15} />
                      <Typography variant="span"></Typography>
                    </Link> */}
                  </Box>
                </Box>

                {/* <Box
                  sx={{
                    background: "#fff",
                    borderRadius: "6px",
                    p: "15px",
                    border: "1px solid #E4E5E5",
                    display: "flex",
                    gap: "15px",
                  }}
                >
                  <img
                    src={gallery01}
                    alt=""
                    style={{
                      borderRadius: "4px",
                      width: "80px",
                      height: "80px",
                    }}
                  />
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        m: "0 0 10px",
                        p: "0",
                        fontFamily: "Quicksand",
                        fontSize: "18px",
                        fontWeight: "700",
                        color: "#495251",
                      }}
                    >
                      Dubai Science Park
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{
                        m: "0",
                        p: "0",
                        fontFamily: "Quicksand",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#7D8383",
                      }}
                    >
                      See the community attractions and lifestyle
                    </Typography>
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </>
        )}

        {/* Add the PropertyGalleryModal */}
        <PropertyGalleryModal
          propertyImages={propertyImages}
          isGalleryModalOpen={isGalleryModalOpen}
          setIsGalleryModalOpen={setIsGalleryModalOpen}
          mapLink={
            propertyDetail?.location?.map_url ||
            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.178787679611!2d55.1728皡15.3052"
          }
          defaultSelectionTab={selectedTab}
          isLoading={isLoading}
        />

        <BrokerEmailModal
          setBrokerEmailModal={setBrokerEmailModal}
          brokerEmailModal={brokerEmailModal}
          setSelectedBroker={setSelectedBroker}
          selectedBroker={selectedBroker}
        />

        <BrokerPhoneModal
          setBrokerPhoneModal={setBrokerPhoneModal}
          brokerPhoneModal={brokerPhoneModal}
          setSelectedBroker={setSelectedBroker}
          selectedBroker={selectedBroker}
        />
      </Container>
    </>
  );
};

export default PropertyDetail;
