import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { userDetails: {} },
  reducers: {
    setUser: (state, action) => {
      state.userDetails = action.payload;
    },
    logOut: (state, action) => {
      state.userDetails = {};
    },
  },
});

export const { setUser } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.userDetails;
