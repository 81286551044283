import React from "react";
import CustomModal from "../../theme/style-components/CustomModal";
import { Box, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";

export const BrokerPhoneModal = ({
  setBrokerPhoneModal,
  brokerPhoneModal,
  setSelectedBroker,
  selectedBroker,
}) => {
  return (
    <CustomModal
      handleClose={() => {
        setBrokerPhoneModal(false);
        setSelectedBroker(null);
      }}
      isModalOpen={brokerPhoneModal}
      isShowActionButton={false}
      maxWidth="sm"
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          background: "#ffffff",
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            p: 0,
            position: "relative",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#000",
              textAlign: "center",
              textTransform: "uppercase",
              fontSize: "22px",
              lineHeight: "30px",
              fontWeight: 700,
              marginBottom: "25px",
              letterSpacing: "0",
            }}
          >
            Contact Us
          </Typography>
        </Box>

        <Box>
          <Box sx={{ margin: "0 10px", mb: 2.5, display: "flex", gap: "20px" }}>
            {/* Company Section */}
            <Box
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "10px",
                backgroundColor: "#efeded",
                borderRadius: "16px",
                // boxShadow: "0 0 10px rgba(0,0,0,0.12)",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-2px)",
                },
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  p: 1,
                  display: "flex",
                }}
              >
                <BusinessIcon sx={{ color: "#2B4162", fontSize: 20 }} />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "#000",
                  letterSpacing: "0",
                }}
              >
                {selectedBroker?.company?.name || ""}
              </Typography>
            </Box>
            {/* Agent Section */}
            <Box
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                p: "10px",
                backgroundColor: "#efeded",
                // boxShadow: "0 0 10px rgba(0,0,0,0.12)",
                borderRadius: "12px",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-1px)",
                },
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  p: 1,
                  display: "flex",
                }}
              >
                <PersonIcon sx={{ color: "#2B4162", fontSize: 22 }} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {selectedBroker?.first_name + " " + selectedBroker?.last_name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              margin: "0 10px",
            }}
          >
            {/* Phone Number Section */}
            <Box
              sx={{
                // backgroundColor: "#efeded",
                borderRadius: "14px",
                // p: "10px",
                mb: 2.5,
                textAlign: "center",
                // boxShadow: "0 0 10px rgba(0,0,0,0.12)",
                position: "relative",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#efeded",
                  width: "30px",
                  height: "30px",
                  borderRadius: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // margin: "0 auto 16px",
                }}
              >
                <LocalPhoneIcon sx={{ color: "#000", fontSize: 18 }} />
              </Box>
              <Typography
                sx={{
                  color: "#1aad9f",
                  fontSize: "18px",
                  lineHeight: "26px",
                  fontWeight: 700,
                  letterSpacing: "0",
                }}
              >
                {selectedBroker?.phone_number || "Not available"}
              </Typography>
            </Box>
            {/* Reference Section */}
            <Box
              sx={{
                background: "#2ec4b640",
                border: "1px solid #1aad9f2e",
                borderRadius: "12px",
                p: 2.5,
                textAlign: "center",
                boxShadow: "0 4px 12px rgba(237, 108, 2, 0.08)",
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-1px)",
                  boxShadow: "0 6px 16px rgba(237, 108, 2, 0.12)",
                },
              }}
            >
              <ArticleIcon
                sx={{
                  color: "#1aad9f",
                  fontSize: 34,
                  mb: 1,
                }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#000",
                  mb: 1,
                }}
              >
                Please quote property reference
              </Typography>
              <Typography
                sx={{
                  color: "#1aad9f",
                  fontSize: "18px",
                  lineHeight: "24px",
                  fontWeight: 600,
                  mb: 1,
                }}
              >
                {"1byx"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#000",
                }}
              >
                when calling us
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};
